import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOG } from '../../config';

import { useQuery } from '@apollo/client';
import { Product, PRODUCTS, Product_Typology, TYPOLOGIES } from '../../stores/queries/product';
import { ACTUAL_AUTH } from '../../stores/db/auth';
import { numberToEuro } from '../../libs/utils';

import { ProductAddModal } from './ProductAddModal';
import { Loading, SelectFilter, Table } from '../../ui';
import { ProductRemoveModal } from '..';
import { ProductDuplicateModal } from './ProductDuplicateModal';

const log = LOG.extend('PRODUCTS_LIST');

const alphabeticSort = (rowA: Product, rowB: Product) => {
  const a = rowA.name.toLowerCase();
  const b = rowB.name.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const tableColumns = [
  {
    id: 'name',
    name: 'nome',
    selector: (row: Product) => row.name,
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
    sortFunctiont: alphabeticSort,
  },
  {
    id: 'type',
    name: 'tipologia',
    selector: (row: Product) => row.type,
    cell: (row: Product) => {
      let typology = TYPOLOGIES.find(typo => typo.value === row.type);
      let label = typology?.label ? typology.label : 'sconosciuta';
      return label;
    },
    sortable: true,
    hide: 'md',
  },
  {
    id: 'price',
    name: 'prezzo a partire da',
    sortable: false,
    cell: (row: Product) => {
      let quantityPrice = row.prices?.quantityDiscounts;
      let minPrice = 99999;
      if (quantityPrice) {
        for (let i = 0; i < quantityPrice.length; i++) {
          let newPrice = quantityPrice[i].newPrice;
          if (newPrice && newPrice < minPrice) {
            minPrice = newPrice;
          }
        }
        return `${numberToEuro(minPrice)}`;
      } else {
        return 'non definito';
      }
    },
    center: true,
    hide: 'md',
  },
  {
    id: 'promo',
    name: 'Oggi in Promo',
    sortable: false,
    cell: (row: Product) => {
      let quantityPrice = row.prices?.quantityDiscounts;
      if (!quantityPrice) return '-';
      let inPromo = 'No';
      let now = new Date();
      for (let i = 0; i < quantityPrice.length; i++) {
        let timeDiscount = quantityPrice[i].timeDiscount;
        if (timeDiscount && timeDiscount.from && timeDiscount.to)
          if (new Date(timeDiscount.from) <= now && new Date(timeDiscount.to) >= now) {
            inPromo = 'Sì';
            break;
          }
      }
      return inPromo;
    },
    center: true,
    hide: 'md',
  },
  {
    id: 'dimension',
    name: 'Dimensioni [h x w]',
    selector: (row: Product) => row.wizards[0]?.sizes?.height,
    sortable: true,
    cell: (row: Product) => {
      if (!row.wizards[0]?.sizes?.height || !row.wizards[0]?.sizes?.width) {
        return `-`;
      }
      return `${row.wizards[0]?.sizes?.height} mm x ${row.wizards[0]?.sizes?.width} mm`;
    },
    center: true,
    hide: 'md',
  },
  {
    id: 'option',
    name: 'opzioni',
    cell: (row: Product) => {
      return (
        <div className="flex flex-row gap-2">
          <ProductDuplicateModal product={row} />
          <ProductRemoveModal product={row} />
        </div>
      );
    },
    width: '110px',
    sortable: false,
    center: true,
    style: { borderLeft: '1px solid #eee' },
  },
];

const ProductsList = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery<{ AdminProducts: Product[] }, { shopId: string }>(
    PRODUCTS,
    {
      variables: {
        shopId: ACTUAL_AUTH.shop || '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [typeFilter, setTypeFilter] = useState<Product_Typology | null>(null);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  // ogni volta che cambiano i dati o uno dei filtri
  useEffect(() => {
    let newFilteredProducts: Product[] = data?.AdminProducts || [];

    if (typeFilter) {
      newFilteredProducts = newFilteredProducts.filter(item => item.type === typeFilter);
    }

    setFilteredProducts(newFilteredProducts);
  }, [data, typeFilter]);

  if (loading)
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <Loading color={'#666'} />
      </div>
    );

  if (error) {
    log.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  return (
    <div className={'relative flex flex-1 flex-col min-w-0 w-full'}>
      <div className={'flex flex-row gap-2 items-center'}>
        <h6 className={'font-semibold text-1xl text-gray-600 py-4 px-2'}>Filtri attivi:</h6>
        <div className={'sm:min-w-[12rem]'}>
          <SelectFilter
            zIndex={998}
            items={TYPOLOGIES}
            placeholder="Tipologie..."
            emptySelection={true}
            onChange={item => {
              item?.value ? setTypeFilter(item.value as Product_Typology) : setTypeFilter(null);
            }}
          />
        </div>
      </div>
      <div
        className={
          'flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white'
        }
      >
        <Table
          columns={tableColumns}
          defaultSortFieldId={'name'}
          data={filteredProducts}
          height={'calc(100vh - 240px)'}
          title={'Lista Prodotti'}
          addElementComponent={<ProductAddModal />}
          loading={filteredProducts ? false : true}
          filterSelector={'name'}
          defaultSortInvert={false}
          onRowClick={(row: Product, event) => {
            log.debug(`CLICK on ${row.name}`);
            navigate('/admin/shop/products/' + row._id);
          }}
        />
      </div>
    </div>
  );
};

export { ProductsList };
