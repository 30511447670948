import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMutation } from '@apollo/client';

import { LOG } from '../../config';
import {
  editJobData,
  editJobVariables,
  EDIT_JOB,
  JobStatus,
  STATUS,
  JobDelivery,
} from '../../stores/queries/orders';
import { useFormFields } from '../../hooks/forms';

import { Button, TextBox } from '../../ui';

const log = LOG.extend('ORDERS_LIST');

interface OrderStatusModalProps {
  status: JobStatus;
  deliveryType: JobDelivery['type'];
  jobId: string;
}
const OrderStatusModal = ({ status, deliveryType, jobId }: OrderStatusModalProps) => {
  const [newState, setNewState] = useState(status);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newTrackingCode = useRef('');
  const [inputs, handleInputChange, setInputs] = useFormFields<{ trackingCode: string }>({
    trackingCode: '',
  });

  const resetForm = () => {
    setInputs({ trackingCode: '' });
    newTrackingCode.current = '';
  };

  function closeModal() {
    resetForm();
    setIsModalOpen(false);
  }

  function openModal() {
    if (status === 'elaborated' || status === 'ready') {
      setIsModalOpen(true);
    }
  }

  const [editJob, { error: updateStatusError }] = useMutation<editJobData, editJobVariables>(
    EDIT_JOB
  );

  const updateJobStatus = (newStatus: JobStatus) => {
    log.debug(`update Job:${jobId} Status to [${newStatus}]`);

    let newStatusObj = STATUS.find(s => s.value === newStatus);
    if (!newStatusObj) {
      log.error(`   - newStatus: ${newStatus} UNKNOWN - can not be Set`);
      return;
    }

    editJob({
      variables: {
        jobId,
        data: {
          status: newStatus,
          trackingCode: newTrackingCode.current ? newTrackingCode.current : undefined,
        },
      },
    });
  };

  if (updateStatusError) {
    log.error(JSON.stringify(updateStatusError));
  }

  const getStatusName = (status: JobStatus) => {
    let statusObj = STATUS.find(s => s.value === status);

    if (statusObj?.value === 'ready' && deliveryType === 'atShop') {
      return 'Pronto per il Ritiro';
    }
    if (statusObj?.value === 'ready' && deliveryType === 'atHome') {
      return 'Spedito';
    }

    let statusLabel = statusObj ? statusObj.label : 'Sconosciuto';
    return statusLabel;
  };

  const getStatusColor = (status: JobStatus) => {
    type Color =
      | 'white'
      | 'purple'
      | 'purpleLight'
      | 'gray'
      | 'grayLight'
      | 'red'
      | 'redLight'
      | 'yellow'
      | 'blue'
      | 'green';

    let statusColor: Color = 'grayLight';

    switch (status) {
      case 'confirmed':
        statusColor = 'yellow';
        break;
      case 'ready':
        statusColor = 'yellow';
        break;
      case 'elaborated':
        statusColor = 'green';
        break;
      case 'errorInProcessing':
        statusColor = 'red';
        break;
      case 'canceledByAdmin':
        statusColor = 'red';
        break;
      case 'canceledByUser':
        statusColor = 'red';
        break;
      default:
        break;
    }

    return statusColor;
  };

  const renderTrackingCode = () => {
    if (deliveryType === 'atShop') return null;

    return (
      <div className="mt-4 flex justify-center">
        <TextBox
          id="trackingCode"
          type="text"
          value={inputs.trackingCode}
          placeholder={'Num. Tracking Spedizione'}
          onChange={event => {
            newTrackingCode.current = event.target.value;
            handleInputChange(event);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Button
        size="xs"
        disabled={status !== 'elaborated' && status !== 'ready'}
        color={getStatusColor(status)}
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        {getStatusName(status)}
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l mb-2 font-bold uppercase">
                  Modifica Stato Ordine
                </Dialog.Title>
                <hr className="border-b-1 border-gray-200" />
                <div className="mt-4 flex justify-center">
                  <Button
                    size="xs"
                    color={newState === 'elaborated' ? getStatusColor(newState) : 'grayLight'}
                    onClick={() => {
                      setNewState('elaborated');
                    }}
                    style={{ alignSelf: 'center' }}
                  >
                    {getStatusName('elaborated')}
                  </Button>
                </div>
                <div className="mt-2 flex justify-center">
                  <i className="fa fa-chevron-down text-gray-600" />
                </div>
                <hr className="mt-2 border-b-1 border-gray-200" />
                <div className="mt-4 flex justify-center">
                  <Button
                    size="xs"
                    color={newState === 'ready' ? getStatusColor(newState) : 'grayLight'}
                    onClick={() => {
                      setNewState('ready');
                    }}
                    style={{ alignSelf: 'center' }}
                  >
                    {getStatusName('ready')}
                  </Button>
                </div>
                {renderTrackingCode()}
                <hr className="mt-4 border-b-1 border-gray-200" />
                <div className="mt-4 flex flex-row">
                  <div className="w-1/2 flex justify-center">
                    <Button
                      onClick={() => {
                        setNewState(status);
                        closeModal();
                      }}
                      color="redLight"
                    >
                      Annulla
                    </Button>
                  </div>
                  <div className="w-1/2 flex justify-center">
                    <Button
                      onClick={() => {
                        updateJobStatus(newState);
                        closeModal();
                      }}
                      color="purple"
                    >
                      Conferma
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { OrderStatusModal };
