import React from 'react';
import { useQuery } from '@apollo/client';
import { LOG } from '../../config';
import { Credit, CREDITS_LABEL, CREDIT_HISTORY } from '../../stores/queries/credits';
import { TableInCard } from '../../ui';
import { ACTUAL_AUTH } from '../../stores/db/auth';
import { numberToEuro } from '../../libs/utils';

const log = LOG.extend('CREDIT_HISTORY');

const tableColumns = [
  {
    id: 'creationDate',
    name: 'Data',
    minWidth: '15%',
    cell: (row: Credit) => {
      if (!row.creationDate) return '-';
      let date = new Date(row.creationDate);
      let dateString = `${date.toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })} - ${date.toLocaleTimeString()}`;
      return dateString;
    },
  },
  {
    id: 'scope',
    name: 'Operazione',
    sortable: false,
    minWidth: '15%',
    cell: (row: Credit) => {
      let labelObj = CREDITS_LABEL.find(s => s.value === row.scope);
      let label = labelObj ? labelObj.label : 'Sconosciuta';
      return label;
    },
  },
  {
    id: 'oldAmount',
    name: 'Vecchio Credito',
    sortable: false,
    center: true,
    hide: 'md',
    minWidth: '10%',
    cell: (row: Credit) => {
      return numberToEuro(row.oldAmount);
    },
  },
  {
    id: 'newAmount',
    name: 'Nuovo Credito',
    sortable: false,
    center: true,
    hide: 'md',
    minWidth: '10%',
    cell: (row: Credit) => {
      return numberToEuro(row.newAmount);
    },
  },
  {
    id: 'amount',
    name: 'Valore',
    sortable: false,
    center: true,
    minWidth: '10%',
    cell: (row: Credit) => {
      let color = 'text-green-400';
      if (row.scope === 'dischargedByOperation' || row.scope === 'dischargedByRoot') {
        color = 'text-red-400';
      }
      return (
        <div style={{ pointerEvents: 'none' }}>
          <h4 className={`font-bold text-sm ${color}`}>{numberToEuro(row.amount)}</h4>
        </div>
      );
    },
  },
];

const CreditsHistory = () => {
  const { data, error, loading } = useQuery<
    { AdminGetShopCreditHistory: Credit[] },
    { shopId: string }
  >(CREDIT_HISTORY, {
    variables: {
      shopId: ACTUAL_AUTH.shop || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    log.error(error);
  }

  return (
    <div className="relative flex flex-auto flex-col ">
      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Storico Crediti</h6>
        <div className={'flex flex-1 flex-col break-words w-full mb-6'}>
          {error ? (
            <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
              {`${error}`}
            </div>
          ) : (
            <TableInCard
              columns={tableColumns}
              data={data?.AdminGetShopCreditHistory || []}
              height={'calc(100vh - 240px)'}
              loading={loading}
              defaultSortInvert={true}
              centerHeadCells={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { CreditsHistory };
