import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { useQuery } from '@apollo/client';
import { Product, PRODUCT } from '../../../stores/queries/product';
import { LOG } from '../../../config';

import { ProductDetail, ProductPages, ProductPrices } from '../../../components';
import { Button, Loading } from '../../../ui';

const ProductView = () => {
  const params: any = useParams();

  const { error, data } = useQuery<{ AdminProduct: Product }, { _id: string }>(PRODUCT, {
    variables: { _id: params.id },
    fetchPolicy: 'cache-and-network',
  });

  const [page, setPage] = useState<'base' | 'varianti' | 'listino'>(params?.view || 'base');

  if (error) {
    LOG.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  if (!data?.AdminProduct) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }

  const renderTab = () => {
    switch (page) {
      case 'base':
        return <ProductDetail product={data.AdminProduct} />;
      case 'varianti':
        return <ProductPages _id={data.AdminProduct._id} pages={data.AdminProduct.pages} />;
      case 'listino':
        return <ProductPrices _id={data.AdminProduct._id} prices={data.AdminProduct.prices} />;
      default:
        return <ProductDetail product={data.AdminProduct} />;
    }
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4">
      <div className="rounded-t-2xl w-full mb-0 px-6 py-4 bg-gray-200">
        <div className="flex flex-row w-full items-center">
          <h6 className=" text-gray-600 text-xl font-bold my-2">{data.AdminProduct.name}</h6>
          <div className=" flex flex-grow justify-evenly ml-4 flex-wrap content-between">
            <Button
              className={'my-2'}
              color={page === 'base' ? 'purple' : 'purpleLight'}
              onClick={() => {
                setPage('base');
              }}
            >
              Scheda base
            </Button>
            <Button
              className={'my-2'}
              color={page === 'varianti' ? 'purple' : 'purpleLight'}
              onClick={() => {
                setPage('varianti');
              }}
            >
              Varianti
            </Button>
            {/* <Button
              className={'my-2'}
              color={page === 'multi' ? 'purple' : 'purpleLight'}
              onClick={() => {
                setPage('multi');
              }}
            >
              Multipagina
            </Button> */}
            <Button
              className={'my-2'}
              color={page === 'listino' ? 'purple' : 'purpleLight'}
              onClick={() => {
                setPage('listino');
              }}
            >
              Listino e promo
            </Button>
          </div>
        </div>
      </div>
      {renderTab()}
    </div>
  );
};

export { ProductView };
