import React, { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { LOG } from '../../config';

import { useMutation } from '@apollo/client';
import {
  createLightJobData,
  createLightJobVariables,
  CREATE_LIGHT_JOB,
} from '../../stores/queries/lightjob';

import { Button, TextBox } from '../../ui';
import { useFormFields } from '../../hooks/forms';
import { ACTUAL_AUTH } from '../../stores/db/auth';

const log = LOG.extend('CREATE_LIGHT_JOB_MODAL');

const emptyJob: createLightJobVariables['userData'] = {
  name: '',
};

type Inputs = 'name';

const LightOrderCreateModal = () => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newJob = useRef<createLightJobVariables['userData']>(emptyJob);
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in Inputs]: string }>({
    name: '',
  });
  const [inputsError, setInputErrors] = useState<{ [key in Inputs]: string }>({
    name: '',
  });

  // -- -- --
  // -- MUTATIONS --
  // -- -- --

  const [createJob, { error, loading, data: CreateJobData }] = useMutation<
    createLightJobData,
    createLightJobVariables
  >(CREATE_LIGHT_JOB);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const create = async () => {
    log.info(`CALL createJob : ${JSON.stringify(newJob.current)}`);

    try {
      let createJobResult = await createJob({
        variables: {
          shopId: ACTUAL_AUTH.shop || '',
          userData: newJob.current,
        },
      });

      if (!createJobResult.data?.AdminCreateLightJob) {
        log.error(`  - createJob Error: ${createJobResult.errors}`);
        return;
      }

      const { _id } = createJobResult.data.AdminCreateLightJob;
      LOG.info(`  - createJob success - ${_id}`);
      closeModal();
    } catch (err) {
      log.error(`  - createJob something went wrong`);
      log.error(`${err}`);
      return;
    }
  };

  const resetError = (key: Inputs) => {
    setInputErrors(oldErrors => {
      return {
        ...oldErrors,
        [key]: '',
      };
    });
  };

  const resetForm = () => {
    setInputs({
      name: '',
    });
    setInputErrors({
      name: '',
    });
    newJob.current = emptyJob;
  };

  const isFormValid = () => {
    log.debug('CALL formValidation()');
    let isFormValid = true;

    //name - not null
    if (!inputs.name) {
      log.info('  - inputs.name NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          name: '* campo obbligatorio.',
        };
      });
      isFormValid = false;
    }

    return isFormValid;
  };

  function closeModal() {
    resetForm();
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  // -- -- --
  // -- RENDER --
  // -- -- --

  if (error) {
    log.error(error);
    return (
      <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
        {`${error}`}
      </div>
    );
  }

  return (
    <>
      <Button
        className="mr-2"
        size="xs"
        color="purple"
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        Crea nuovo Ordine
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l my-2 font-bold uppercase">
                  Crea nuovo Ordine Light
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">Inserisci il nome del Cliente</p>
                </div>
                <TextBox
                  id="name"
                  type="text"
                  value={inputs.name}
                  error={inputsError.name}
                  placeholder={'Nome'}
                  required={true}
                  onChange={event => {
                    newJob.current.name = event.target.value;
                    handleInputChange(event);
                    resetError('name');
                  }}
                />

                <div className="mt-4 flex flex-row gap-4">
                  <Button
                    disabled={loading}
                    onClick={() => {
                      if (isFormValid()) {
                        LOG.info('valid form -> upload');
                        create();
                      } else {
                        LOG.info('NOT valid form -> NOT upload');
                      }
                    }}
                    color="purple"
                  >
                    Crea
                  </Button>
                  <Button disabled={loading} onClick={closeModal} color="red">
                    Annulla
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { LightOrderCreateModal };
