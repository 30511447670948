import React, { useState, MouseEvent, useEffect } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useNavigate } from 'react-router-dom';

import { LOG, theme } from '../../config';

import { useQuery } from '@apollo/client';
import { Shop, SHOPS } from '../../stores/queries/shop';

import { changeShop } from '../../libs/apollo/auth';
import { ACTUAL_AUTH } from '../../stores/db/auth';

import { Loading } from '../../ui';

interface ShopBtnProps {
  shop: Shop;
  onPress?: () => void;
  onChangeSuccess: (shop: Shop) => void;
}

const ShopBtn = (props: ShopBtnProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const changeShopRes = (res: boolean) => {
    if (res) {
      LOG.info('Change Shop Succesfully');
      setLoading(false);
      props.onChangeSuccess && props.onChangeSuccess(props.shop);
    } else {
      setLoading(false);
      LOG.warn('Cambio Shop fallito');
    }
  };

  const handleShopPress = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.onPress && props.onPress();
    if (loading) return null;
    if (props.shop._id !== ACTUAL_AUTH.shop) {
      setLoading(true);
      changeShop(props.shop._id, props.shop.type).then(res => {
        changeShopRes(res);
      });
    }
  };

  if (props.shop.status === 'copying' || props.shop.status === 'removing') return null;

  return (
    <button
      onClick={handleShopPress}
      className="flex flex-row w-full p-4 shadow-md rounded-lg justify-between items-center hover:shadow-xl outline-none focus:outline-none ease-linear transition-all duration-150 bg-gray-200 cursor-pointer"
      style={{
        //backgroundColor: "#515151",
        marginTop: 10,
        marginBottom: 10,
        opacity: loading ? 0.5 : 1,
      }}
    >
      <div
        className="border-none"
        style={{
          width: 40,
          height: 40,
          paddingRight: 10,
          borderRadius: 10,
          backgroundColor: '#ccc',
        }}
      />
      <h1
        className="text-gray-500 text-lg font-bold uppercase flex-1 m-2 opacity-80"
        style={{
          textAlign: 'left',
          paddingLeft: 10,
          borderLeft: '1px solid #bbb',
        }}
      >
        {props.shop.name}
      </h1>
      {loading && (
        <div className="absolute flex items-center justify-center">
          <Loading color={theme.colors.light[0]} />
        </div>
      )}
    </button>
  );
};

const ShopSelect = () => {
  const navigate = useNavigate();
  const { data } = useQuery<{ AdminShops: Shop[] }>(SHOPS, { fetchPolicy: 'cache-and-network' });

  const [currentShop, setCurrentShop] = useState<Shop>();

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (data?.AdminShops && ACTUAL_AUTH.shop) {
      let currentShop = data.AdminShops.find(shop => shop._id === ACTUAL_AUTH.shop);
      setCurrentShop(currentShop);
    }
  }, [data]);

  if (!data?.AdminShops) {
    return (
      <div className={'bg-white p-2 rounded-2xl'} style={{ marginRight: 20 }}>
        <Loading color={'#666'} />
      </div>
    );
  }

  return (
    <>
      <Popover>
        <Popover.Button
          ref={setReferenceElement as any}
          className="flex mr-2 flex-row bg-white w-full rounded-2xl justify-between items-center hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
        >
          <div
            className="border-none rounded-2xl"
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              marginLeft: 5,
              backgroundColor: '#ccc',
              padding: 11,
            }}
          >
            <div
              className="border-none"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>

          <h1
            className="text-gray-500 text-sm font-bold uppercase flex-1 opacity-80"
            style={{
              textAlign: 'left',
              padding: 3,
              paddingRight: 10,
              paddingLeft: 5,
              wordBreak: 'break-all',
            }}
          >
            {currentShop && currentShop.name}
            <br />
            <small>{currentShop && currentShop.code}</small>
          </h1>
        </Popover.Button>

        <Popover.Panel ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
          {({ close }) => (
            <div
              className={
                'bg-white text-base z-50 float-left px-2 mt-1 list-none rounded-lg shadow-lg min-w-[12rem] text-right'
              }
              style={{ border: '1px solid #ddd', paddingLeft: 10, zIndex: 99999 }}
            >
              {data.AdminShops &&
                data.AdminShops?.map((shop, index) => (
                  <ShopBtn
                    key={index}
                    shop={shop}
                    onChangeSuccess={shop => {
                      LOG.info('onSelectionSuccess');
                      setCurrentShop(shop);
                      navigate('/admin');
                      close();
                    }}
                  />
                ))}
            </div>
          )}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export { ShopBtn, ShopSelect };
