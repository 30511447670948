import React from 'react';

import imgDefault from '../../assets/img/image_not_found.png';
import { EPNode } from '../../stores/queries/shop';
import { theme } from '../../config';

interface ProductElementProps {
  connectDragSource: any;
  node: EPNode;
}
const ProductElement = ({ connectDragSource, node }: ProductElementProps) => {
  const { product, color } = node;

  let mainColors = theme.colors['violet'];
  if (color) {
    mainColors = theme.colors[color];
  }

  let bg = `linear-gradient(325deg, ${mainColors[0]} 0%, ${mainColors[2]} 94%)`;

  return connectDragSource(
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        padding: 20,
        marginTop: 10,
      }}
    >
      <div
        className="flex flex-col items-center justify-center shadow-lg rounded-2xl"
        style={{
          display: 'inline-block',
          width: 200,
          height: 90,
          paddingTop: 32,
          paddingLeft: 5,
          marginTop: 20,
          // background: `linear-gradient(325deg, ${theme.colors.violet[0]} 0%, ${theme.colors.violet[2]} 94%)`,
          background: `${theme.colors.violet[2]}`,
          textAlign: 'left',
        }}
      >
        <h2
          className="uppercase text-white text-sm font-bold mx-2 truncate"
          style={{ color: `${theme.colors.violet[0]}` }}
        >
          {product?.name || 'Sconosciuto'}
        </h2>
        <p
          className="text-white text-xxs mx-2 truncate"
          style={{ color: `${theme.colors.violet[0]}` }}
        >
          {product?.descriptions?.short || ''}
        </p>
        <h2
          className="text-white text-xs font-bold mx-2 truncate"
          style={{ color: `${theme.colors.violet[0]}` }}
        >
          {'A partire da ... €'}
        </h2>
      </div>
      <img
        alt="img"
        src={product?.images.gallery[0]?.url || imgDefault}
        className="absolute h-auto align-middle border-none rounded-2xl shadow-lg"
        style={{
          objectFit: 'cover',
          top: 0,
          left: 40,
          display: 'inline-block',
          width: 160,
          height: 70,
        }}
      />
    </div>,
    { dropEffect: 'copy' }
  );
};

export { ProductElement };
