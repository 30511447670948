import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Address,
  Shop,
  SHOP,
  updateShopData,
  updateShopVariables,
  UPDATE_SHOP,
} from '../../../stores/queries/shop';
import { ACTUAL_AUTH } from '../../../stores/db/auth';

import { LOG } from '../../../config';

import { CreditsHistory, ShopCommercialAddress, ShopLegalAddress } from '../../../components';
import { Button, EditBox, Loading, MySwitch } from '../../../ui';
import { numberToEuro, transformApolloData } from '../../../libs/utils';

const log = LOG.extend('ACCOUNT');

const AccountView = () => {
  // -- -- --
  // -- STATTES --
  // -- -- --
  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  // -- -- --
  // -- QUERY --
  // -- -- --
  const { error, data } = useQuery<{ AdminShop: Shop }, { _id: string }>(SHOP, {
    variables: { _id: ACTUAL_AUTH.shop || '' },
    fetchPolicy: 'cache-and-network',
  });

  // -- -- --
  // -- MUTATIONS --
  // -- -- --
  const [updateShop, { error: updateShopError }] = useMutation<updateShopData, updateShopVariables>(
    UPDATE_SHOP
  );

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const update = (newShop: updateShopVariables['shop']) => {
    log.info('CALL updateProduct');

    let cleanNewShop = transformApolloData(newShop);
    updateShop({
      variables: {
        _id: ACTUAL_AUTH.shop || '',
        shop: cleanNewShop,
      },
    });
  };

  const updateLegalAddress = (newAddress: Address) => {
    log.debug('CALL updateLegalAddress()');
    if (!data?.AdminShop) {
      log.error('can NOT find SHOP');
      return;
    }

    const { commercial: commercialAddress } = data.AdminShop.addresses;
    update({
      addresses: {
        commercial: commercialAddress,
        legal: newAddress,
      },
    });
    log.info(`update shop legalAddress`);
  };

  const updateCommercialAddress = (newAddress: Address, index: number) => {
    log.debug('CALL updateCommercialAddress()');
    if (!data?.AdminShop) {
      log.error('can NOT find SHOP');
      return;
    }
    if (!data.AdminShop.addresses.commercial[index]) {
      log.error(`can NOT find Shop.address.commercial[${index}]`);
      return;
    }

    const { legal: legalAddress } = data.AdminShop.addresses;
    let commercialAddress = [...data.AdminShop.addresses.commercial];
    commercialAddress[index] = newAddress;
    update({
      addresses: {
        commercial: commercialAddress,
        legal: legalAddress,
      },
    });
    log.info(`update shop CommerialAddress[${index}]`);
  };

  const addCommercialAddress = () => {
    log.debug('CALL updateCommercialAddress()');
    if (!data?.AdminShop) {
      log.error('can NOT find SHOP');
      return;
    }

    const { legal: legalAddress } = data.AdminShop.addresses;
    let commercialAddress = [...data.AdminShop.addresses.commercial];
    commercialAddress.push({
      street: '',
      city: '',
      state: '',
      country: 'IT',
      zipCode: '',
      phoneNumber: '',
    });
    update({
      addresses: {
        commercial: commercialAddress,
        legal: legalAddress,
      },
    });
    log.info(`add 1 shop CommerialAddress`);
  };

  const removeCommercialAddress = (index: number) => {
    log.debug('CALL removeCommercialAddress()');
    if (!data?.AdminShop) {
      log.error('can NOT find SHOP');
      return;
    }
    if (!data.AdminShop.addresses.commercial[index]) {
      log.error(`can NOT find Shop.address.commercial[${index}]`);
      return;
    }

    const { legal: legalAddress } = data.AdminShop.addresses;
    let commercialAddress = [...data.AdminShop.addresses.commercial];

    commercialAddress.splice(index, 1);
    update({
      addresses: {
        commercial: commercialAddress,
        legal: legalAddress,
      },
    });
    log.info(`remove CommerialAddress[${index}]`);
  };

  const toggleMaintenance = (newStatus: boolean) => {
    log.debug('CALL toggleMaintenance()');
    if (!data?.AdminShop) {
      log.error('can NOT find SHOP');
      return;
    }
    if (newStatus === data.AdminShop.isMaintenance) {
      log.warn('newStatus === data.AdminShop.isMaintenance - do NOT update');
      return;
    }
    update({
      isMaintenance: newStatus,
    });
    log.info(`update shop.isMaintenance: ${data.AdminShop.isMaintenance} -> ${newStatus}`);
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  const renderShopAddress = () => {
    let addresses: JSX.Element[] = [];
    for (let i = 0; i < shopAddress.length; i++) {
      addresses.push(
        <ShopCommercialAddress
          key={i}
          address={shopAddress[i]}
          index={i}
          updateCommercialAddress={updateCommercialAddress}
          onDelete={removeCommercialAddress}
        />
      );
    }
    return addresses;
  };

  if (error) {
    log.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  if (updateShopError) {
    log.error(updateShopError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${updateShopError}`}
        </div>
      </div>
    );
  }

  if (!data?.AdminShop) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }

  const renderFees = () => {
    let feesToRender: JSX.Element[] = [];
    if (!data.AdminShop.servicePayments?.fees?.length) return null;
    for (let i = 0; i < data.AdminShop.servicePayments.fees.length; i++) {
      let fee = data.AdminShop.servicePayments.fees[i].percentualFee;
      let limit = data.AdminShop.servicePayments.fees[i].earningLimit;
      let actualFee = data.AdminShop.servicePayments.actualPercentualFee;
      if (!fee || !limit || !actualFee) {
        log.error(`renderFees() - can NOT find actualFee/precentualFee/earningLimit for fee[${i}]`);
        continue;
      }
      let bgColor = fee === actualFee ? 'bg-green-200' : '';
      // se ultimo scaglione ....
      if (i === data.AdminShop.servicePayments.fees.length - 1) {
        feesToRender.push(
          <div key={i} className={`flex flex-col flex-auto items-center rounded-xl p-1 ${bgColor}`}>
            <p className={`text-gray-500 text-xs px-2 mb-2`}>
              {'Oltre a '}
              <span className={`text-gray-500 text-xs font-bold`}>{`${numberToEuro(
                data.AdminShop.servicePayments.fees[i - 1].earningLimit
              )}`}</span>
            </p>
            <p className={`text-gray-500 text-xs font-bold px-2 mb-2`}>{`${fee}%`}</p>
          </div>
        );
      }
      // ... se non è l'ultimo scaglione
      else {
        feesToRender.push(
          <div key={i} className={`flex flex-col flex-auto items-center rounded-xl p-1 ${bgColor}`}>
            <p className={`text-gray-500 text-xs px-2 mb-2`}>
              {'Fino a '}
              <span className={`text-gray-500 text-xs font-bold`}>{`${numberToEuro(limit)}`}</span>
            </p>
            <p className={`text-gray-500 text-xs font-bold px-2 mb-2`}>{`${fee}%`}</p>
          </div>
        );
      }
    }
    return feesToRender;
  };

  const { commercial: shopAddress } = data.AdminShop.addresses;
  const { legal: legalAddress } = data.AdminShop.addresses;
  const endDate = data.AdminShop.servicePayments.endDate
    ? new Date(data.AdminShop.servicePayments.endDate).toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : '-';
  const maintenanceLabel = data.AdminShop.isMaintenance
    ? 'in manutenzione'
    : 'visibile al pubblico';

  return (
    <div className="relative flex flex-auto flex-col ">
      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Costo del servizio</h6>
        <div className="flex flex-row">{renderFees()}</div>
      </div>
      <div className=" flex flex-row w-full flex-wrap">
        <div className="flex flex-col w-1/3">
          <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
            <div className="flex flex-row items-center justify-between">
              <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
                Manutenzione Negozio
              </h6>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="block uppercase text-gray-500 text-sm font-bold px-4">
                {maintenanceLabel}
              </p>
              <MySwitch defaultValue={data.AdminShop.isMaintenance} onChange={toggleMaintenance} />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/3">
          <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
            <div className="flex flex-row items-center justify-between">
              <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
                Scadenza abbonamento
              </h6>
              {/* <Button
                className="ml-8 my-3"
                size="xs"
                color="green"
                onClick={() => {
                  // TODO:
                  log.debug('click renew annual fee');
                }}
                style={{ alignSelf: 'center' }}
              >
                <i className="fa fa-plus" />
              </Button> */}
            </div>
            <p className="block uppercase text-gray-500 text-sm font-bold px-4 mb-2">{endDate}</p>
          </div>
        </div>
        <div className="flex flex-col w-1/3">
          <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
            <div className="flex flex-row items-center justify-between">
              <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
                Credito Residuo
              </h6>
              {/* <Button
                className="ml-8 my-3"
                size="xs"
                color="green"
                onClick={() => {
                  // TODO:
                  log.debug('click add credits');
                }}
                style={{ alignSelf: 'center' }}
              >
                <i className="fa fa-plus" />
              </Button> */}
            </div>
            <p className="block uppercase text-gray-500 text-sm font-bold px-4 mb-2">
              {`${numberToEuro(data.AdminShop.servicePayments?.credits)}`}
            </p>
          </div>
        </div>

        <div className="flex flex-col w-1/2">
          <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
            <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Dati azienda</h6>
            <EditBox
              editable={false}
              value={data.AdminShop.name}
              label="nome"
              maxChar={50}
              onEnterEdit={() => {
                setActiveEdit(true);
              }}
              onExitEdit={() => {
                setActiveEdit(false);
              }}
              onPressSave={value => {
                log.info(`update Shop.name with [${value}]`);
                // update({ name: value });
              }}
            />
            <EditBox
              editable={false}
              value={data.AdminShop.owner.username}
              label="proprietario"
              maxChar={50}
              onEnterEdit={() => {
                setActiveEdit(true);
              }}
              onExitEdit={() => {
                setActiveEdit(false);
              }}
              onPressSave={value => {
                log.info(`update Shop.owner.username with [${value}]`);
                // update({ name: value });
              }}
            />
            <EditBox
              editable={false}
              value={data.AdminShop.code}
              label="codice negozio"
              maxChar={50}
              onEnterEdit={() => {
                setActiveEdit(true);
              }}
              onExitEdit={() => {
                setActiveEdit(false);
              }}
              onPressSave={value => {
                log.info(`update Shop.code with [${value}]`);
                // update({ name: value });
              }}
            />

            {activeEdit && (
              <div
                className=" w-full h-full absolute top-0 left-0 rounded-2xl"
                style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
              ></div>
            )}
          </div>

          <ShopLegalAddress address={legalAddress} updateLegalAddress={updateLegalAddress} />
        </div>

        <div className="flex flex-col w-1/2">
          {renderShopAddress()}
          <Button
            className="ml-8 my-3"
            size="xs"
            color="green"
            onClick={addCommercialAddress}
            style={{ alignSelf: 'center' }}
          >
            <i className="fa fa-plus" />
          </Button>
        </div>
      </div>
      <CreditsHistory />
    </div>
  );
};

export { AccountView };
