import { gql } from '@apollo/client';

interface Wizard {
  _id?: string;
  label?: string;
  sizes: {
    height: number;
    width: number;
  };
  borders?: {
    bottom: {
      color: string;
      size: number;
    };
    left: {
      color: string;
      size: number;
    };
    right: {
      color: string;
      size: number;
    };
    top: {
      color: string;
      size: number;
    };
  };
  frame?: {
    thickness?: {
      size: number;
    };
    mode?: {
      mirrored?: boolean;
      colored?: boolean;
      normal?: boolean;
    };
  };
  masks?: {
    cut?: {
      url: string | null;
      _id: string | null;
    };
    safety?: {
      url: string | null;
      _id: string | null;
    };
  };
}

interface Choice {
  name: string;
  percentualPriceIncrement: number;
  picture: {
    url: string | null;
    _id: string | null;
  };
}

interface Page {
  choices: Choice[];
  name: string;
}

interface Discount {
  quantityFrom: number | null;
  quantityTo: number | null;
  newPrice: number | null;
  timeDiscount: {
    from: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
    to: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
    newPrice: number | null;
  };
}

interface Prices {
  basePrice?: number | null;
  basePriceLabel?: string | null;
  basePriceMaxQnt?: number | null;
  quantityDiscounts: Discount[];
}

const MIN_PRODUCT_QUANTITY = 1;
const MAX_PRODUCT_QUANTITY = 999;

type Product_Typology =
  | 'pictureStandard'
  | 'pictureCreative'
  | 'gadget'
  | 'canvas'
  | 'panel'
  | 'goods';

const TYPOLOGIES: { label: string; value: Product_Typology }[] = [
  {
    label: 'Foto',
    value: 'pictureStandard',
  },
  {
    label: 'Foto creative',
    value: 'pictureCreative',
  },
  {
    label: 'Pannelli',
    value: 'panel',
  },
  {
    label: 'Tele',
    value: 'canvas',
  },
  {
    label: 'Gadget',
    value: 'gadget',
  },
  {
    label: 'Prodotto generico',
    value: 'goods',
  },
];

interface Product {
  _id: string;
  name: string;
  type: Product_Typology;
  descriptions: {
    long: string;
    short: string;
  };
  shop?: any; // da divedere
  images: {
    cover: {
      url: string;
      _id: string;
    }[];
    gallery: {
      url: string | null;
      _id: string | null;
    }[];
  };
  pages?: Page[];
  prices: Prices | null;
  wizards: Wizard[];
}

interface StorageFile {
  _id: string;
  url: string;
  filename: string;
}

interface FileInput {
  fileId: string;
  action: 'insert' | 'remove';
}

const PRODUCT_FRAGMENT = gql`
  fragment productFragment on Product {
    _id
    name
    type
    descriptions {
      long
      short
    }
    images {
      cover {
        url
        _id
      }
      gallery {
        url
        _id
      }
    }
    pages {
      choices {
        name
        percentualPriceIncrement
        picture {
          url
          _id
        }
      }
      name
    }
    prices {
      basePrice
      basePriceLabel
      basePriceMaxQnt
      quantityDiscounts {
        quantityFrom
        quantityTo
        newPrice
        timeDiscount {
          from
          to
          newPrice
        }
      }
    }
    wizards {
      _id
      label
      borders {
        bottom {
          color
          size
        }
        left {
          color
          size
        }
        right {
          color
          size
        }
        top {
          color
          size
        }
      }
      frame {
        thickness {
          size
        }
        mode {
          colored
          mirrored
          normal
        }
      }
      sizes {
        height
        width
      }
      masks {
        cut {
          url
          _id
        }
        safety {
          url
          _id
        }
      }
    }
  }
`;
const PRODUCT_MIN_FRAGMENT = gql`
  fragment productMinFragment on Product {
    _id
    name
    type
    descriptions {
      short
    }
    prices {
      quantityDiscounts {
        quantityFrom
        quantityTo
        newPrice
        timeDiscount {
          from
          to
          newPrice
        }
      }
    }
    wizards {
      _id
      sizes {
        height
        width
      }
    }
  }
`;

const PRODUCT_TREE_FRAGMENT = gql`
  fragment productTreeFragment on Product {
    _id
    name
    type
    descriptions {
      short
    }
    images {
      gallery {
        url
        _id
      }
    }
    prices {
      quantityDiscounts {
        quantityFrom
        quantityTo
        newPrice
      }
    }
    wizards {
      _id
      sizes {
        height
        width
      }
    }
  }
`;

const PRODUCTS = gql`
  ${PRODUCT_MIN_FRAGMENT}
  query PRODUCTS($shopId: ID!) {
    AdminProducts(shopId: $shopId) {
      ...productMinFragment
    }
  }
`;

const PRODUCTS_FOR_TREE = gql`
  ${PRODUCT_TREE_FRAGMENT}
  query PRODUCTS_FOR_TREE($shopId: ID!) {
    AdminProducts(shopId: $shopId) {
      ...productTreeFragment
    }
  }
`;

const PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  query PRODUCT($_id: ID!) {
    AdminProduct(_id: $_id) {
      ...productFragment
    }
  }
`;

/* --------------------------------------------------------------------------*/
/* ----------------------  MUTATION  ----------------------------------------*/
/* --------------------------------------------------------------------------*/

const ADD_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation ADD_PRODUCT($product: ProductCreateInput!, $shopId: ID!) {
    AdminProductAdd(product: $product, shopId: $shopId) {
      ...productFragment
    }
  }
`;

interface AddProductData {
  AdminProductAdd: Product;
}

interface AddProductVariables {
  product: {
    name: Product['name'];
    descriptions?: Product['descriptions'];
    prices: Product['prices'];
    wizards: Product['wizards'];
    type: Product['type'];
  };
  shopId: string;
}

// ---------------------------------

const REMOVE_PRODUCT = gql`
  mutation REMOVE_PRODUCT($_id: ID!, $files: [ID]) {
    AdminProductRemove(_id: $_id, files: $files)
  }
`;

interface RemoveProductData {
  AdminProductRemove: boolean;
}

interface RemoveProductVariables {
  _id: string;
  files?: string[];
}

// ---------------------------------

const UPDATE_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation UPDATE_PRODUCT($_id: ID!, $product: ProductInput!, $files: [ProductStorageFileInput]) {
    AdminProductUpdate(_id: $_id, product: $product, files: $files) {
      ...productFragment
    }
  }
`;

interface updateProductData {
  AdminProductUpdate: Product;
}

interface updateProductVariables {
  _id: string;
  product: {
    name?: Product['name'];
    descriptions?: Product['descriptions'];
    wizards?: Product['wizards'];
    pages?: Product['pages'];
    prices?: Product['prices'];
    images?: Product['images'];
  };
  files?: FileInput[];
}

// ---------------------------------

const ADD_FILE = gql`
  mutation ADD_FILE($files: [Upload!]!, $scope: FileScope) {
    AdminAddFiles(files: $files, scope: $scope) {
      _id
      url
      filename
    }
  }
`;

interface addFilesData {
  AdminAddFiles: StorageFile[];
}

interface addFilesVariables {
  files: File[];
  scope: 'masks' | 'gallery' | 'cover' | 'treeImages';
}

// ---------------------------------

const DUPLICATE_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation DUPLICATE_PRODUCT($_id: ID!, $shopId: ID, $product: ProductDuplicateInput) {
    AdminProductDuplicate(_id: $_id, shopId: $shopId, product: $product) {
      ...productFragment
    }
  }
`;

interface duplicateProductData {
  AdminProductDuplicate: Product;
}

interface duplicateProductVariables {
  _id: string;
  shopId: string;
  product: {
    name?: Product['name'];
    descriptions?: Product['descriptions'];
    wizards: {
      _id?: string;
      sizes: {
        height: number;
        width: number;
      };
    }[];
  };
  files?: FileInput[];
}

// ---------------------------------

export {
  TYPOLOGIES,
  PRODUCTS,
  PRODUCTS_FOR_TREE,
  PRODUCT,
  MIN_PRODUCT_QUANTITY,
  MAX_PRODUCT_QUANTITY,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  PRODUCT_FRAGMENT,
  ADD_FILE,
  DUPLICATE_PRODUCT,
};
export type {
  Product_Typology,
  Product,
  Wizard,
  Page,
  Choice,
  Prices,
  Discount,
  AddProductData,
  AddProductVariables,
  RemoveProductData,
  RemoveProductVariables,
  updateProductData,
  updateProductVariables,
  addFilesData,
  addFilesVariables,
  duplicateProductData,
  duplicateProductVariables,
};
