import { gql } from '@apollo/client';
import { Product } from './product';
import { Shop } from './shop';
import { User } from './user';

interface RawProduct {
  name: Product['name'];
  type: Product['type'];
  wizards: Product['wizards'];
  pages: Product['pages'];
  prices: Product['prices'];
}

type JobStatus =
  | 'initializing'
  | 'readyForPayment'
  | 'readyForProcessing'
  | 'processing'
  | 'canceledByUser'
  | 'canceledByAdmin'
  | 'confirmed'
  | 'errorInProcessing'
  | 'inElaboration'
  | 'elaborated'
  | 'ready';

const STATUS: { label: string; value: JobStatus }[] = [
  {
    label: 'In Caricamento',
    value: 'initializing',
  },
  {
    label: 'In attesa di pagamento',
    value: 'readyForPayment',
  },
  {
    label: 'In attesa di essere processato',
    value: 'readyForProcessing',
  },
  {
    label: 'Immagini in elaborazione',
    value: 'processing',
  },
  {
    label: 'Da elaborare',
    value: 'confirmed',
  },
  {
    label: "Cancellato dall'utente",
    value: 'canceledByUser',
  },
  {
    label: 'Cancellato',
    value: 'canceledByAdmin',
  },
  {
    label: 'Errore di Fase di Elaborazione',
    value: 'errorInProcessing',
  },
  {
    label: 'Preparazione zip',
    value: 'inElaboration',
  },
  {
    label: 'Download effettuato',
    value: 'elaborated',
  },
  {
    label: 'Ordine completato',
    value: 'ready',
  },
];

const FILTER_STATUS: { label: string; value: JobStatus }[] = [
  {
    label: 'Da elaborare',
    value: 'confirmed',
  },
  {
    label: 'Cancellato',
    value: 'canceledByAdmin',
  },
  {
    label: 'Errore di Fase di Elaborazione',
    value: 'errorInProcessing',
  },
  {
    label: 'Preparazione zip',
    value: 'inElaboration',
  },
  {
    label: 'Pronto per il download',
    value: 'elaborated',
  },
  {
    label: 'Ordine completato',
    value: 'ready',
  },
];

type JobPaymentStatus =
  | 'initializing'
  | 'inProcessing'
  | 'failed'
  | 'needActionFromAdmin'
  | 'needActionFromUser'
  | 'payed'
  | 'canceled';

const PAYMENT_STATUS: { label: string; value: JobPaymentStatus }[] = [
  {
    label: 'In elaborazione',
    value: 'initializing',
  },
  {
    label: 'In elaborazione',
    value: 'inProcessing',
  },
  {
    label: 'In elaborazione',
    value: 'needActionFromUser',
  },
  {
    label: 'Da confermare',
    value: 'needActionFromAdmin',
  },
  {
    label: 'Pagato',
    value: 'payed',
  },
  {
    label: 'Non Avvenuto',
    value: 'failed',
  },
  {
    label: 'Non Avvenuto',
    value: 'canceled',
  },
];

interface UserEditingPage {
  name: string;
  choice: {
    name: string;
    percentualPriceIncrement: number;
  };
}

interface UserEditingImage {
  _id: string;
  attachmentKey: string; // ID
  attachmentUrl: string | undefined; // url dove si trova l'immagine editata
  originalUrl: string | undefined; // url dove si trova l'immagine originale
  ifEdited: boolean; // se false vuol dire che l'immagine non e' passata dall'editor, quindi il backend deve creare l'immagine
  quantity: number;
  historyKey: string; // ID
  historyUrl: string | undefined; // url dove si trova il file
  status: 'initialized' | 'uploading' | 'uploaded';
  wizardId: string;
}

interface UserEditing {
  editor: {
    version: string;
  };
  pages: UserEditingPage[];
  images: UserEditingImage[];
}

interface Order {
  _id: string;
  orderKey: string;
  finalPrice: number;
  rawProduct: RawProduct;
  userEditing: UserEditing;
}

interface JobDelivery {
  type: 'atHome' | 'atShop';
  timing: number;
  cost: number;
  address: {
    name: string;
    phone: string;
    street: string;
    city: string;
    province: string;
    state: string;
    zipCode: string;
  } | null;
  business: {
    country: string;
    city: string;
    state: string;
    zipCode: string;
    phoneNumber: string;
    street: string;
    denomination: string;
  } | null;
  country: string | null;
  trackingCode: string | null;
}

interface Job {
  _id: string;
  status: JobStatus;
  shop: Shop;
  creationDate: string; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  lastUpdateDate?: string; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  jobPayedFromShop: boolean;
  downloadUrl?: string;
  delivery: JobDelivery;
  user: User;
  finalPrice: number;
  payment: {
    type: 'onSite' | 'stripe';
    status: JobPaymentStatus;
    // stripe?: {
    //   clientSecret: string;
    // };
  };
  orders?: Order[];
}

const ORDER_FRAGMENT = gql`
  fragment orderFragment on JobOrder {
    _id
    orderKey
    finalPrice
    rawProduct {
      name
      type
      wizards {
        _id
        label
        borders {
          bottom {
            color
            size
          }
          left {
            color
            size
          }
          right {
            color
            size
          }
          top {
            color
            size
          }
        }
        frame {
          mode {
            colored
            mirrored
            normal
          }
          thickness {
            size
          }
        }
        sizes {
          height
          width
        }
        masks {
          cut {
            url
            _id
          }
          safety {
            url
            _id
          }
        }
      }
      pages {
        choices {
          name
          percentualPriceIncrement
          picture {
            url
            _id
          }
        }
        name
      }
      prices {
        basePrice
        quantityDiscounts {
          quantityFrom
          quantityTo
          newPrice
          timeDiscount {
            from
            to
            newPrice
          }
        }
      }
    }
    userEditing {
      editor {
        version
      }
      pages {
        name
        choice {
          name
          percentualPriceIncrement
        }
      }
      images {
        _id
        attachmentKey
        attachmentUrl
        originalUrl
        ifEdited
        quantity
        historyKey
        historyUrl
        status
        wizardId
      }
    }
  }
`;

const JOB_FRAGMENT = gql`
  fragment jobFragment on Job {
    _id
    status
    shop {
      _id
      servicePayments {
        annualFee
        actualPercentualFee
      }
    }
    creationDate
    lastUpdateDate
    jobPayedFromShop
    downloadUrl
    delivery {
      type
      timing
      cost
      address {
        name
        street
        city
        province
        state
        zipCode
        phone
      }
      business {
        denomination
        street
        city
        state
        country
        zipCode
        phoneNumber
      }
      country
      trackingCode
    }
    user {
      _id
      email
      username
      basicInfo {
        name
      }
    }
    finalPrice
    payment {
      type
      status
    }
  }
`;
const JOB_MIN_FRAGMENT = gql`
  fragment jobMinFragment on Job {
    _id
    status
    shop {
      _id
      servicePayments {
        annualFee
        actualPercentualFee
      }
    }
    creationDate
    jobPayedFromShop
    delivery {
      type
      address {
        name
        street
        city
        province
        state
        zipCode
        phone
      }
      business {
        denomination
        street
        city
        state
        country
        zipCode
        phoneNumber
      }
      country
    }
    user {
      _id
      email
      username
      basicInfo {
        name
      }
    }
    finalPrice
    payment {
      type
      status
    }
  }
`;

const JOB = gql`
  ${JOB_FRAGMENT}
  ${ORDER_FRAGMENT}
  query JOB($_id: ID!) {
    AdminJob(_id: $_id) {
      ...jobFragment
      orders {
        ...orderFragment
      }
    }
  }
`;

const JOBS = gql`
  ${JOB_MIN_FRAGMENT}
  query JOBS($shopId: ID!, $limit: Int, $offset: Int) {
    AdminJobs(shopId: $shopId, limit: $limit, offset: $offset) {
      jobs {
        ...jobMinFragment
      }
      count
    }
  }
`;

interface JobsData {
  AdminJobs: {
    jobs: Job[];
    count: number;
  };
}

interface JobsVariable {
  shopId: string;
  limit?: number;
  offset?: number;
  orderStatuses?: JobStatus[];
}

/* --------------------------------------------------------------------------*/
/* ----------------------  MUTATION  ----------------------------------------*/
/* --------------------------------------------------------------------------*/

const EDIT_JOB = gql`
  ${JOB_FRAGMENT}
  mutation EDIT_JOB($jobId: String!, $data: JobEditInput!) {
    AdminJobEdit(jobId: $jobId, data: $data) {
      ...jobFragment
    }
  }
`;

interface editJobData {
  AdminJobEdit: Job;
}

interface editJobVariables {
  jobId: string;
  data: {
    status?: JobStatus;
    paymentStatus?: JobPaymentStatus;
    trackingCode?: string;
  };
}

// ---------------------------------

const CREATE_JOB_ZIP = gql`
  mutation CREATE_JOB_ZIP($jobId: ID!) {
    AdminCreateJobZip(jobId: $jobId) {
      success
      errors
    }
  }
`;

interface createJobZipData {
  AdminCreateJobZip: {
    success: boolean;
    errors?: string[];
  };
}
interface createJobZipVariables {
  jobId: string;
}

export {
  ORDER_FRAGMENT,
  JOB_FRAGMENT,
  JOB,
  JOBS,
  EDIT_JOB,
  CREATE_JOB_ZIP,
  STATUS,
  FILTER_STATUS,
  PAYMENT_STATUS,
};
export type {
  Job,
  JobsData,
  JobsVariable,
  JobStatus,
  JobPaymentStatus,
  JobDelivery,
  Order,
  UserEditingImage,
  editJobData,
  editJobVariables,
  createJobZipData,
  createJobZipVariables,
};
