import React, { useEffect, useState } from 'react';
import { LOG } from '../../config';

import { Button } from '../../ui';
import { Order, UserEditingImage } from '../../stores/queries/orders';

import { EditorModal } from '..';
import { TYPOLOGIES, Wizard } from '../../stores/queries/product';
import { ACTUAL_AUTH } from '../../stores/db/auth';

const log = LOG.extend('ORDER_PRODUCTS');

interface PreviewImageProps {
  editorConfig: Wizard;
  editingImage: UserEditingImage;
  refetch: () => void;
  orderParams: {
    shopId: string;
    jobId: string;
    orderKey: string;
    attachmentKey: string;
    wizardId: string;
  };
}
const PreviewImage = ({ editorConfig, editingImage, orderParams, refetch }: PreviewImageProps) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(
    editingImage.attachmentUrl ? editingImage.attachmentUrl + `?${Date.now()}` : undefined
  );
  const [historyUrl, setHistoryUrl] = useState<string | undefined>(
    editingImage.historyUrl ? editingImage.historyUrl + `?${Date.now()}` : undefined
  );
  const [originalUrl, setOriginalUrl] = useState<string | undefined>(
    editingImage.originalUrl ? editingImage.originalUrl + `?${Date.now()}` : undefined
  );

  const subtitle = `${editingImage.quantity} x`;

  const onImageEditing = (newImageUrl: string, newHistoryUrl: string) => {
    if (newImageUrl) {
      log.debug('  - onImageEditing() - updateImgUrl');
      setImageUrl(newImageUrl + `${`?${Date.now()}`}`);
      refetch();
    } else {
      log.error(`  - onImageEditing() - can NOT update imageUrl: ${newImageUrl}`);
    }

    if (newHistoryUrl) {
      log.debug('  - onImageEditing() - updateHistoryUrl');
      setHistoryUrl(newHistoryUrl + `${`?${Date.now()}`}`);
    } else {
      log.error(`  - onImageEditing() - can NOT update historyUrl: ${newHistoryUrl}`);
    }
  };

  return (
    <div className="relative flex flex-col items-center">
      <h6 className="text-gray-500 text-xs mb-1">{subtitle}</h6>
      <div className="flex relative w-36 h-36 bg-gray-50 shadow-lg border-0">
        {editingImage.attachmentUrl ? (
          <img
            src={imageUrl}
            alt="img"
            className="w-full h-full rounded"
            style={{ objectFit: 'contain' }}
          />
        ) : (
          <div className="bg-gray-200 text-gray-500 text-xs font-bold justify-center items-center h-16 rounded">
            Ops - No Image Found
          </div>
        )}
        <EditorModal
          editorInput={{
            imageUrl: originalUrl,
            historyUrl: historyUrl,
            editorConfig: editorConfig,
          }}
          orderParams={orderParams}
          onImageEditing={onImageEditing}
        />
      </div>
    </div>
  );
};

const getProductPages = (order: Order) => {
  let lines: string[] = [];
  const { pages } = order.userEditing;

  for (let i = 0; i < pages.length; i++) {
    let line = `- ${pages[i].name}: ${pages[i].choice.name}`;
    lines.push(line);
  }

  return lines;
};

const getProductsQuantity = (order: Order) => {
  let prints = 0;
  let images = 0;
  let differentImages = order.userEditing.images;

  for (let i = 0; i < differentImages.length; i++) {
    prints += 1 * differentImages[i].quantity;
    images += 1;
  }
  return [prints, images];
};

interface OrderSingleProductProps {
  order: Order;
  jobId: string;
  isProcessing: boolean;
  refetch: () => void;
}
const OrderSingleProduct = ({ order, jobId, isProcessing, refetch }: OrderSingleProductProps) => {
  // -- -- --
  // -- STATTES --
  // -- -- --
  const [showImage, setShowImages] = useState(false);

  useEffect(() => {
    if (showImage && isProcessing) setShowImages(false);
  }, [isProcessing]);

  let [nPrint, nImages] = getProductsQuantity(order);
  let copiesLabel = `${nPrint} ${nPrint === 1 ? 'Stampa' : 'Stampe'} - ${nImages} ${
    nImages === 1 ? 'Immagine' : 'Immagini'
  } `;

  let typology = TYPOLOGIES.find(typo => typo.value === order.rawProduct.type);
  let typoLabel = typology?.label ? typology.label : 'sconosciuta';

  let priceLabel = order.finalPrice ? `${order.finalPrice} €` : '-';

  // -- -- --
  // -- RENDER --
  // -- -- --

  const renderImageList = () => {
    if (!showImage) return null;

    let [nPrint, nImages] = getProductsQuantity(order);
    let subTitle = `${nPrint} ${nPrint === 1 ? 'Stampa' : 'Stampe'} - ${nImages} ${
      nImages === 1 ? 'Immagine' : 'Immagini differenti'
    } `;

    let images = order.userEditing.images.map((image: UserEditingImage, index: number) => {
      const orderParams = {
        shopId: ACTUAL_AUTH.shop || '',
        jobId: jobId,
        orderKey: order.orderKey,
        attachmentKey: image.attachmentKey,
        wizardId: image.wizardId,
      };

      const wizard = order.rawProduct.wizards.find(wizard => wizard._id === image.wizardId);

      if (!wizard) {
        log.error(`wizardId:${image.wizardId} Not found in order.rawProduct.wizards`);
        return (
          <div
            key={index}
            className="relative bg-gray-50 shadow-lg rounded-2xl border-0 h-40 w-40 p-4 flex"
          >
            <div className="p-4 text-white bg-red-400 text-sm rounded-2xl flex flex-col justify-center text-center">
              {`wizardId Not found `}
            </div>
          </div>
        );
      }

      return (
        <PreviewImage
          key={index}
          editingImage={image}
          editorConfig={wizard}
          orderParams={orderParams}
          refetch={refetch}
        />
      );
    });

    return (
      <div className="relative my-2 p-2 flex flex-col rounded-2xl bg-gray-100">
        <div className="flex flex-row flex-wrap gap-2">{images}</div>
      </div>
    );
  };

  return (
    <div
      className={`px-4 py-1 flex flex-col flex-1 rounded-2xl hover:bg-white ${
        showImage ? 'bg-white' : ''
      }`}
    >
      <div className="flex flex-row items-center gap-4 my-2">
        <h3 className="text-gray-500 text-lg font-bold">{` ${order.rawProduct.name}`}</h3>
        <Button
          className="mx-1"
          color={showImage ? 'purple' : 'purpleLight'}
          disabled={isProcessing}
          onClick={() => {
            setShowImages(state => !state);
          }}
        >
          <i className={showImage ? 'fa fa-angle-up' : 'fa fa-angle-down'} />
        </Button>
      </div>
      <div className="grid grid-cols-4 mt-1 mb-3">
        <div>
          <h3 className="uppercase text-gray-500 text-xs font-bold mb-2">{`Tipologia:`}</h3>
          <h4 className="text-gray-500 text-xs overflow-auto">{`${typoLabel}`}</h4>
        </div>
        <div>
          <h3 className="uppercase text-gray-500 text-xs font-bold mb-2">{`Prezzo`}</h3>
          <h4 className="text-gray-500 text-xs overflow-auto">{`${priceLabel}`}</h4>
        </div>
        <div>
          <h3 className="uppercase text-gray-500 text-xs font-bold mb-2">{`Copie:`}</h3>
          <h4 className="text-gray-500 text-xs overflow-auto">{`${copiesLabel}`}</h4>
        </div>
        <div>
          <h3 className="uppercase text-gray-500 text-xs font-bold mb-2">{`Varianti`}</h3>
          {getProductPages(order).map((line, index) => {
            return (
              <h4 key={index} className="text-gray-500 text-xs overflow-auto">
                {line}
              </h4>
            );
          })}
        </div>
      </div>

      {renderImageList()}
    </div>
  );
};

export { OrderSingleProduct };
