import React, { Fragment, useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Dialog, Transition } from '@headlessui/react';

import { LOG } from '../../config';

import { Discount } from '../../stores/queries/product';

import { Button, TextBox } from '../../ui';
import { useFormFields } from '../../hooks/forms';
import { addDaysToDate, isNumber, isNumberAndNotEmpty } from '../../libs/utils';

const log = LOG.extend('PRODUCT_PRICES');

type Inputs = 'from' | 'to' | 'newPrice' | 'timeNewPrice';

interface ProductPriceEditModalProps {
  discount: Discount;
  discountIndex: number;
  quantityBefore?: number;
  quantityAfter?: number;
  isFirstRow?: boolean;
  onEdit: (index: number, newDiscount: Discount) => void;
}
const ProductPriceEditModal = ({
  discount,
  discountIndex,
  quantityBefore,
  quantityAfter,
  isFirstRow,
  onEdit,
}: ProductPriceEditModalProps) => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newDiscount = useRef<Discount>({
    newPrice: discount.newPrice,
    quantityFrom: discount.quantityFrom,
    quantityTo: discount.quantityTo,
    timeDiscount: {
      from: discount.timeDiscount.from,
      to: discount.timeDiscount.to,
      newPrice: discount.timeDiscount.newPrice,
    },
  });
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in Inputs]: string }>({
    from: discount.quantityFrom?.toString() || '',
    to: discount.quantityTo?.toString() || '',
    newPrice: discount.newPrice?.toString() || '',
    timeNewPrice: discount.timeDiscount.newPrice?.toString() || '',
  });
  const [startDate, setStartDate] = useState<Date | null>(
    discount.timeDiscount.from ? new Date(discount.timeDiscount.from) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    discount.timeDiscount.to ? addDaysToDate(new Date(discount.timeDiscount.to), -1) : null
  );
  const [inputsError, setInputErrors] = useState<{ [key in Inputs]: string }>({
    from: '',
    to: '',
    newPrice: '',
    timeNewPrice: '',
  });

  useEffect(() => {
    resetForm();
  }, [discount]);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const resetForm = () => {
    setInputs({
      from: discount.quantityFrom?.toString() || '',
      to: discount.quantityTo?.toString() || '',
      newPrice: discount.newPrice?.toString() || '',
      timeNewPrice: discount.timeDiscount.newPrice?.toString() || '',
    });
    setInputErrors({
      from: '',
      to: '',
      newPrice: '',
      timeNewPrice: '',
    });
    newDiscount.current = {
      newPrice: discount.newPrice,
      quantityFrom: discount.quantityFrom,
      quantityTo: discount.quantityTo,
      timeDiscount: {
        from: discount.timeDiscount.from,
        to: discount.timeDiscount.to,
        newPrice: discount.timeDiscount.newPrice,
      },
    };
  };

  const resetError = (key: Inputs) => {
    setInputErrors(oldErrors => {
      return {
        ...oldErrors,
        [key]: '',
      };
    });
  };

  const isFormValid = () => {
    log.debug('CALL isFormValid()');
    let isFormValid = true;

    //from - number not null
    if (!isNumberAndNotEmpty(inputs.from) || Number(inputs.from) < 1) {
      log.info('  - inputs.from NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          from: '* campo obbligatorio. Solo numeri positivi',
        };
      });
      isFormValid = false;
    }
    //from - > quantity before
    if (quantityBefore && Number(inputs.from) <= quantityBefore) {
      log.info('  - inputs.from NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          from: '* la quantità inserita si sovrappone alla fascia precedente!',
        };
      });
      isFormValid = false;
    }
    //from - < quantity after
    if (quantityAfter && Number(inputs.from) >= quantityAfter) {
      log.info('  - inputs.from NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          from: '* la quantità inserita si sovrappone alla fascia successiva!',
        };
      });
      isFormValid = false;
    }

    //from - > to
    if (Number(inputs.from) >= Number(inputs.to)) {
      log.info('  - inputs.to NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          from: '* il num iniziale non può essere > di quello finale',
        };
      });
      isFormValid = false;
    }

    //to - number not null
    if (!isNumberAndNotEmpty(inputs.to)) {
      log.info('  - inputs.to NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          to: '* Amessi solo numeri',
        };
      });
      isFormValid = false;
    }

    //newPrice - number not null
    if (!isNumberAndNotEmpty(inputs.newPrice)) {
      log.info('  - inputs.newPrice NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          newPrice: '* campo obbligatorio. Solo numeri',
        };
      });
      isFormValid = false;
    }

    //timeNewPrice - number
    if (!isNumber(inputs.timeNewPrice)) {
      log.info('  - inputs.timeNewPrice NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          timeNewPrice: '* Amessi solo numeri',
        };
      });
      isFormValid = false;
    }

    return isFormValid;
  };

  function closeModal() {
    resetForm();
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  const onSave = () => {
    log.debug('CALL onSave()');
    if (!isFormValid()) {
      log.info('form is NOT valid');
      return;
    }
    log.info('valid form -> upload');
    onEdit(discountIndex, newDiscount.current);
    closeModal();
  };

  const renderQuantityForm = () => {
    // if (isLastRow) {
    //   return (
    //     <div className="mt-4">
    //       <p className="text-sm text-gray-500">Quantità</p>
    //       <div className="flex flex-row items-center">
    //         <TextBox
    //           id="from"
    //           type="text"
    //           isNumber
    //           value={inputs.from}
    //           placeholder={'Da...'}
    //           required={true}
    //           error={inputsError.from}
    //           onChange={event => {
    //             newDiscount.current.quantityFrom = Number(event.target.value) || null;
    //             log.debug(`update prices.quantityFrom with [${event.target.value}]`);
    //             handleInputChange(event);
    //             resetError('from');
    //           }}
    //         />
    //         <p className="text-sm mx-2 text-gray-500">-</p>
    //         <TextBox
    //           id="to"
    //           type="text"
    //           isNumber
    //           value={inputs.to}
    //           placeholder={'A...'}
    //           required={true}
    //           error={inputsError.to}
    //           onChange={event => {
    //             newDiscount.current.quantityTo = Number(event.target.value) || null;
    //             log.debug(`update prices.quantityTo with [${event.target.value}}]`);
    //             handleInputChange(event);
    //             resetError('to');
    //           }}
    //         />
    //       </div>
    //     </div>
    //   );
    // }
    if (isFirstRow) return null;

    return (
      <div className="mt-4">
        <p className="text-sm text-gray-500">Quantità</p>
        <p className="text-xs text-gray-400">{'A partire da...'}</p>
        <TextBox
          id="from"
          type="text"
          isNumber
          value={inputs.from}
          placeholder={'Da...'}
          required={true}
          error={inputsError.from}
          onChange={event => {
            newDiscount.current.quantityFrom = Number(event.target.value) || null;
            log.debug(`update prices.quantityFrom with [${event.target.value}]`);
            handleInputChange(event);
            resetError('from');
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Button size="xs" color="purple" onClick={openModal} style={{ alignSelf: 'center' }}>
        <i className="fa fa-pencil-alt" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l mb-2 font-bold uppercase">
                  Modifica Fascia di prezzi
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                {renderQuantityForm()}

                <div className="mt-2">
                  <p className="text-sm text-gray-500">{'Prezzo Unitario [€]'}</p>
                  <p className="text-xs text-gray-400">
                    {'Applicato per questa quantità di prodotti, se non sono presenti promozioni'}
                  </p>
                </div>
                <TextBox
                  id="newPrice"
                  type="text"
                  isNumber
                  selectOnFocus={true}
                  value={inputs.newPrice}
                  placeholder={'prezzo per questa fascia'}
                  required={true}
                  error={inputsError.newPrice}
                  onChange={event => {
                    newDiscount.current.newPrice = Number(event.target.value);
                    log.info(`update prices.newPrice with [${Number(event.target.value)}]`);
                    handleInputChange(event);
                    resetError('newPrice');
                  }}
                />

                <hr className="mt-2 border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">{'In promozione'}</p>

                  <div className="flex flex-row items-center">
                    {/* @ts-ignore */}
                    <DatePicker
                      className="shadow my-2 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
                      dateFormat="dd MMMM yyyy"
                      selected={startDate}
                      placeholderText="A partire da..."
                      onChange={date => {
                        if (date === null) {
                          newDiscount.current.timeDiscount.from = null;
                        } else {
                          newDiscount.current.timeDiscount.from = (date as Date).toISOString();
                        }
                        setStartDate(date as Date | null);
                      }}
                    />
                    <p className="text-sm mx-2 text-gray-500">-</p>
                    {/* @ts-ignore */}
                    <DatePicker
                      className="shadow my-2 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
                      dateFormat="dd MMMM yyyy"
                      selected={endDate}
                      placeholderText="Fino a..."
                      onChange={date => {
                        if (date === null) {
                          newDiscount.current.timeDiscount.to = null;
                        } else {
                          newDiscount.current.timeDiscount.to = addDaysToDate(
                            date as Date,
                            1
                          ).toISOString();
                        }
                        setEndDate(date as Date | null);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{'Prezzo Unitario [€]'}</p>
                  <p className="text-xs text-gray-400">
                    {
                      'Applicato per questa quantità di prodotti, nel periodo di promozione indicato'
                    }
                  </p>
                </div>
                <TextBox
                  id="timeNewPrice"
                  type="text"
                  isNumber
                  value={inputs.timeNewPrice}
                  selectOnFocus={true}
                  placeholder={'Prezzo nel periodo di promozione'}
                  required={true}
                  error={inputsError.timeNewPrice}
                  onChange={event => {
                    newDiscount.current.timeDiscount.newPrice = Number(event.target.value);
                    log.debug(
                      `update prices.timeDiscount.newPrice with [${Number(event.target.value)}]`
                    );
                    handleInputChange(event);
                    resetError('timeNewPrice');
                  }}
                />

                <div className="mt-4 flex flex-row gap-4">
                  <Button onClick={onSave} color="purple">
                    Salva
                  </Button>
                  <Button onClick={closeModal} color="red">
                    Annulla
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ProductPriceEditModal };
