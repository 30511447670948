import React, { Fragment, useState } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useQuery } from '@apollo/client';

import { ENV } from '../../config';
import { logout } from '../../libs/apollo/auth';
import { User, USER_ME } from '../../stores/queries/user';
import { ACTUAL_AUTH } from '../../stores/db/auth';

const UserMenu = () => {
  const { data } = useQuery<{
    UserMe: User;
  }>(USER_ME, {
    fetchPolicy: 'network-only',
    //skip: !auth.user,
  });

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  return (
    <>
      <Popover>
        <Popover.Button
          ref={setReferenceElement as any}
          className={'focus:outline-none outline-none'}
        >
          <div className="items-center flex" style={{ height: 48, marginTop: 5, marginLeft: 10 }}>
            <span className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-2xl hover:shadow ease-linear transition-all duration-150 mb-2">
              <i className="fa fa-user" />
            </span>
          </div>
        </Popover.Button>

        <Popover.Panel ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
          <div
            className={
              'bg-white text-base z-50 float-left py-2 mt-1 list-none rounded-lg shadow-lg min-w-[12rem] text-right'
            }
            style={{ border: '1px solid #ddd', zIndex: 99999 }}
          >
            <a
              href="null"
              className={
                'text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-gray-800'
              }
              onClick={e => e.preventDefault()}
            >
              {data?.UserMe?.username}
            </a>
            <a
              href="null"
              className={
                'text-xs px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-600'
              }
              onClick={e => e.preventDefault()}
            >
              {data?.UserMe.email}
            </a>
            <div className="h-0 my-2 border border-solid border-gray-200" />
            <a
              // href="mailto:info@mowi.space"
              className={
                'text-xs py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700'
              }
              //onClick={(e) => e.preventDefault()}
            >
              ASSISTENZA
            </a>
            <div className="h-0 my-2 border border-solid border-gray-200" />
            <a
              href="null"
              className={
                'text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-gray-700'
              }
              onClick={e => {
                e.preventDefault();
                logout();
              }}
            >
              LOGOUT
            </a>
          </div>
        </Popover.Panel>
      </Popover>
    </>
  );
};

export { UserMenu };
