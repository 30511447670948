import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import { Button } from './Button';

interface EditBoxTextProps {
  style?: CSSProperties;
  label?: string;
  halfCol?: boolean;
  editable: boolean;
  value?: string;
  maxChar?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onEnterEdit?: () => void;
  onExitEdit?: () => void;
  isInputValid?: (value?: string) => boolean;
  inputInvalidMsg?: string;
  onPressSave?: (value?: string) => void;
  onPressCancel?: () => void;
}

const EditBoxText = (props: EditBoxTextProps) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.value);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setValue(props.value ? String(props.value) : undefined);
  }, [props.value]);

  useEffect(() => {
    if (edit) {
      props.onEnterEdit && props.onEnterEdit();
    } else {
      props.onExitEdit && props.onExitEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const onSave = () => {
    // With input validation...
    if (props.isInputValid) {
      let isInputValid = props.isInputValid(value);
      // if is valid...
      if (isInputValid) {
        setEdit(false);
        errorMsg && setErrorMsg(null);
        props.onPressSave && props.onPressSave(String(value || ''));
      }
      // ... if is NOT valid
      else {
        setErrorMsg(props.inputInvalidMsg || '* invalid input');
      }
    }
    // ... Without input validation
    else {
      setEdit(false);
      props.onPressSave && props.onPressSave(String(value || ''));
    }
  };

  const onCancel = () => {
    setEdit(false);
    setValue(String(props.value || ''));
    props.onPressCancel && props.onPressCancel();
    errorMsg && setErrorMsg(null);
  };

  return (
    <div
      className={
        'w-full lg:w-12/12 px-4 py-2 rounded-2xl ' +
        (props.editable && !edit ? 'hover:bg-white ' : '') +
        (props.halfCol ? 'lg:w-6/12' : '')
      }
      onClick={e => {
        if (props.editable && !edit) {
          setEdit(true);
        }
      }}
      style={{ zIndex: edit ? 99 : undefined }}
    >
      <div className={'relative w-full ' + (props.editable && 'edit-icon')}>
        {props.label && (
          <label className="block uppercase text-gray-500 text-xs font-bold mb-2">
            {props.label}
          </label>
        )}

        <>
          {value ? (
            <p
              className="text-gray-500 text-xs"
              style={{ whiteSpace: 'break-spaces' }}
              hidden={edit}
            >
              {value}
            </p>
          ) : (
            <p className="text-gray-400 text-xs" hidden={edit}>
              -
            </p>
          )}
        </>

        {props.editable && (
          <div className="flex flex-col w-full" hidden={!edit}>
            <form
              onSubmit={evt => {
                evt.preventDefault();
                onSave();
              }}
            >
              <div className="flex flex-col flex-1">
                <input
                  className="flex flex-1 shadow px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
                  value={value || ''}
                  maxLength={props.maxChar}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setValue(event.target.value);
                    props.onChange && props.onChange(event);
                  }}
                />
                {errorMsg ? (
                  <div className="ring-2 ring-red-400 outline-none bg-white rounded-lg mt-1 py-1 px-2 shadow border border-transparent">
                    <p className="text-xs text-red-400">{errorMsg}</p>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-row items-center gap-2 p-2">
                <Button color={'purple'} isSubmitButton>
                  <i className="fa fa-check" />
                </Button>{' '}
                <Button color={'white'} onClick={onCancel} style={{ marginTop: 4 }}>
                  <i className="fa fa-times" />
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export { EditBoxText };
