import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

// Css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-sortable-tree/style.css';
import './assets/styles/easypixy-icons.css';
import './assets/styles/index.css';

// Layouts
import { Admin } from './layouts/Admin';
import { Auth } from './layouts/Auth';

// Libs
import { DB_AUTH } from './stores/db/auth';
import { LOG, theme } from './config';
import { createApolloClient } from './libs/apollo/createClient';
import { Loading } from './ui';
import { shopType, userLogged } from './stores/db/appstate';

// Locale date-fns
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';
registerLocale('it', it);
setDefaultLocale('it');

const Root = () => {
  const [client, setClient] = useState<any>(undefined);

  const initApp = async () => {
    const authSaved = await DB_AUTH.get();
    const apolloClient = await createApolloClient();
    if (authSaved && authSaved.user) {
      LOG.info('User previous logged');
      userLogged(authSaved.user);
    }
    if (authSaved && authSaved.shopType) {
      LOG.info('Shop previous saved');
      shopType(authSaved.shopType);
    }
    if (apolloClient) {
      setClient(apolloClient);
    } else {
      LOG.error('Unable to initialize client');
    }
  };

  useEffect(() => {
    initApp();
  }, []);

  return (
    <div className="flex flex-1 bg-gray-100">
      {/* <div className="w-full h-full bg-no-repeat bg-gray-100" style={styles.container}> */}
      {client ? (
        <ApolloProvider client={client}>
          {/* <BrowserRouter basename={ENV.basename}> */}
          <BrowserRouter>
            <Routes>
              {/* Routes autenticazione */}
              <Route path="/" element={<Auth />} />

              {/* Routes admin */}
              <Route path="admin/*" element={<Admin />} />

              {/* Redirect alla prima pagina */}
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </BrowserRouter>
        </ApolloProvider>
      ) : (
        <Loading color={theme.colors.grey[0]} />
      )}
    </div>
  );
};

const root = createRoot(document.getElementById('root')!);
root.render(<Root />);
