import React, { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { EditorFrame } from './EditorFrame';
import { Wizard } from '../../../stores/queries/product';

interface EditorModalProps {
  editorInput: {
    imageUrl?: string;
    historyUrl?: string;
    editorConfig: Wizard;
  };
  orderParams: {
    shopId: string;
    jobId: string;
    orderKey: string;
    attachmentKey: string;
    wizardId: string;
  };
  onImageEditing: (newImageUrl: string, newHistoryUrl: string) => void;
}
const EditorModal = ({ editorInput, orderParams, onImageEditing }: EditorModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <>
      <button
        className="absolute flex justify-center items-center bg-white rounded w-full h-full opacity-0 hover:opacity-80 ease-linear transition-all duration-150"
        onClick={openModal}
      >
        <div className=" rounded-full bg-black opacity-100 py-2 px-3">
          <i className="fa fa-pencil-alt text-3xl text-white" />
        </div>
      </button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-full overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl"
                style={{ height: 'calc(100vh - 80px)' }}
              >
                <div style={{ height: '80px' }}>
                  <Dialog.Title as="h3" className="text-gray-600 text-l p-6 font-bold uppercase">
                    Editor
                  </Dialog.Title>
                </div>
                <EditorFrame
                  editorInput={editorInput}
                  onCloseEditor={closeModal}
                  orderParams={orderParams}
                  onImageEditing={onImageEditing}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { EditorModal };
