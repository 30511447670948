import { gql } from '@apollo/client';
import { Shop } from './shop';

interface LightJob {
  _id: string;
  shop: Shop;
  userData: {
    name: string;
  };
  code: string;
  createdAt: string; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  downloadUrl?: string;
  images: {
    url: string;
    _id: string;
  }[];
}

const LIGHT_JOB_FRAGMENT = gql`
  fragment lightJobFragment on LightJob {
    _id
    shop {
      _id
      name
    }
    userData {
      name
    }
    code
    createdAt
    downloadUrl
    images {
      url
      _id
    }
  }
`;

const LIGHT_JOB = gql`
  ${LIGHT_JOB_FRAGMENT}
  query LIGHT_JOB($_id: ID!) {
    AdminLightJob(_id: $_id) {
      ...lightJobFragment
    }
  }
`;

const LIGHT_JOBS = gql`
  ${LIGHT_JOB_FRAGMENT}
  query LIGHT_JOBS($shopId: ID!, $limit: Int, $offset: Int) {
    AdminLightJobs(shopId: $shopId, limit: $limit, offset: $offset) {
      lightJobs {
        ...lightJobFragment
      }
      count
    }
  }
`;

interface LightJobsData {
  AdminLightJobs: {
    lightJobs: LightJob[];
    count: number;
  };
}

interface LightJobsVariable {
  shopId: string;
  limit?: number;
  offset?: number;
}

/* --------------------------------------------------------------------------*/
/* ----------------------  MUTATION  ----------------------------------------*/
/* --------------------------------------------------------------------------*/

const CREATE_LIGHT_JOB = gql`
  ${LIGHT_JOB_FRAGMENT}
  mutation CREATE_LIGHT_JOB($shopId: ID!, $userData: LightJobUserDataInput) {
    AdminCreateLightJob(shopId: $shopId, userData: $userData) {
      ...lightJobFragment
    }
  }
`;

interface createLightJobData {
  AdminCreateLightJob: LightJob;
}

interface createLightJobVariables {
  shopId: string;
  userData: {
    name: string;
  };
}

// -------------------------------------------------------------------

const CREATE_LIGHT_JOB_ZIP = gql`
  ${LIGHT_JOB_FRAGMENT}
  mutation CREATE_LIGHT_JOB_ZIP($_id: ID!) {
    AdminCreateLightJobZip(_id: $_id) {
      ...lightJobFragment
    }
  }
`;

interface createLightJobZipData {
  AdminCreateLightJobZip: LightJob;
}

interface createLightJobZipVariables {
  _id: string;
}

export { LIGHT_JOB, LIGHT_JOBS, CREATE_LIGHT_JOB, CREATE_LIGHT_JOB_ZIP };
export type {
  LightJob,
  LightJobsData,
  LightJobsVariable,
  createLightJobData,
  createLightJobZipData,
  createLightJobZipVariables,
  createLightJobVariables,
};
