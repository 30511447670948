import React from 'react';

import { theme, Colors } from '../../config';

interface TreeItemTitleProps {
  type: 'product' | 'folder';
  name: string;
  isVisible?: boolean;
  isSponsored?: boolean;
  color?: Colors | null;
}
const TreeItemTitle = (props: TreeItemTitleProps) => {
  let icon = 'fa fa-image';

  switch (props.type) {
    case 'product':
      icon = 'fa fa-image';
      break;
    case 'folder':
      icon = 'fa fa-folder';
      break;
    default:
      icon = 'fa fa-image';
      break;
  }
  let bgColor = 'transparent';
  if (props.color) bgColor = theme.colors[props.color][1];

  let textColor = 'text-gray-600';
  if (props.isVisible === false) {
    textColor = 'text-gray-300';
  }
  let starColor = 'text-amber-400';
  if (props.isVisible === false) {
    starColor = 'text-gray-300';
  }

  return (
    <div className="flex flex-1 items-center">
      <i className={`${textColor} ${icon}`} />
      {props.isSponsored ? <i className={`${starColor} fa fa-star ml-2`} /> : null}
      <h4 className={`${textColor} px-2`}> {props.name}</h4>
      <span className="inline-block w-3 h-3 rounded-full" style={{ background: bgColor }}></span>
    </div>
  );
};

export { TreeItemTitle };
