import React, { useState } from 'react';
import { Address } from '../../stores/queries/shop';
import { Button, EditBoxText } from '../../ui';

import { LOG } from '../../config';

const log = LOG.extend('SHOP_ADDRESS');

interface ShopCommercialAddressProps {
  address: Address;
  index: number;
  updateCommercialAddress: (newAddress: Address, index: number) => void;
  onDelete: (index: number) => void;
}
const ShopCommercialAddress = ({
  address,
  index,
  updateCommercialAddress,
  onDelete,
}: ShopCommercialAddressProps) => {
  const [activeEdit, setActiveEdit] = useState(false);

  return (
    <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
      <div className="flex flex-rox items-center justify-between">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
          {`Indirizzo Negozio ${index ? index + 1 : ''}`}
        </h6>
        <Button size="xs" color="red" onClick={() => onDelete(index)}>
          <i className="fa fa-trash-alt" />
        </Button>
      </div>

      <EditBoxText
        editable={true}
        value={address.denomination}
        label="Nome"
        maxChar={50}
        onEnterEdit={() => {
          setActiveEdit(true);
        }}
        onExitEdit={() => {
          setActiveEdit(false);
        }}
        onPressSave={value => {
          log.info(`update address.denomination with [${value}]`);
          updateCommercialAddress(
            {
              ...address,
              denomination: value || '',
              country: 'IT',
            },
            index
          );
        }}
      />

      <EditBoxText
        editable={true}
        value={address.street}
        label="via"
        maxChar={50}
        isInputValid={value => (value ? true : false)}
        inputInvalidMsg="* campo obbligatorio."
        onEnterEdit={() => {
          setActiveEdit(true);
        }}
        onExitEdit={() => {
          setActiveEdit(false);
        }}
        onPressSave={value => {
          log.info(`update address.street with [${value}]`);
          updateCommercialAddress(
            {
              ...address,
              street: value || '',
              country: 'IT',
            },
            index
          );
        }}
      />
      <div className="flex flex-row">
        <div className="flex w-1/2">
          <EditBoxText
            editable={true}
            value={address.city}
            label="Città"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update address.city with [${value}]`);
              updateCommercialAddress(
                {
                  ...address,
                  city: value || '',
                  country: 'IT',
                },
                index
              );
            }}
          />
        </div>
        <div className="flex w-1/2">
          <EditBoxText
            editable={true}
            value={address.zipCode}
            label="CAP"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update adress.zipCode with [${value}]`);
              updateCommercialAddress(
                {
                  ...address,
                  zipCode: value || '',
                  country: 'IT',
                },
                index
              );
            }}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex w-1/2">
          <EditBoxText
            editable={true}
            value={address.state}
            label="Provincia"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update address.state with [${value}]`);
              updateCommercialAddress(
                {
                  ...address,
                  state: value || '',
                  country: 'IT',
                },
                index
              );
            }}
          />
        </div>
        <div className="flex w-1/2">
          <EditBoxText
            editable={false}
            value={address.country}
            label="Stato"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update address.state with [${value}]`);
              // update({
              //   addresses: {
              //     commercial: shopAddress,
              //     legal: {
              //       ...legalAddress,
              //       state: value || '',
              //     },
              //   },
              // });
            }}
          />
        </div>
      </div>
      <EditBoxText
        editable={true}
        value={address.phoneNumber}
        label="Telefono"
        maxChar={50}
        onEnterEdit={() => {
          setActiveEdit(true);
        }}
        onExitEdit={() => {
          setActiveEdit(false);
        }}
        isInputValid={value => (value ? true : false)}
        inputInvalidMsg="* campo obbligatorio."
        onPressSave={value => {
          log.info(`update legalAddress.phoneNumber with [${value}]`);
          updateCommercialAddress(
            {
              ...address,
              phoneNumber: value || '',
              country: 'IT',
            },
            index
          );
        }}
      />
      {activeEdit && (
        <div
          className=" w-full h-full absolute top-0 left-0 rounded-2xl"
          style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
        ></div>
      )}
    </div>
  );
};

export { ShopCommercialAddress };
