import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useQuery } from '@apollo/client';
import { Job, JOB } from '../../../stores/queries/orders';
import { LOG } from '../../../config';

import { OrderDetail } from '../../../components';
import { Loading } from '../../../ui';

const OrderView = () => {
  const params: any = useParams();

  const { error, data, refetch } = useQuery<{ AdminJob: Job }, { _id: string }>(JOB, {
    variables: { _id: params.id },
    fetchPolicy: 'network-only',
    pollInterval: 3000,
  });

  if (error) {
    LOG.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  if (!data?.AdminJob) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }

  const renderOrderTitle = () => {
    let date = new Date(data.AdminJob.creationDate).toLocaleDateString(undefined, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    let name = data.AdminJob.user?.basicInfo?.name;
    let mail = data.AdminJob.user?.email;
    let id = data.AdminJob._id;

    return (
      <div>
        <h6 className=" text-gray-600 text-xl font-bold mt-1">{name || mail}</h6>
        <h4 className=" text-gray-500 text-base font-bold mb-1">{`${date} - ${id}`}</h4>
      </div>
    );
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4">
      <div className="rounded-t-2xl w-full mb-0 px-6 py-4 bg-gray-200">{renderOrderTitle()}</div>
      <OrderDetail job={data.AdminJob} refetch={refetch} />
    </div>
  );
};

export { OrderView };
