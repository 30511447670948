import React, { useEffect, useState } from 'react';

import { LOG } from '../../../config';

import { Job } from '../../../stores/queries/orders';

import { getPriorDate, numberToEuro } from '../../../libs/utils';

const log = LOG.extend('HOME');

const getRevenue = (jobs: Job[]) => {
  log.debug('CALL getRevenue()');
  let revenue7 = 0;
  let revenue30 = 0;
  const now = new Date();
  const date7 = getPriorDate(now, 7);
  const date30 = getPriorDate(now, 30);

  for (let i = 0; i < jobs.length; i++) {
    if (!jobs[i].creationDate) {
      log.warn(`job[${jobs[i]._id}] do NOT have creationDate`);
      continue;
    }
    if (!jobs[i].finalPrice) {
      log.warn(`job[${jobs[i]._id}] do NOT have finalPrice`);
      continue;
    }

    if (new Date(jobs[i].creationDate) > date7 && jobs[i].jobPayedFromShop) {
      revenue7 += jobs[i].finalPrice;
    }
    if (new Date(jobs[i].creationDate) > date30 && jobs[i].jobPayedFromShop) {
      revenue30 += jobs[i].finalPrice;
    }
  }

  revenue7 = Math.round(revenue7 * 100) / 100;
  revenue30 = Math.round(revenue30 * 100) / 100;
  return {
    revenue7,
    revenue30,
  };
};

interface SalesHomeProps {
  shopEarning: number | null;
  jobs: Job[];
}
const SalesHome = ({ shopEarning, jobs }: SalesHomeProps) => {
  const [revenue7, setRevenue7] = useState<string>('');
  const [revenue30, setRevenue30] = useState<string>('');

  useEffect(() => {
    if (jobs && jobs.length) {
      const rev = getRevenue(jobs);
      setRevenue7(numberToEuro(rev.revenue7));
      setRevenue30(numberToEuro(rev.revenue30));
    } else {
      log.warn('SalesHome - jobs.length NOT found');
    }
  }, [shopEarning, jobs]);

  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Ultimi 7 Giorni</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{`${revenue7}`}</h3>
      </div>

      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Ultimi 30 Giorni</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{`${revenue30}`}</h3>
      </div>

      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Da attivazione Abbonamento</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{`${numberToEuro(shopEarning)}`}</h3>
      </div>
    </div>
  );
};

export { SalesHome };
