import { gql } from '@apollo/client';
import { Product, PRODUCT_FRAGMENT } from './product';
import { TreeItem } from 'react-sortable-tree';
import { Colors } from '../../config';

type ShopStatus = 'running' | 'copying' | 'removing';

interface FileInput {
  fileId: string;
  action: 'insert' | 'remove';
}

const FOLDER_COLORS: { label: string; value: Colors }[] = [
  {
    label: 'Violetto',
    value: 'violet',
  },
  {
    label: 'Blu',
    value: 'blue',
  },
  {
    label: 'Grigio Scuro',
    value: 'dark',
  },
  {
    label: 'Verde',
    value: 'green',
  },
  {
    label: 'Grigio',
    value: 'grey',
  },
  {
    label: 'Grigio Leggero',
    value: 'light',
  },
  {
    label: 'Rosa',
    value: 'pink',
  },
  {
    label: 'Rosso',
    value: 'red',
  },
];

type DisplayType = 'block' | 'list_horizontal';

const FOLDER_DISPLAY: { label: string; value: DisplayType }[] = [
  {
    label: 'Cartella',
    value: 'block',
  },
  {
    label: 'Estesa',
    value: 'list_horizontal',
  },
];

interface ProductTreeLvl {
  type: 'folder' | 'product';
  name: string | null;
  description: string | null;
  isSponsored: boolean | null;
  isVisible: boolean | null;
  picture: {
    url: string | null;
    _id: string | null;
  } | null;
  style: {
    color: Colors | null;
    display?: DisplayType;
  };
  product: Product | null;
  next: ProductTreeLvl[];
}

interface ProductTreeInput {
  type: 'folder' | 'product';
  name?: string;
  description?: string;
  isSponsored?: boolean;
  isVisible?: boolean;
  picture?: {
    url: string | null;
    _id: string | null;
  };
  style?: {
    color: Colors | null;
    display?: DisplayType;
  };
  product?: string;
  next?: ProductTreeInput[];
}

interface EPNode extends TreeItem {
  type?: 'folder' | 'product';
  display?: DisplayType;
  name?: string;
  description?: string;
  picture?: {
    url: string | null;
    _id: string | null;
  };
  color?: Colors | null;
  isSponsored?: boolean;
  isVisible?: boolean;
  product?: Product | null;
}

// ----------------------------------------------------------

interface Address {
  country: 'IT';
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  street: string;
  denomination?: string;
}

interface ShopAddresses {
  commercial: Address[];
  legal: Address;
}

interface ShopPaymentMethod {
  onSite: {
    isEnabled: boolean;
  };
  stripe: {
    isEnabled: boolean;
    stripeInfo?: {
      accountId: string;
    };
  };
}

interface ShopServicePayments {
  earning: number | null;
  startDate: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  endDate: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  credits: number | null;
  annualFee: number | null;
  actualPercentualFee: number | null;
  fees: {
    earningLimit: number | null;
    percentualFee: number | null;
  }[];
}

interface Shop {
  _id: string;
  name: string;
  owner: {
    _id: string;
    username: string;
  };
  type: 'light' | 'full';
  isEnabled: boolean;
  isAccepted: boolean;
  isMaintenance: boolean;
  status: ShopStatus;
  code: string;
  servicePayments: ShopServicePayments;
  vat: string | null;
  deliveryInfo: {
    atHome: {
      allowedPaymentTypes: {
        onSite: boolean;
        stripe: boolean;
      };
      timings: {
        country: string;
        time: number | null;
      }[];
      costs: {
        country: string;
        cost: number | null;
      }[];
    };
    atShop: {
      allowedPaymentTypes: {
        onSite: boolean;
        stripe: boolean;
      };
      time: number | null;
      cost: number | null;
    };
  };
  addresses: ShopAddresses;
  productTree: ProductTreeLvl[];
  paymentMethods: ShopPaymentMethod;
}

const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on ShopBusinessAddress {
    country
    city
    state
    zipCode
    phoneNumber
    street
    denomination
  }
`;

const SERVICE_PAYMENT_FRAGMENT = gql`
  fragment paymentFragment on ShopServicePayments {
    earning
    startDate
    endDate
    credits
    annualFee
    actualPercentualFee
    fees {
      earningLimit
      percentualFee
    }
  }
`;

const SHOP_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${SERVICE_PAYMENT_FRAGMENT}
  fragment shopFragment on Shop {
    _id
    name
    owner {
      _id
      username
    }
    type
    isEnabled
    isAccepted
    isMaintenance
    status
    code
    vat
    servicePayments {
      ...paymentFragment
    }
    addresses {
      commercial {
        ...addressFragment
      }
      legal {
        ...addressFragment
      }
    }
    paymentMethods {
      onSite {
        isEnabled
      }
      stripe {
        isEnabled
        #stripeInfo {
        #  accountId
        #}
      }
    }
    deliveryInfo {
      atShop {
        allowedPaymentTypes {
          onSite
          stripe
        }
        time
        cost
      }
      atHome {
        allowedPaymentTypes {
          onSite
          stripe
        }
        timings {
          country
          time
        }
        costs {
          country
          cost
        }
      }
    }
    productTree {
      # lvl0
      type
      name
      description
      isSponsored
      isVisible
      picture {
        url
        _id
      }
      style {
        color
        display
      }
      product {
        ...productFragment
      }
      next {
        # lvl1
        type
        name
        description
        isSponsored
        isVisible
        picture {
          url
          _id
        }
        style {
          color
          display
        }
        product {
          ...productFragment
        }
        next {
          # lvl2
          type
          name
          description
          isSponsored
          picture {
            url
            _id
          }
          style {
            color
            display
          }
          style {
            color
            display
          }
          product {
            ...productFragment
          }
        }
      }
    }
  }
`;

const SHOP = gql`
  ${SHOP_FRAGMENT}
  query SHOP($_id: ID!) {
    AdminShop(_id: $_id) {
      ...shopFragment
    }
  }
`;

const SHOPS = gql`
  ${SHOP_FRAGMENT}
  query SHOPS {
    AdminShops {
      ...shopFragment
    }
  }
`;

/* --------------------------------------------------------------------------*/
/* ----------------------  MUTATION  ----------------------------------------*/
/* --------------------------------------------------------------------------*/

// ---------------------------------

const UPDATE_SHOP = gql`
  ${SHOP_FRAGMENT}
  mutation UPDATE_SHOP($_id: ID!, $shop: ShopEditInput!) {
    AdminShopEdit(_id: $_id, shop: $shop) {
      ...shopFragment
    }
  }
`;

interface updateShopData {
  AdminShopEdit: Shop;
}

interface updateShopVariables {
  _id: string;
  shop: {
    deliveryInfo?: Shop['deliveryInfo'];
    addresses?: Shop['addresses'];
    paymentMethods?: Shop['paymentMethods'];
    isMaintenance?: Shop['isMaintenance'];
  };
}

// ---------------------------------

const SHOP_STRIPE_ENABLE = gql`
  mutation SHOP_STRIPE_ENABLE($_id: ID!) {
    AdminShopStripeEnable(_id: $_id) {
      success
      link
      error
    }
  }
`;

interface ShopStripeEnableData {
  AdminShopStripeEnable: {
    success: boolean;
    link: string | null;
    error: string | null;
  };
}

interface ShopStripeEnableVariables {
  _id: string;
}

// ---------------------------------

const SHOP_STRIPE_DISABLE = gql`
  ${SHOP_FRAGMENT}
  mutation SHOP_STRIPE_DISABLE($_id: ID!) {
    AdminShopStripeDisable(_id: $_id) {
      ...shopFragment
    }
  }
`;

interface ShopStripeDisableData {
  AdminShopStripeDisable: Shop;
}

interface ShopStripeDisableVariables {
  _id: string;
}

// ---------------------------------

const EDIT_PRODUCT_TREE = gql`
  ${SHOP_FRAGMENT}
  mutation EDIT_PRODUCT_TREE(
    $_id: ID!
    $productTree: [ShopProductTreeLevel0Input]
    $files: [StorageFileInput]
  ) {
    AdminShopEditProductTree(_id: $_id, productTree: $productTree, files: $files) {
      ...shopFragment
    }
  }
`;

interface EditProductTreeData {
  AdminShopEditProductTree: Shop;
}

interface EditProductTreeVariables {
  _id: string;
  productTree: ProductTreeInput[];
  files?: FileInput[];
}

// ---------------------------------

export {
  SHOP,
  SHOP_FRAGMENT,
  SHOPS,
  UPDATE_SHOP,
  SHOP_STRIPE_ENABLE,
  SHOP_STRIPE_DISABLE,
  EDIT_PRODUCT_TREE,
  FOLDER_DISPLAY,
  FOLDER_COLORS,
};
export type {
  Shop,
  Address,
  EPNode,
  DisplayType,
  ShopPaymentMethod,
  ProductTreeLvl,
  ProductTreeInput,
  updateShopData,
  updateShopVariables,
  ShopStripeEnableData,
  ShopStripeEnableVariables,
  ShopStripeDisableData,
  ShopStripeDisableVariables,
  EditProductTreeData,
  EditProductTreeVariables,
  FileInput,
};
