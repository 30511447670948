import React from 'react';
import { useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';

import { USER_LOGGED } from '../stores/queries/appstate';

// views
import { Login } from '../views/auth/Login';

interface AuthProps {}

const Auth = (props: AuthProps) => {
  const { loading, error, data } = useQuery(USER_LOGGED);

  if (data?.userLogged) {
    return <Navigate replace to="/admin" />;
  }

  return (
    <>
      <section className="relative w-full h-full py-7">
        <Login />
      </section>
    </>
  );
};

export { Auth };
