import React, { useState } from 'react';

import { LOG } from '../../config';

import { useMutation, useQuery } from '@apollo/client';
import {
  createLightJobZipData,
  createLightJobZipVariables,
  CREATE_LIGHT_JOB_ZIP,
  LightJob,
  LightJobsData,
  LightJobsVariable,
  LIGHT_JOBS,
} from '../../stores/queries/lightjob';
import { ACTUAL_AUTH } from '../../stores/db/auth';

import { Button, Loading, Table } from '../../ui';
import { LightOrderCreateModal } from '..';

const log = LOG.extend('LIGHT_ORDERS_LIST');

function downloadURI(uri: string, name: string) {
  let link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const JOBS_FOR_PAGES = 30;

const LightOrderList = () => {
  const [page, setPage] = useState(1);

  const { loading, error, data } = useQuery<LightJobsData, LightJobsVariable>(LIGHT_JOBS, {
    variables: {
      shopId: ACTUAL_AUTH.shop || '',
      offset: (page - 1) * JOBS_FOR_PAGES,
      limit: JOBS_FOR_PAGES,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  const [createZip, { loading: createZipLoading, error: createZipError }] = useMutation<
    createLightJobZipData,
    createLightJobZipVariables
  >(CREATE_LIGHT_JOB_ZIP);

  const onDownload = async (job: LightJob) => {
    log.debug('CALL onDownload()');

    if (job.downloadUrl) {
      log.info(`  - downloadUrl already present. Download Job: ${job._id}`);
      downloadURI(job.downloadUrl, job._id);
      return;
    }

    try {
      let createZipResult = await createZip({
        variables: {
          _id: job._id,
        },
      });

      if (!createZipResult.data || !createZipResult.data?.AdminCreateLightJobZip?.downloadUrl) {
        log.error(`  - createZip Error: ${createZipResult.errors}`);
        return;
      }

      const { _id, downloadUrl } = createZipResult.data.AdminCreateLightJobZip;

      log.info(`  - createZip Success! new downloadUrl: ${downloadUrl}`);

      log.info(`  - Download Job: ${_id}`);
      downloadURI(downloadUrl, _id);
    } catch (err) {
      log.error(`  - createZip something went wrong`);
      log.error(`${err}`);
      return;
    }
  };

  const tableColumns = [
    {
      id: 'creationDate',
      name: 'Ricevuto il',
      selector: (row: LightJob) => row.createdAt,
      sortable: true,
      minWidth: '15%',
      cell: (row: LightJob) => {
        if (!row.createdAt) return '-';
        let date = new Date(row.createdAt);
        let dateString = `${date.toLocaleDateString(undefined, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })} - ${date.toLocaleTimeString()}`;
        return dateString;
      },
    },
    {
      id: 'user',
      name: 'Cliente',
      selector: (row: LightJob) => {
        let name = row.userData.name;
        return name || '-';
      },
      sortable: true,
      minWidth: '15%',
      cell: (row: LightJob) => {
        let name = row.userData.name;
        return (
          <div style={{ pointerEvents: 'none' }}>
            <h4 className=" font-bold text-sm">{name || '-'}</h4>
          </div>
        );
      },
    },
    {
      id: 'code',
      name: 'Code',
      minWidth: '15%',
      cell: (row: LightJob) => {
        return row.code;
      },
    },
    {
      id: 'images',
      name: 'Num. Immagini',
      center: true,
      minWidth: '15%',
      cell: (row: LightJob) => {
        return row.images.length;
      },
    },
    {
      id: 'actions',
      name: 'Azioni',
      center: true,
      cell: (row: LightJob) => {
        return (
          <Button
            size="xs"
            color="blue"
            onClick={() => {
              onDownload(row);
            }}
            style={{ alignSelf: 'center' }}
          >
            <i className="fa fa-download" />
          </Button>
        );
      },
    },
  ];

  if (loading)
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <Loading color={'#666'} />
      </div>
    );

  if (error) {
    log.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  return (
    <div className={'relative flex flex-1 flex-col min-w-0 w-full'}>
      <div className={'flex flex-row gap-2 items-center'}>
        <div className={'sm:min-w-[12rem]  py-4'}>
          <LightOrderCreateModal />
        </div>
      </div>
      <div
        className={
          'flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white'
        }
      >
        <Table
          columns={tableColumns}
          data={data?.AdminLightJobs.lightJobs || []}
          height={'calc(100vh - 240px)'}
          title={'Lista Ordini Istantanei'}
          filterSelector={'user.contacts.name'}
          filterPlaceholder={'Cerca per Cliente ...'}
          loading={loading}
          pagination
          paginationServer
          paginationPerPage={JOBS_FOR_PAGES}
          paginationTotalRows={data?.AdminLightJobs.count}
          paginationDefaultPage={page}
          onChangePage={page => {
            log.debug(`change page to ${page}`);
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};

export { LightOrderList };
