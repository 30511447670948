import React, { Fragment, useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';

interface SwitchProps {
  defaultValue?: boolean;
  onChange?: (value: boolean) => void;
}
const MySwitch = (props: SwitchProps) => {
  const [enabled, setEnabled] = useState(props.defaultValue || false);

  const onChange = (value: boolean) => {
    setEnabled(value);
    props.onChange && props.onChange(value);
  };

  return (
    <Switch checked={enabled} onChange={onChange} as={Fragment}>
      {({ checked }) => (
        /* Use the `checked` state to conditionally style the button. */
        <button
          className={`${
            checked ? 'bg-green-600' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full my-2`}
        >
          <span className="sr-only">Metti in Evidenza</span>
          <span
            className={`${
              checked ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </button>
      )}
    </Switch>
  );
};

export { MySwitch };
