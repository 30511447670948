import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

import { LOG } from '../../config';

import { useMutation } from '@apollo/client';
import {
  Product_Typology,
  TYPOLOGIES,
  ADD_PRODUCT,
  AddProductData,
  AddProductVariables,
  MIN_PRODUCT_QUANTITY,
  MAX_PRODUCT_QUANTITY,
} from '../../stores/queries/product';

import { Button, SelectFilter, TextBox } from '../../ui';
import { useFormFields } from '../../hooks/forms';
import { ACTUAL_AUTH } from '../../stores/db/auth';
import { isNumberAndNotEmpty } from '../../libs/utils';

const logger = LOG.extend('PRODUCT_ADD_MODAL');

type SizeInputs = 'width' | 'height' | 'label';

interface ImageSizeInputProps {
  onInputChange: (pageIndex: number, type: SizeInputs, input: string) => void;
  pageIndex: number;
  showLabelInput: boolean;
}

const ImageSizeInput = ({ pageIndex, onInputChange, showLabelInput }: ImageSizeInputProps) => {
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in SizeInputs]: string }>({
    width: '',
    height: '',
    label: '',
  });

  return (
    <div>
      {showLabelInput ? (
        <div className="mt-4">
          <p className="text-sm text-gray-500">inserisci un etichetta all'immagine</p>
          <TextBox
            id="label"
            type="text"
            value={inputs.label}
            placeholder={'Etichetta'}
            required={true}
            onChange={event => {
              handleInputChange(event);
              onInputChange(pageIndex, 'label', event.target.value);
            }}
          />
        </div>
      ) : null}
      <div className="mt-2">
        <p className="text-sm text-gray-500">inserisci le dimensioni</p>
        <div className="flex flex-row items-center">
          <TextBox
            id="height"
            type="text"
            isNumber
            value={inputs.height}
            placeholder={'Altezza'}
            required={true}
            onChange={event => {
              handleInputChange(event);
              onInputChange(pageIndex, 'height', event.target.value);
            }}
          />

          <p className="text-sm px-2 text-gray-500 text">mm</p>
          <p className="text-sm pr-2 text-gray-500 text">-</p>
          <TextBox
            id="width"
            type="text"
            isNumber
            value={inputs.width}
            placeholder={'Lunghezza'}
            required={true}
            onChange={event => {
              handleInputChange(event);
              onInputChange(pageIndex, 'width', event.target.value);
            }}
          />
          <p className="text-sm px-2 text-gray-500">mm</p>
        </div>
      </div>
    </div>
  );
};

const emptyProduct: AddProductVariables['product'] = {
  name: '',
  type: 'pictureStandard',
  prices: {
    quantityDiscounts: [],
  },
  wizards: [
    {
      sizes: {
        height: 0,
        width: 0,
      },
    },
  ],
};

type Inputs = 'name' | 'price' | 'sizes';

const ProductAddModal = () => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTypology, setSelectedTypology] = useState<Product_Typology>('pictureStandard');
  const [numOfImg, setnumOfImg] = useState(1);
  const newProduct = useRef<AddProductVariables['product']>(emptyProduct);
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in Inputs]: string }>({
    name: '',
    price: '',
    sizes: '',
  });
  const [inputsError, setInputErrors] = useState<{ [key in Inputs]: string }>({
    name: '',
    price: '',
    sizes: '',
  });

  // -- -- --
  // -- MUTATIONS --
  // -- -- --

  const [addProduct, { error, data }] = useMutation<AddProductData, AddProductVariables>(
    ADD_PRODUCT
  );

  // -- -- --
  // -- EFFECTS --
  // -- -- --

  useEffect(() => {
    if (data?.AdminProductAdd?._id) {
      logger.info(`! New product register [${data.AdminProductAdd._id}]! -> go to Detail`);
      navigate('/admin/shop/products/' + data.AdminProductAdd._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // se selectedTypology = picture -> lascia solo un wizard
  // se selectedTypology = goods -> elimina tutti i wizard
  // se selectedTypology != goods && no wizard -> crea un wizard
  useEffect(() => {
    if (
      (selectedTypology === 'pictureCreative' || selectedTypology === 'pictureStandard') &&
      newProduct.current.wizards.length > 1
    ) {
      logger.debug('selectedTypology === picture ... leave only first element');
      newProduct.current.wizards.splice(1, newProduct.current.wizards.length - 1);
      if (newProduct.current.wizards[0].label) {
        logger.debug('   ... and delete label');
        delete newProduct.current.wizards[0].label;
      }
      setnumOfImg(1);
    }

    if (selectedTypology === 'goods') {
      logger.debug('selectedTypology === goods ... remove wizards elements');
      newProduct.current.wizards = [];
    } else if (!newProduct.current.wizards.length) {
      logger.debug('selectedTypology != goods && no element ... create first empty element');
      newProduct.current.wizards = [
        {
          sizes: {
            height: 0,
            width: 0,
          },
        },
      ];
    }
  }, [selectedTypology]);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const add = () => {
    logger.info(`CALL addProduct : ${JSON.stringify(newProduct.current)}`);

    addProduct({
      variables: {
        product: newProduct.current,
        shopId: ACTUAL_AUTH.shop || '',
      },
    });
  };

  const resetError = (key: Inputs) => {
    setInputErrors(oldErrors => {
      return {
        ...oldErrors,
        [key]: '',
      };
    });
  };

  const resetForm = () => {
    setInputs({
      name: '',
      price: '',
      sizes: '',
    });
    setInputErrors({
      name: '',
      price: '',
      sizes: '',
    });
    newProduct.current = emptyProduct;
  };

  const isFormValid = () => {
    LOG.debug('CALL formValidation()');
    let isFormValid = true;

    //name - not null
    if (!inputs.name) {
      LOG.info('  - inputs.name NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          name: '* campo obbligatorio.',
        };
      });
      isFormValid = false;
    }

    //price - number not null & positive
    if (!isNumberAndNotEmpty(inputs.price) || Number(inputs.price) < 0) {
      LOG.info('  - inputs.width NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          price: '* campo obbligatorio. Solo numeri positivi',
        };
      });
      isFormValid = false;
    }

    //all sizes - not null & > 0
    for (let i = 0; i < newProduct.current.wizards.length; i++) {
      let sizes = newProduct.current.wizards[i].sizes;
      if (!sizes || sizes.height <= 0) {
        LOG.info('  - inputs.sizes NOT VALID');
        setInputErrors(oldErrors => {
          return {
            ...oldErrors,
            sizes: '* campi obbligatori. Solo numeri positivi',
          };
        });
        isFormValid = false;
      }
      if (!sizes || sizes.width <= 0) {
        LOG.info('  - inputs.sizes NOT VALID');
        setInputErrors(oldErrors => {
          return {
            ...oldErrors,
            sizes: '* dimensioni obbligatorie per ogni immagine',
          };
        });
        isFormValid = false;
      }
    }

    return isFormValid;
  };

  function closeModal() {
    resetForm();
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  const onSizeInputChange = (index: number, type: SizeInputs, input: string) => {
    let sizes = newProduct.current.wizards[index]?.sizes;
    if (!sizes) {
      logger.error(`wizards[${index}].sizes do NOT exist`);
      return;
    }

    if (type === 'label') {
      newProduct.current.wizards[index] = {
        ...newProduct.current.wizards[index],
        label: input,
      };
    }

    if (type === 'height') {
      newProduct.current.wizards[index] = {
        ...newProduct.current.wizards[index],
        sizes: {
          height: Number(input),
          width: sizes.width,
        },
      };
    }
    if (type === 'width') {
      newProduct.current.wizards[index] = {
        ...newProduct.current.wizards[index],
        sizes: {
          height: sizes.height,
          width: Number(input),
        },
      };
    }
    resetError('sizes');
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  if (error) logger.error(error);

  // se selectedTypology = goods -> null
  // se selectedTypology = pictureStandard -> input dimensioni di 1 immagine
  // se selectedTypology = pictureCreative -> input dimensioni di 1 immagine
  // negli altri casi -> input dimensioni a scelta del fotografo (almeno 1 immagine)
  const renderSizesInput = () => {
    if (selectedTypology === 'goods') return null;

    let sizesInput = [];

    for (let i = 0; i < numOfImg; i++) {
      sizesInput.push(
        <div key={i} className="border-b-2 border-gray-500 last:border-b-0">
          <div className="mb-2">
            <ImageSizeInput
              key={i}
              pageIndex={i}
              onInputChange={onSizeInputChange}
              showLabelInput={
                selectedTypology !== 'pictureStandard' && selectedTypology !== 'pictureCreative'
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {selectedTypology === 'pictureStandard' || selectedTypology === 'pictureCreative' ? null : (
          <div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {`Num di immagini necessarie per questo prodotto:`}
              </p>
            </div>
            <div className="flex flex-row mt-2 items-center">
              <h4 className="text-base font-bold uppercase mr-4">{numOfImg}</h4>
              <Button
                className="mr-2"
                size="xs"
                color="green"
                onClick={() => {
                  setnumOfImg(num => num + 1);
                  newProduct.current.wizards.push({
                    sizes: {
                      height: 0,
                      width: 0,
                    },
                  });
                }}
                style={{ alignSelf: 'center' }}
              >
                <i className="fa fa-plus" />
              </Button>
              <Button
                className="mr-2"
                size="xs"
                color="redLight"
                onClick={() => {
                  if (numOfImg > 1) {
                    setnumOfImg(num => num - 1);
                    newProduct.current.wizards.pop();
                  } else {
                    logger.debug('numOfImg can not be < 1');
                  }
                }}
                style={{ alignSelf: 'center' }}
              >
                <i className="fa fa-minus" />
              </Button>
            </div>
          </div>
        )}

        {sizesInput}
        {inputsError.sizes ? <p className="text-xs text-red-400">{inputsError.sizes}</p> : null}
      </div>
    );
  };

  return (
    <>
      <Button
        className="mr-2"
        size="xs"
        color="purple"
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        <i className="fa fa-plus" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l my-2 font-bold uppercase">
                  Crea nuovo prodotto
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">Inserisci il nome</p>
                </div>
                <TextBox
                  id="name"
                  type="text"
                  value={inputs.name}
                  error={inputsError.name}
                  placeholder={'Nome'}
                  required={true}
                  onChange={event => {
                    newProduct.current.name = event.target.value;
                    handleInputChange(event);
                    resetError('name');
                  }}
                />

                <div className="mt-2">
                  <p className="text-sm text-gray-500">Scegli la tipologia</p>
                </div>
                <SelectFilter
                  items={TYPOLOGIES}
                  placeholder={'Tipologia'}
                  defaultValue={TYPOLOGIES[0]}
                  onChange={item => {
                    if (item?.value) {
                      newProduct.current.type = item.value as Product_Typology;
                      setSelectedTypology(item.value as Product_Typology);
                    }
                  }}
                />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">Inserisci il prezzo unitario</p>
                </div>
                <TextBox
                  id="price"
                  type="text"
                  isNumber
                  value={inputs.price}
                  error={inputsError.price}
                  placeholder={'prezzo'}
                  required={true}
                  onChange={event => {
                    // newProduct.current.price = event.target.value;
                    newProduct.current.prices = {
                      quantityDiscounts: [
                        {
                          quantityFrom: MIN_PRODUCT_QUANTITY,
                          quantityTo: MAX_PRODUCT_QUANTITY,
                          newPrice: Number(event.target.value),
                          timeDiscount: {
                            from: null,
                            to: null,
                            newPrice: null,
                          },
                        },
                      ],
                    };
                    handleInputChange(event);
                    resetError('price');
                  }}
                />

                {renderSizesInput()}

                <div className="mt-4 flex flex-row gap-4">
                  <Button
                    onClick={() => {
                      if (isFormValid()) {
                        LOG.info('valid form -> upload');
                        add();
                      } else {
                        LOG.info('NOT valid form -> NOT upload');
                      }
                    }}
                    color="purple"
                  >
                    Crea
                  </Button>
                  <Button onClick={closeModal} color="red">
                    Annulla
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ProductAddModal };
