import React, { CSSProperties, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { theme } from '../config';
import { Button } from './Button';
import { Loading } from './Loading';

interface EditImageProps {
  imageFit?: 'fill' | 'contain' | 'cover';
  labelStyle?: CSSProperties;
  label?: string;
  halfCol?: boolean;
  editable: boolean;
  value?: string;
  id?: string;
  loading?: boolean;
  onChange?: (acceptedFiles: any, onChangeParams?: any) => void;
  onPressRemove?: (id: string) => void;
  onError?: (error: string | null) => void;
}

const EditImage = (props: EditImageProps) => {
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState<string | null>(null);
  const [mouseOver, setMouseOver] = useState(false);

  const onDrop = (acceptedFiles: any, fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      if (
        fileRejections[0].errors?.[0]?.code &&
        fileRejections[0].errors[0].code === 'file-too-large'
      ) {
        setError('file più grande di 10MB!');
      } else {
        setError("impossibile caricare l'immagine");
      }
      props.onError &&
        props.onError(fileRejections[0].file.name + ': ' + fileRejections[0].errors[0].message);
    } else {
      props.onChange && props.onChange(acceptedFiles);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const {
    //getRootProps,
    getInputProps,
    //isDragActive,
    open,
    //acceptedFiles,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 10485760,
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div
      className={
        'w-full lg:w-12/12 px-4 py-2 rounded-2xl ' +
        (props.editable ? 'hover:bg-white ' : '') +
        (props.halfCol ? 'lg:w-6/12' : '')
      }
      onMouseEnter={() => {
        if (props.editable) {
          setMouseOver(true);
        }
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
    >
      <div className={'relative w-full h-full'}>
        {props.label && (
          <label className="block uppercase text-gray-500 text-xs font-bold mb-2">
            {props.label}
          </label>
        )}
        <div className="flex flex-row w-full">
          <div className="relative shadow bg-gray-200 rounded" style={{ height: 85, width: 170 }}>
            <input {...getInputProps()} />
            {props.value ? (
              <img
                src={value}
                alt="CoverImage"
                className="w-full h-full rounded"
                style={props.imageFit ? { objectFit: props.imageFit } : { objectFit: 'cover' }}
              />
            ) : (
              <div className="bg-gray-200 text-gray-500 text-xs font-bold flex justify-center items-center w-full h-full rounded">
                No Image
              </div>
            )}
            {props.loading && (
              <div
                style={{
                  backgroundColor: 'rgba(0,0,0,0.3)',
                }}
                className="absolute w-full h-full bottom-0 left-0 flex flex-col justify-center items-center rounded"
              >
                <Loading color={theme.colors.light[0]} />
              </div>
            )}
            {error && (
              <div
                style={{
                  backgroundColor: 'rgba(0,0,0,0.3)',
                }}
                className="absolute bg-red-400  w-full h-full bottom-0 left-0 flex flex-col justify-center items-center rounded"
              >
                <p className="p-4 text-white bg-red-400 text-xs">{error}</p>
              </div>
            )}
          </div>

          <div className="flex flex-col p-2" hidden={!mouseOver || props.loading}>
            <Button
              color={'purple'}
              onClick={() => {
                if (!props.loading) {
                  open();
                }
              }}
            >
              <i className="fa fa-pencil-alt" />
            </Button>{' '}
            {props.value && (
              <Button
                color={'white'}
                onClick={() => {
                  if (!props.loading && props.id) {
                    props.onPressRemove && props.onPressRemove(props.id);
                  }
                }}
                style={{ marginTop: 4 }}
              >
                <i className="fa fa-times" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditImage };
