import { gql } from '@apollo/client';

export const USER_LOGGED = gql`
  query userLogged {
    userLogged @client
  }
`;

export const SHOP_TYPE = gql`
  query shopType {
    shopType @client
  }
`;
