import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ShopSelect } from '../ShopSelect';
import { UserMenu } from '../User/UserMenu';

const routes = [
  { path: '/', breadcrumb: null },
  { path: '/admin', breadcrumb: 'dashboard' },
  { path: '/admin/shop/products', breadcrumb: 'prodotti' },
  { path: '/admin/shop/products/:id', breadcrumb: 'modifica' },
  { path: '/admin/shop/products/:id/:view', breadcrumb: null },
  { path: '/admin/shop/folders', breadcrumb: 'percorsi' },
  { path: '/admin/sales', breadcrumb: 'vendite' },
  { path: '/admin/sales/lightorders', breadcrumb: 'ordini istantanei' },
  { path: '/admin/sales/orders', breadcrumb: 'ordini' },
  { path: '/admin/sales/orders/:id', breadcrumb: 'dettagli' },
  { path: '/admin/sales/orders/:id/:view', breadcrumb: null },
  { path: '/admin/settings', breadcrumb: 'impostazioni' },
  { path: '/admin/settings/account', breadcrumb: 'account' },
  { path: '/admin/settings/payment', breadcrumb: 'metodi di pagamento' },
  { path: '/admin/settings/delivery', breadcrumb: 'consegna e spedizioni' },
];

const Navbar = () => {
  const breadcrumbs = useBreadcrumbs(routes);
  return (
    <>
      <nav
        style={{ zIndex: 999 }}
        className="absolute hidden md:flex top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start items-center py-4 px-2"
      >
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-2">
          <div className="text-sm text-gray-500 uppercase hidden lg:inline-block font-extrabold rounded-2xl bg-white p-3 border-0">
            {breadcrumbs.map(({ match, breadcrumb }, index) => (
              <span key={match.pathname}>
                {index !== 0 ? <i className={'fas fa-angle-right mx-1'} /> : null}
                <Link className={'hover:text-purple-500'} to={match.pathname}>
                  {breadcrumb}
                </Link>
              </span>
            ))}
          </div>

          <ul className="flex flex-row list-none items-center justify-center">
            <ShopSelect />
            <UserMenu />
          </ul>
        </div>
      </nav>
    </>
  );
};

export { Navbar };
