import React from 'react';
import { Product } from '../../../stores/queries/product';
import { Shop } from '../../../stores/queries/shop';

const getProductsFromShopTree = (shop: Shop) => {
  let products: Product[] = [];
  let lvl0 = shop.productTree;
  if (!lvl0.length) return products;
  for (let i = 0; i < lvl0.length; i++) {
    let pr = lvl0[i].product;
    if (pr) products.push(pr);

    let lvl1 = lvl0[i].next;
    if (!lvl1.length) continue;
    for (let k = 0; k < lvl1.length; k++) {
      let pr = lvl1[k].product;
      if (pr) products.push(pr);

      let lvl2 = lvl1[k].next;
      if (!lvl2.length) continue;
      for (let j = 0; j < lvl2.length; j++) {
        let pr = lvl2[j].product;
        if (pr) products.push(pr);
      }
    }
  }
  return products;
};

const getProductFromType = (products: Product[], type: Product['type']) => {
  let finalProducts: Product[] = [];
  for (let i = 0; i < products.length; i++) {
    if (products[i].type === type) finalProducts.push(products[i]);
  }
  return finalProducts;
};

//------------------------------------------------

interface ProductHomeProps {
  shop: Shop;
  products: Product[];
}
const ProductsHome = ({ shop, products }: ProductHomeProps) => {
  const treeProducts = getProductsFromShopTree(shop);

  const getProductSats = (type: Product['type']) => {
    const typeProducts = getProductFromType(products, type);
    let totProd = typeProducts.length;
    let saleProd = 0;
    for (let i = 0; i < typeProducts.length; i++) {
      let res = treeProducts.find(pr => pr._id === typeProducts[i]._id);
      if (res) saleProd++;
    }

    return `${saleProd} / ${totProd}`;
  };

  return (
    <div>
      <div className="grid grid-cols-2 p-4">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Tipologia</h6>
        <h3 className="text-gray-400 text-sm mt-1 mb-2 font-bold">{'in vendita / totali'}</h3>
      </div>
      <hr className="border-b-1 border-gray-200 mb-2" />
      <div className="grid grid-cols-2 p-4">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Foto</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">
          {getProductSats('pictureStandard')}
        </h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Foto Creative</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">
          {getProductSats('pictureCreative')}
        </h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Tele</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{getProductSats('canvas')}</h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Pannelli</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{getProductSats('panel')}</h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Gadget</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{getProductSats('gadget')}</h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Prodotti Generici</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{getProductSats('goods')}</h3>
      </div>
    </div>
  );
};

export { ProductsHome };
