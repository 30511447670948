import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Button } from '../../ui';

interface StripeFailProps {
  isOpen: boolean;
  onClose?: () => void;
}
const StripeFailModal = ({ isOpen, onClose }: StripeFailProps) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  function closeModal() {
    setIsModalOpen(false);
    onClose && onClose();
  }

  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l mb-2 font-bold uppercase">
                  Account STRIPE non abilitato!
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Qualcosa è andato storto. Prova a rifare la procedura di abilitazione o chiama
                    il supporto tecnico per ricevere assistenza
                  </p>
                </div>

                <div className="mt-4 flex flex-row gap-4">
                  <Button onClick={closeModal} color="blue">
                    Ok
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { StripeFailModal };
