import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMutation } from '@apollo/client';

import { LOG } from '../../config';
import {
  editJobData,
  editJobVariables,
  EDIT_JOB,
  JobPaymentStatus,
  PAYMENT_STATUS,
} from '../../stores/queries/orders';

import { Button } from '../../ui';

const log = LOG.extend('ORDERS_LIST');

interface OrderPaymentStatusModalProps {
  paymentStatus: JobPaymentStatus;
  jobId: string;
}
const OrderPaymentStatusModal = ({ paymentStatus, jobId }: OrderPaymentStatusModalProps) => {
  const [newState, setNewState] = useState(paymentStatus);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal() {
    // setIsModalOpen(true);
    if (
      paymentStatus === 'needActionFromAdmin' ||
      paymentStatus === 'payed' ||
      paymentStatus === 'canceled'
    ) {
      setIsModalOpen(true);
    }
  }

  const [editJob, { error: updateStatusError }] = useMutation<editJobData, editJobVariables>(
    EDIT_JOB
  );

  const updateJobStatus = (newPaymentStatus: JobPaymentStatus) => {
    log.debug(`update Job:${jobId} Status to [${newPaymentStatus}]`);

    let newStatusObj = PAYMENT_STATUS.find(s => s.value === newPaymentStatus);
    if (!newStatusObj) {
      log.error(`   - newStatus: ${newPaymentStatus} UNKNOWN - can not be Set`);
      return;
    }

    editJob({
      variables: {
        jobId,
        data: {
          paymentStatus: newPaymentStatus,
        },
      },
    });
  };

  if (updateStatusError) {
    log.error(JSON.stringify(updateStatusError));
  }

  const getStatusName = (status: JobPaymentStatus) => {
    let statusObj = PAYMENT_STATUS.find(s => s.value === status);
    let statusLabel = statusObj ? statusObj.label : 'Sconosciuto';

    return statusLabel;
  };

  const getStatusColor = (status: JobPaymentStatus) => {
    type Color =
      | 'white'
      | 'purple'
      | 'purpleLight'
      | 'gray'
      | 'grayLight'
      | 'red'
      | 'redLight'
      | 'yellow'
      | 'blue'
      | 'green';

    let statusColor: Color = 'grayLight';

    switch (status) {
      // case 'initializing':
      //   statusColor = 'grayLight';
      //   break;
      // case 'inProcessing':
      //   statusColor = 'grayLight';
      //   break;
      // case 'needActionFromUser':
      //   statusColor = 'grayLight';
      //   break;
      case 'needActionFromAdmin':
        statusColor = 'yellow';
        break;
      case 'payed':
        statusColor = 'green';
        break;
      case 'canceled':
        statusColor = 'red';
        break;
      case 'failed':
        statusColor = 'red';
        break;
      default:
        break;
    }

    return statusColor;
  };

  return (
    <>
      <Button
        size="xs"
        disabled={
          paymentStatus !== 'needActionFromAdmin' &&
          paymentStatus !== 'payed' &&
          paymentStatus !== 'canceled'
        }
        color={getStatusColor(paymentStatus)}
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        {getStatusName(paymentStatus)}
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l mb-2 font-bold uppercase">
                  Modifica Stato Pagamento Ordine
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4 flex justify-center">
                  <Button
                    size="xs"
                    color={
                      newState === 'needActionFromAdmin' ? getStatusColor(newState) : 'grayLight'
                    }
                    onClick={() => {
                      setNewState('needActionFromAdmin');
                    }}
                    style={{ alignSelf: 'center' }}
                  >
                    {getStatusName('needActionFromAdmin')}
                  </Button>
                </div>

                <div className="mt-2 flex justify-center">
                  <i className="fa fa-chevron-down text-gray-600" />
                </div>
                <hr className="mt-2 border-b-1 border-gray-200" />

                <div className=" grid grid-cols-2">
                  <div className="mt-4 flex justify-center">
                    <Button
                      size="xs"
                      color={newState === 'payed' ? getStatusColor(newState) : 'grayLight'}
                      onClick={() => {
                        setNewState('payed');
                      }}
                      style={{ alignSelf: 'center' }}
                    >
                      {getStatusName('payed')}
                    </Button>
                  </div>
                  <div className="mt-4 flex justify-center">
                    <Button
                      size="xs"
                      color={newState === 'canceled' ? getStatusColor(newState) : 'grayLight'}
                      onClick={() => {
                        setNewState('canceled');
                      }}
                      style={{ alignSelf: 'center' }}
                    >
                      {getStatusName('canceled')}
                    </Button>
                  </div>
                </div>

                <hr className="mt-4 border-b-1 border-gray-200" />

                <div className="mt-4 flex flex-row">
                  <div className="w-1/2 flex justify-center">
                    <Button
                      onClick={() => {
                        setNewState(paymentStatus);
                        closeModal();
                      }}
                      color="redLight"
                    >
                      Annulla
                    </Button>
                  </div>
                  <div className="w-1/2 flex justify-center">
                    <Button
                      onClick={() => {
                        updateJobStatus(newState);
                        closeModal();
                      }}
                      color="purple"
                    >
                      Conferma
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { OrderPaymentStatusModal };
