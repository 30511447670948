import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import { numberToEuro } from '../libs/utils';
import { Button } from './Button';

interface EditBoxNumberProps {
  style?: CSSProperties;
  label?: string;
  halfCol?: boolean;
  editable: boolean;
  value?: number;
  maxChar?: number;
  unit?: string;
  isCurrency?: boolean;
  isInteger?: boolean;
  isPositive?: boolean;
  isNotEmpty?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onEnterEdit?: () => void;
  onExitEdit?: () => void;
  validateInput?: (value: number) => boolean;
  inputInvalidMsg?: string;
  onPressSave?: (value?: number) => void;
  onPressCancel?: () => void;
}

const EditBoxNumber = (props: EditBoxNumberProps) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(String(props.value || ''));
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    setValue(String(props.value || ''));
  }, [props.value]);

  useEffect(() => {
    if (edit) {
      props.onEnterEdit && props.onEnterEdit();
    } else {
      props.onExitEdit && props.onExitEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const onSave = () => {
    // if is NOT NUMBER
    if (isNaN(Number(value))) {
      setErrorMsg('* Ammessi solo numeri');
      return;
    }
    // other validations
    if (props.isPositive && Number(value) < 0) {
      setErrorMsg('* Ammessi solo numeri positivi');
      return;
    }
    if (props.isInteger && !Number.isInteger(Number(value))) {
      setErrorMsg('* Ammessi solo numeri interi');
      return;
    }
    if (props.isNotEmpty && Number(value) === 0) {
      setErrorMsg('* Campo Obbligatorio');
      return;
    }
    if (props.validateInput && !props.validateInput(Number(value))) {
      setErrorMsg(props.inputInvalidMsg || '* Invalid Input');
      return;
    }

    let finalValue = Number(value);
    // round if currency
    if (props.isCurrency) {
      finalValue = Math.round(finalValue * 100) / 100;
      setValue(String(finalValue));
    }

    // if is valid...
    setEdit(false);
    errorMsg && setErrorMsg(null);
    props.onPressSave && props.onPressSave(finalValue);
  };

  const onCancel = () => {
    setEdit(false);
    setValue(String(props.value || ''));
    props.onPressCancel && props.onPressCancel();
    errorMsg && setErrorMsg(null);
  };

  return (
    <div
      className={
        'w-full lg:w-12/12 px-4 py-2 rounded-2xl ' +
        (props.editable && !edit ? 'hover:bg-white ' : '') +
        (props.halfCol ? 'lg:w-6/12' : '')
      }
      onClick={e => {
        if (props.editable && !edit) {
          setEdit(true);
        }
      }}
      style={{ zIndex: edit ? 99 : undefined }}
    >
      <div className={'relative w-full ' + (props.editable && 'edit-icon')}>
        {props.label && (
          <label className="block uppercase text-gray-500 text-xs font-bold mb-2">
            {props.label}
          </label>
        )}

        <>
          {value ? (
            <p
              className="text-gray-500 text-xs"
              style={{ whiteSpace: 'break-spaces' }}
              hidden={edit}
            >
              {props.isCurrency ? numberToEuro(Number(value)) : value}
              {props.unit && ' ' + props.unit}
            </p>
          ) : (
            <p className="text-gray-400 text-xs" hidden={edit}>
              -
            </p>
          )}
        </>

        {props.editable && (
          <div className="flex flex-col w-full" hidden={!edit}>
            <form
              onSubmit={evt => {
                evt.preventDefault();
                onSave();
              }}
            >
              <div className="flex flex-col flex-1">
                <input
                  className="flex flex-1 shadow px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
                  value={value || ''}
                  maxLength={props.maxChar}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    let value = event.target.value;
                    value = value.replace(/,/i, '.');
                    setValue(value);
                    props.onChange && props.onChange(event);
                  }}
                />
                {errorMsg ? (
                  <div className="ring-2 ring-red-400 outline-none bg-white rounded-lg mt-1 py-1 px-2 shadow border border-transparent">
                    <p className="text-xs text-red-400">{errorMsg}</p>
                  </div>
                ) : null}
              </div>

              <div className="flex flex-row items-center gap-2 p-2">
                <Button color={'purple'} isSubmitButton>
                  <i className="fa fa-check" />
                </Button>{' '}
                <Button color={'white'} onClick={onCancel} style={{ marginTop: 4 }}>
                  <i className="fa fa-times" />
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export { EditBoxNumber };
