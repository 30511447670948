import React, { ChangeEvent, CSSProperties } from 'react';

interface TextBoxProps {
  key?: any;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  id: string;
  value?: string;
  type: 'password' | 'email' | 'text';
  selectOnFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: string | null;
  label?: string;
  maxChar?: number;
  isNumber?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextBox = (props: TextBoxProps) => {
  let errorStyle = '';
  if (props.error) errorStyle = 'ring-2 ring-red-400 outline-none';
  return (
    <>
      {props.label && (
        <label className="block uppercase text-gray-600 text-xs font-bold mb-2" htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <div>
        <input
          value={props.value}
          required={props.required}
          id={props.id}
          type={props.type}
          disabled={props.disabled}
          maxLength={props.maxChar}
          onChange={event => {
            if (props.isNumber) {
              event.target.value = event.target.value.replace(/,/i, '.');
              if (isNaN(Number(event.target.value))) return;
            }
            props.onChange && props.onChange(event);
          }}
          onFocus={props.selectOnFocus ? e => e.target.select() : undefined}
          className={
            errorStyle +
            'shadow my-2 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150'
          }
          placeholder={props.placeholder}
        />
        {props.error ? <p className="text-xs text-red-400">{props.error}</p> : null}
      </div>
    </>
  );
};

export { TextBox };
