import { gql } from '@apollo/client';
import { Job } from './orders';

type CreditHistoryScope =
  | 'chargedByRoot'
  | 'chargedByRootAsGift'
  | 'chargedByUser'
  | 'dischargedByRoot'
  | 'dischargedByRootAsGift'
  | 'dischargedByOperation';

const CREDITS_LABEL: { label: string; value: CreditHistoryScope }[] = [
  {
    label: 'Aggiunto da Amministratore',
    value: 'chargedByRoot',
  },
  {
    label: 'Aggiunto da Amministratore (GRATIS)',
    value: 'chargedByRootAsGift',
  },
  {
    label: 'Aggiunto da Utente',
    value: 'chargedByUser',
  },
  {
    label: 'Scalato da Amministratore',
    value: 'dischargedByRoot',
  },
  {
    label: 'Scalato da Amministratore (GRATIS)',
    value: 'dischargedByRootAsGift',
  },
  {
    label: 'Scalato da Operazione',
    value: 'dischargedByOperation',
  },
];

interface Credit {
  _id: string;
  // sourceUser: User;
  // targetShop: Shop;
  scope: CreditHistoryScope;
  amount: number;
  oldAmount: number;
  newAmount: number;
  creationDate: string; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  job: Job;
}

const CREDIT_FRAGMENT = gql`
  fragment creditFragment on CreditHistory {
    _id
    scope
    amount
    oldAmount
    newAmount
    creationDate
  }
`;

const CREDIT_HISTORY = gql`
  ${CREDIT_FRAGMENT}
  query CREDIT_HISTORY($shopId: ID!) {
    AdminGetShopCreditHistory(shopId: $shopId) {
      ...creditFragment
    }
  }
`;

export { CREDIT_HISTORY, CREDITS_LABEL };
export type { Credit };
