import { deviceInfo } from './deviceInfo';

import { logger, configLoggerType, consoleTransport } from 'react-native-logs';
import { theme, Colors } from './theme';

const sessionId = 'SES_' + new Date().getTime();

let __DEV__ = true;
if (process.env.REACT_APP_ENV === 'prod') {
  __DEV__ = false;
}

// INIZIALIZZO VARIABILI D'AMBIENTE

interface EnvVars {
  mode: 'test' | 'production';
  basename: string;
  deviceInfo: {
    system: string;
    app: {
      version: string;
      name: string;
    };
  };
  backend: {
    uri: string;
  };
  auth: {
    sessionId: string;
    uri: string;
  };
  db: {
    cacheKey: string;
    authKey: string;
    appKey: string;
  };
  editor: {
    uri: string;
    storage: string;
  };
}

const ENV_VAR_TEST: EnvVars = {
  mode: 'test',
  basename: 'apptest',
  deviceInfo,
  backend: {
    uri: 'https://test.backend.easypixy.com/graphql',
  },
  auth: {
    sessionId: sessionId,
    uri: 'https://test.auth.easypixy.com',
  },
  db: {
    cacheKey: 'EYPY_CACHE_TEST',
    authKey: 'EYPY_AUTH_TEST',
    appKey: 'EYPY_APP_TEST',
  },
  editor: {
    // uri: 'http://192.168.11.17:8080',
    uri: '/editor/index.html',
    storage: 'https://test.storage.easypixy.com/storage',
  },
};

const ENV_VAR_PRODUCTION: EnvVars = {
  mode: 'production',
  basename: 'app',
  deviceInfo,
  backend: {
    uri: 'https://backend.easypixy.com/graphql',
  },
  auth: {
    sessionId: sessionId,
    uri: 'https://auth.easypixy.com',
  },
  db: {
    cacheKey: 'EYPY_CACHE_PROD',
    authKey: 'EYPY_AUTH_PROD',
    appKey: 'EYPY_APP_PROD',
  },
  editor: {
    // uri: 'http://192.168.11.61:8080',
    uri: '/editor/index.html',
    storage: 'https://storage.easypixy.com/storage',
  },
};

let ENV: EnvVars = __DEV__ ? ENV_VAR_TEST : ENV_VAR_PRODUCTION;

const SET_ENV_MODE = (mode: string) => {
  if (mode === 'test') {
    ENV = ENV_VAR_TEST;
    LOG.setSeverity('debug');
  } else if (mode === 'production') {
    ENV = ENV_VAR_PRODUCTION;
    LOG.setSeverity('error');
  }
  LOG.info('MODE CHANGED: ' + mode);
};

// INIZIALIZZO LOGGER

let loggerConfig: configLoggerType = {
  transport: consoleTransport,
  severity: __DEV__ ? 'debug' : 'warn',
  transportOptions: {
    colors: 'web',
  },
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  async: true,
  dateFormat: 'time',
  printLevel: true,
  printDate: true,
  enabled: true,
  enabledExtensions: [
    'STORAGES',
    'AUTHLIB',
    'APOLLOCLIENT',
    'PRODUCT_ADD_MODAL',
    'PRODUCT_DUPLICATE_MODAL',
    'GROUP_ADD_MODAL',
    'PRODUCT_REMOVE_MODAL',
    'GROUP_REMOVE_MODAL',
    'PRODUCTS_LIST',
    'GROUPS_LIST',
    'TREE_VIEW',
    'TREE_UTILS',
    'PRODUCT_PAGES',
    'PRODUCT_PRICES',
    'ORDERS_LIST',
    'ORDER_PRODUCTS',
    'ORDER_DETAIL',
    'PAYMENT',
    'EDITOR',
    'API',
    'PAYMENT',
    'HOME',
    'SHOP_ADDRESS',
    'ACCOUNT',
    'SHOP_REDIRECT',
    'CREDIT_HISTORY',
    'CREATE_LIGHT_JOB_MODAL',
    'LIGHT_ORDERS_LIST',
  ],
};

const LOG = logger.createLogger(loggerConfig);

// LOG AVVIO APP
if (__DEV__) {
  LOG.debug('PMA started in Development');
}

LOG.warn(
  `NEW SESSION | v. ${ENV.deviceInfo.app.version} | ${ENV.deviceInfo.system} | ENV: ${ENV.mode}`
);

export { ENV, LOG, SET_ENV_MODE, theme, __DEV__ };
export type { Colors };
