import { gql } from '@apollo/client';

interface UserBasicInfoAddress {
  name: string
  street: string
  city: string
  province: string
  state: string
  zipCode: string
  phone: string
};

interface User {
  _id: string;
  email: string;
  username: string;
  basicInfo: {
    name: string;
    addresses: {
      home: UserBasicInfoAddress;
      work: UserBasicInfoAddress;
      other: UserBasicInfoAddress;
    };
    firstName: string;
    lastName: string;
    birthYear: number;
    country: string;
    sex: string;
  };
  auth: {
    createdAt: string;
    createdWith: string;
    isActive: boolean;
    isEmailVerified: boolean;
    permission: {
      scopes: string[];
    };
    lastLogin: string;
  };
}

const USER_ME = gql`
  query USER_ME {
    UserMe {
      _id
      email
      username
      basicInfo {
        name
        addresses {
          home {
            name
            street
            city
            province
            state
            zipCode
            phone
          }
          work {
            name
            street
            city
            province
            state
            zipCode
            phone
          }
          other {
            name
            street
            city
            province
            state
            zipCode
            phone
          }
        }
      }
      auth {
        createdAt
        createdWith
        isActive
        isEmailVerified
        permission {
          scopes
        }
        lastLogin
      }
    }
  }
`;

export { USER_ME };
export type { User };
