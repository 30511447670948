import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ImgLogo from '../../assets/img/loghi/easypixy-logo.png';

import { LOG } from '../../config';
import { loginCode, sendAuthCode } from '../../libs/apollo/auth';
import { useFormFields } from '../../hooks/forms';

import { useLazyQuery } from '@apollo/client';
import { Shop, SHOPS } from '../../stores/queries/shop';
import { shopType, userLogged } from '../../stores/db/appstate';

import { Button, TextBox, Loading } from '../../ui';
import { ShopBtn } from '../../components';

const Login = () => {
  const [userId, setUserId] = useState<any>();
  const [inputs, handleInputChange] = useFormFields<{
    email: string;
    code: string;
  }>({
    email: '',
    code: '',
  });

  const [error, setError] = useState<null | string>(null);
  const [opLoad, setOpLoad] = useState<boolean>(false);
  const [loginStep, setLoginStep] = useState<'email' | 'code'>('email');

  const [getShops, { data, error: shopError, loading: shopLoading }] = useLazyQuery<{
    AdminShops: Shop[];
  }>(SHOPS, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (shopError) setError(`${shopError}`);
  }, [shopError]);

  const loginRes = (res: any) => {
    if (res.success) {
      LOG.info('Login Success');
      //setOpLoad(false);
      setUserId(res.user);
      getShops();
    } else {
      setOpLoad(false);
      setError(res.msg);
      LOG.warn('Login fallito: ' + res.msg);
    }
  };

  const login = (email: string, code: string) => {
    setOpLoad(true);
    setError(null);
    loginCode(email, code).then(res => {
      loginRes(res);
    });
  };

  const sendCodeRes = (res: { success: boolean; msg: string }) => {
    if (res.success) {
      LOG.info('email Valid');
      setOpLoad(false);
      setLoginStep('code');
    } else {
      setOpLoad(false);
      setError(res.msg);
      LOG.warn('Login fallito: ' + res.msg);
    }
  };

  const sendCode = (email: string) => {
    setOpLoad(true);
    setError(null);
    sendAuthCode(email).then(res => {
      sendCodeRes(res);
    });
  };

  const onSubmitEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendCode(inputs.email);
  };
  const onSubmitCode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(inputs.email, inputs.code);
  };

  const renderTextinput = () => {
    if (loginStep === 'email') {
      return (
        <form onSubmit={onSubmitEmail}>
          <div className="relative w-full mb-3">
            <TextBox
              key="email"
              id="email"
              type="email"
              // label="Email"
              placeholder="Email"
              required={true}
              onChange={handleInputChange}
            />
          </div>

          <div className="text-center mt-6">
            <Button
              isSubmitButton={true}
              size="md"
              full
              color="purple"
              // onClick={() => sendCode(inputs.email)}
              disabled={opLoad ? true : false}
            >
              {opLoad ? (
                <div className="w-full flex items-center justify-center">
                  <Loading color={'#fff'} height={20} width={20} />
                </div>
              ) : (
                'Invia codice di verifica'
              )}
            </Button>
          </div>
        </form>
      );
    }

    if (loginStep === 'code') {
      return (
        <form onSubmit={onSubmitCode}>
          <div className="relative w-full mb-3">
            <TextBox
              key="code"
              id="code"
              type="text"
              // label="Code"
              placeholder="Codice di verifica"
              required={true}
              onChange={handleInputChange}
            />
          </div>

          <div className="text-center mt-6">
            <Button
              isSubmitButton={true}
              size="md"
              full
              color="purple"
              // onClick={() => login(inputs.email, inputs.code)}
              disabled={opLoad ? true : false}
            >
              {opLoad ? (
                <div className="w-full flex items-center justify-center">
                  <Loading color={'#fff'} height={20} width={20} />
                </div>
              ) : (
                'Accedi'
              )}
            </Button>
          </div>
        </form>
      );
    }

    return null;
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex justify-start items-center w-full flex-col px-4 h-full">
          <div className="relative flex flex-col content-center items-center justify-center">
            <img
              alt="EasyPixy"
              src={ImgLogo}
              className="h-auto align-middle border-none mb-2 max-w-[180px] opacity-90"
            />
            {/* <h1 className="mt-6 text-4xl text-gray-500">EasyPixy</h1> */}
            <h1 className="mb-6 text-xl text-gray-500">Shop Dashboard</h1>
          </div>

          {!data?.AdminShops || !userId ? (
            <div className="w-full flex flex-col lg:w-4/12">
              <div className="relative w-full flex flex-col min-w-0 break-words mb-6 shadow-xl rounded-lg bg-gray-200 border-0">
                <div className="text-gray-400 text-center mb-6 mt-3 font-bold">
                  <h6 className="text-gray-500 text-sm font-bold">Accedi con la tua email</h6>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-4 lg:pb-8 pt-0">
                  {error ? (
                    <div className="bg-red-400 py-2 rounded-lg text-white text-center ml-2 mr-2 mb-3 font-bold">
                      <small>{error}</small>
                    </div>
                  ) : null}
                  {renderTextinput()}
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-center">
              <div className="w-full h-full lg:w-4/12 text-center">
                <h1
                  className="text-gray-400 text-lg font-bold flex-1 m-2 opacity-80"
                  style={{ textAlign: 'center', marginTop: 20 }}
                >
                  Selezeiona il negozio
                </h1>
                <i className="fas fa-chevron-down mb-6 text-gray-400"></i>
                {error && (
                  <div className="w-full flex items-center justify-center">
                    <div className="bg-red-400 py-2 rounded-lg text-white text-center ml-2 mr-2 mb-3 font-bold">
                      <small className="px-2">{error}</small>
                    </div>
                  </div>
                )}
                {shopLoading && (
                  <div className="w-full h-full flex items-center justify-center py-8">
                    <Loading color={'#666'} />
                  </div>
                )}
                {data?.AdminShops &&
                  data.AdminShops.map((shop: Shop, index: number) => (
                    <ShopBtn
                      key={index}
                      shop={shop}
                      onChangeSuccess={() => {
                        userLogged(userId);
                        shopType(shop.type);
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { Login };
