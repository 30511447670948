import { setContext } from "@apollo/client/link/context";

import { ACTUAL_AUTH } from "../../stores/db/auth";

// Middleware che aggiunge gli header auth alle richieste apollo
const authLink = setContext((_, { headers }) => {
  const token = ACTUAL_AUTH.accessToken;
  return {
    headers: {
      ...headers,
      authentication: token || "",
    },
  };
});

export { authLink };
