import React from 'react';
import { Oval } from 'react-loader-spinner';

interface LoadingProps {
  color?: string;
  width?: number;
  height?: number;
}

const Loading = (props: LoadingProps) => {
  return (
    <Oval color={props.color || '#fff'} height={props.height || 30} width={props.width || 30} />
  );
};

export { Loading };
