import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { LOG } from '../../config';

import { useMutation } from '@apollo/client';
import {
  Product,
  PRODUCTS,
  RemoveProductData,
  RemoveProductVariables,
  REMOVE_PRODUCT,
} from '../../stores/queries/product';

import { Button } from '../../ui';
import { ACTUAL_AUTH } from '../../stores/db/auth';

const logger = LOG.extend('PRODUCT_REMOVE_MODAL');

interface ProductRemovemodalProps {
  product: Product;
}
const ProductRemoveModal = ({ product }: ProductRemovemodalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removeFail, setRemoveFail] = useState(false);
  const [removeProduct, { error, data }] = useMutation<RemoveProductData, RemoveProductVariables>(
    REMOVE_PRODUCT,
    {
      refetchQueries: [
        {
          query: PRODUCTS,
          variables: { shopId: ACTUAL_AUTH.shop },
        },
      ],
    }
  );

  useEffect(() => {
    if (data && data.AdminProductRemove === false) {
      logger.warn(`Remove product Fail! -> mutation return false`);
      setRemoveFail(true);
    }
  }, [data]);

  const remove = () => {
    logger.info(`CALL removeProduct : ${product.name}`);

    // files/masks to remove
    let fileIds: string[] = [];
    for (let i = 0; i < product.wizards.length; i++) {
      let cutMaskId = product.wizards[i].masks?.cut?._id;
      if (cutMaskId) {
        fileIds.push(cutMaskId);
        logger.debug(`  - remove also cutMask ${cutMaskId}`);
      }
      let safetyMaskId = product.wizards[i].masks?.safety?._id;
      if (safetyMaskId) {
        fileIds.push(safetyMaskId);
        logger.debug(`  - remove also safetyMask ${safetyMaskId}`);
      }
    }

    removeProduct({
      variables: {
        _id: product._id,
        files: fileIds.length ? fileIds : undefined,
      },
    });
  };

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  if (error || removeFail) {
    logger.error(`something went wrong - ${error || removeFail} `);
  }

  return (
    <>
      <Button size="xs" color="red" onClick={openModal}>
        <i className="fa fa-trash-alt" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l mb-2 font-bold uppercase">
                  Elimina prodotto
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Sei sicuro di voler eliminare definitivamente
                  </p>
                  <p className="text-sm text-gray-500">
                    "<span className="font-bold">{`${product.name}`}</span>"?
                  </p>
                </div>

                <div className="mt-2">
                  <p className="text-sm text-gray-500">Quest'azione è irreversibile</p>
                </div>

                <div className="mt-4 flex flex-row gap-4">
                  <Button
                    onClick={() => {
                      remove();
                      closeModal();
                    }}
                    color="purple"
                  >
                    Elimina
                  </Button>
                  <Button onClick={closeModal} color="red">
                    Annulla
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ProductRemoveModal };
