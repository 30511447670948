import React, { useState } from 'react';
import { Address } from '../../stores/queries/shop';
import { EditBoxText } from '../../ui';

import { LOG } from '../../config';

const log = LOG.extend('SHOP_ADDRESS');

interface ShopLegalAddressProps {
  address: Address;
  updateLegalAddress: (newAddress: Address) => void;
}
const ShopLegalAddress = ({ address, updateLegalAddress }: ShopLegalAddressProps) => {
  const [activeEdit, setActiveEdit] = useState(false);

  return (
    <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
      <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
        Indirizzo - fatturazione
      </h6>
      <EditBoxText
        editable={true}
        value={address.street}
        label="via"
        maxChar={50}
        isInputValid={value => (value ? true : false)}
        inputInvalidMsg="* campo obbligatorio."
        onEnterEdit={() => {
          setActiveEdit(true);
        }}
        onExitEdit={() => {
          setActiveEdit(false);
        }}
        onPressSave={value => {
          log.info(`update address.street with [${value}]`);
          updateLegalAddress({
            ...address,
            street: value || '',
            country: 'IT',
          });
        }}
      />
      <div className="flex flex-row">
        <div className="flex w-1/2">
          <EditBoxText
            editable={true}
            value={address.city}
            label="Città"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update address.city with [${value}]`);
              updateLegalAddress({
                ...address,
                city: value || '',
                country: 'IT',
              });
            }}
          />
        </div>
        <div className="flex w-1/2">
          <EditBoxText
            editable={true}
            value={address.zipCode}
            label="CAP"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update adress.zipCode with [${value}]`);
              updateLegalAddress({
                ...address,
                zipCode: value || '',
                country: 'IT',
              });
            }}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex w-1/2">
          <EditBoxText
            editable={true}
            value={address.state}
            label="Provincia"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update address.state with [${value}]`);
              updateLegalAddress({
                ...address,
                state: value || '',
                country: 'IT',
              });
            }}
          />
        </div>
        <div className="flex w-1/2">
          <EditBoxText
            editable={false}
            value={address.country}
            label="Stato"
            maxChar={50}
            isInputValid={value => (value ? true : false)}
            inputInvalidMsg="* campo obbligatorio."
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              log.info(`update address.state with [${value}]`);
              // update({
              //   addresses: {
              //     commercial: shopAddress,
              //     legal: {
              //       ...legalAddress,
              //       state: value || '',
              //     },
              //   },
              // });
            }}
          />
        </div>
      </div>
      <EditBoxText
        editable={true}
        value={address.phoneNumber}
        label="Telefono"
        maxChar={50}
        onEnterEdit={() => {
          setActiveEdit(true);
        }}
        onExitEdit={() => {
          setActiveEdit(false);
        }}
        isInputValid={value => (value ? true : false)}
        inputInvalidMsg="* campo obbligatorio."
        onPressSave={value => {
          log.info(`update legalAddress.phoneNumber with [${value}]`);
          updateLegalAddress({
            ...address,
            phoneNumber: value || '',
            country: 'IT',
          });
        }}
      />
      {activeEdit && (
        <div
          className=" w-full h-full absolute top-0 left-0 rounded-2xl"
          style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
        ></div>
      )}
    </div>
  );
};

export { ShopLegalAddress };
