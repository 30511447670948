// @ts-ignore
import omitDeep from 'omit-deep';
import Resizer from 'react-image-file-resizer';
import { LOG } from '../../config';

const transformApolloData = (data: Object) => {
  if (!data) return null;
  try {
    let tData = JSON.parse(JSON.stringify(data));
    return omitDeep(tData, '__typename');
  } catch (error) {
    LOG.error(error);
    return null;
  }
};

const copyTextToClipboard = (text: string) => {
  var textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = '0px';
  textArea.style.left = '0px';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0px';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  document.body.appendChild(textArea);
  textArea.value = text;
  textArea.focus();
  textArea.select();
  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    LOG.info('Copying text command was ' + msg);
  } catch (err) {
    LOG.error('Oops, unable to copy');
  }
  document.body.removeChild(textArea);
};

const addDaysToDate = (date: Date, days: number) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const isNumber = (value: any, min?: number, max?: number) => {
  if (isNaN(Number(value))) return false;
  if (min !== undefined && Number(value) < min) return false;
  if (max !== undefined && Number(value) > max) return false;
  return true;
};
const isNumberAndNotEmpty = (value: any) => {
  if (value === null || value === undefined || value === '') return false;
  if (isNaN(Number(value))) return false;
  return true;
};

const base64ToFile = async (base64: string, fileName: string) => {
  const res: Response = await fetch(base64);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpeg' });
};

const fileToBase64 = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

const objectToFile = (data: Object, fileName: string) => {
  return new File([JSON.stringify(data)], fileName, { type: 'application/json' });
};

const openExternalLink = (url: string, newTab?: boolean) => {
  const newWindow = window.open(url, newTab ? '_blank' : '_self', 'noopener, noreferrer');
  if (newWindow) newWindow.opener = null;
};

const resolveObjectPath = (object: any, path: string, deaultValue: any) =>
  path.split('.').reduce((o, p) => o?.[p] ?? deaultValue, object);

// obj = {a:{b:{c: 1}}}
// resolveObjectPath(obj, 'a.b.c', undefined) => 1
// resolveObjectPath(obj, 'a.b', undefined) => {c:1}
// resolveObjectPath(obj, 'a.b.c.d', undefined) => undefined
// resolveObjectPath(obj, 'a.b.c.d', null) => null

const getPriorDate = (myDate: Date, daysBefore: number) => {
  let date = new Date(myDate);
  let priorDate = date.setDate(date.getDate() - daysBefore);
  return new Date(priorDate);
};

const resizeImage = (file: File, maxSize?: number, quality?: number) =>
  new Promise<File>(resolve => {
    if (!maxSize) maxSize = 720;
    if (!quality) quality = 75;
    Resizer.imageFileResizer(
      file,
      maxSize, //New image max width (ratio is preserved)
      maxSize, //New image max height (ratio is preserved)
      'JPEG', //Can be either JPEG, PNG or WEBP.
      quality, //A number between 0 and 100.
      0, //Degree of clockwise rotation to apply to the image.
      uri => resolve(uri as File), //Callback function of URI.
      'file' //Can be either base64, blob or file.
    );
  });

const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
});

const numberToEuro = (n: any) => {
  if (typeof n !== 'number') return '-';
  return formatter.format(n);
}

export {
  transformApolloData,
  copyTextToClipboard,
  addDaysToDate,
  isNumber,
  isNumberAndNotEmpty,
  base64ToFile,
  fileToBase64,
  objectToFile,
  openExternalLink,
  resolveObjectPath,
  getPriorDate,
  resizeImage,
  numberToEuro,
};
