import React, { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { Job } from '../../stores/queries/orders';

import { Button } from '../../ui';

interface OrderUserInfoModalProps {
  userInfo: Job['user'];
  delivery: Job['delivery'];
}
const OrderUserInfoModal = ({ userInfo, delivery }: OrderUserInfoModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  const renderUserInfo = () => {
    // se utente ospite
    if (!userInfo) {
      return (
        <div className="mt-2">
          <p className="text-sm text-gray-600">Utente Ospite - Non registrato</p>
        </div>
      );
    }

    // se utente registrato
    let name = userInfo.basicInfo?.name || '-';

    return (
      <div>
        <div className="mt-2">
          <p className="text-sm text-gray-600">Nome</p>
          <p className="text-sm text-gray-500">{`${name}`}</p>
        </div>
        <div className="mt-2">
          <div className="flex flex-row">
            <div className="w-1/2">
              <p className="text-sm text-gray-600">UserName</p>
              <p className="text-sm text-gray-500">{`${userInfo.username}`}</p>
            </div>
            <div className="w-1/2">
              <p className="text-sm text-gray-600">{'E-mail (di registrazione)'}</p>
              <p className="text-sm text-gray-500">{`${userInfo.email}`}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDeliveryInfo = () => {
    return (
      <>
        <div className="mt-2">
          <div className="flex flex-row">
            <div className="w-1/2">
              <p className="text-sm text-gray-600">Nome</p>
              <p className="text-sm text-gray-500">{delivery.address?.name || '-'}</p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex flex-row">
            <div className="w-1/2">
              <p className="text-sm text-gray-600">Telefono</p>
              <p className="text-sm text-gray-500">{delivery.address?.phone || '-'}</p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex flex-row">
            <div className="w-1/2">
              <p className="text-sm text-gray-600">Via</p>
              <p className="text-sm text-gray-500">{delivery.address?.street || '-'}</p>
            </div>
            <div className="w-1/2">
              <p className="text-sm text-gray-600">Città</p>
              <p className="text-sm text-gray-500">{delivery.address?.city || '-'}</p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex flex-row">
            <div className="w-1/2">
              <p className="text-sm text-gray-600">CAP</p>
              <p className="text-sm text-gray-500">{delivery.address?.zipCode || '-'}</p>
            </div>
            <div className="w-1/2">
              <p className="text-sm text-gray-600">Provincia</p>
              <p className="text-sm text-gray-500">{delivery.address?.province || '-'}</p>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex flex-row">
            <div className="w-1/2">
              <p className="text-sm text-gray-600">Stato</p>
              <p className="text-sm text-gray-500">{delivery.address?.state || '-'}</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Button size="xs" color="blue" onClick={openModal} style={{ alignSelf: 'center' }}>
        <i className="fa fa-user-alt" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l mb-2 font-bold uppercase">
                  Informazioni utente
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500 font-bold">Utente</p>
                  {renderUserInfo()}
                </div>

                <hr className="mt-2 border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500 font-bold">{'Spedizione'}</p>
                  {renderDeliveryInfo()}
                </div>

                <div className="mt-4 flex">
                  <Button onClick={closeModal} color="blue">
                    Ok
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { OrderUserInfoModal };
