import { CachePersistor } from 'apollo3-cache-persist';
import { ApolloClient, from, InMemoryCache } from '@apollo/client';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';

import { ENV, LOG, __DEV__ } from '../../config';
import { cacheStorage } from '../storages';
import { authLink } from './authLink';
import { onErrorRefreshTokenLink } from './onErrorRefreshTokenLink';
import { shopType, userLogged } from '../../stores/db/appstate';
import { setExtApolloClient } from './auth';

const log = LOG.extend('APOLLOCLIENT');

let oertl: any = onErrorRefreshTokenLink;
let client: any = null;

// Funzione che recupera la cache e crea il client apollo
const createApolloClient = async () => {
  log.debug('CREATE CLIENT ON URI: ' + ENV.backend.uri);

  try {
    const httpLink = createUploadLink({ uri: ENV.backend.uri });

    // Configuro cache poer rendere reattivo il parametro locale "userLogged" e "shopType"
    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            userLogged: {
              read() {
                return userLogged();
              },
            },
            shopType: {
              read() {
                return shopType();
              },
            },
          },
        },
        ProductGroup: {
          fields: {
            products: {
              merge: false,
            },
          },
        },
      },
    });

    // Configurazione persistenza cache in local storage / Async Storage
    const persistor = new CachePersistor({
      trigger: 'background',
      // trigger: 'write',
      storage: cacheStorage,
      cache,
      key: ENV.db.cacheKey,
      maxSize: 2000000,
      debug: __DEV__,
    });

    try {
      await persistor.restore();
      __DEV__ &&
        persistor.getSize().then(cacheSize => {
          log.debug('CACHE RESTORED, SIZE: ' + cacheSize + ' bytes');
        });
    } catch ({ message }) {
      log.error(`Unable to Restore Cache: ${message}`);
    }

    client = new ApolloClient({
      cache,
      link: from([authLink, oertl, httpLink]),
      resolvers: {},
    });

    client.onClearStore(async () => {
      await persistor.purge();
      log.warn('Offline Cache Purged');
    });

    setExtApolloClient(client);
  } catch ({ message }) {
    log.error(`Create client ERROR: ${message}`);
  }
  return client;
};

// Funzione che restituisce il client all'esterno
const getApolloClient = () => {
  return client;
};

export { createApolloClient, getApolloClient };
