/*eslint-disable*/
import { useQuery } from '@apollo/client';
import React, { CSSProperties, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ImgLogo from '../../assets/img/loghi/easypixy-logo.png';
import { ENV, __DEV__ } from '../../config';
import { SHOP_TYPE } from '../../stores/queries/appstate';

interface LinkItemProps {
  uri: string;
  title: string;
  icon?: string;
  disabled?: boolean;
}
const LinkItem = ({ uri, title, icon, disabled }: LinkItemProps) => {
  const location = useLocation();
  if (!icon) {
    icon = 'fa-circle';
  }
  let finalStyle: CSSProperties | undefined = undefined;
  let textStyle: string =
    location.pathname === uri
      ? 'text-purple-500 hover:text-purple-700'
      : 'text-gray-500 hover:text-gray-700';
  if (disabled) {
    finalStyle = { pointerEvents: 'none' };
    textStyle = 'text-gray-300';
  }
  return (
    <Link
      style={finalStyle}
      className={`text-xs uppercase py-3 font-bold block ${textStyle}`}
      to={uri}
    >
      <i className={'fas ' + icon + ' mr-2 text-sm opacity-75'}></i> {title}
    </Link>
  );
};

const Sidebar = () => {
  const [collapseShow, setCollapseShow] = useState('hidden');

  const { data: shopTypeData } = useQuery<{ shopType: 'full' | 'light' }>(SHOP_TYPE);

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-lg bg-white flex flex-wrap items-center justify-between relative md:w-60 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <div className="flex flex-col justify-center">
            <img
              alt="EasyPixy"
              src={ImgLogo}
              style={{
                width: 120,
              }}
              className="h-auto align-middle border-none opacity-90 mb-2"
            />
            {/* <h1 className="text-2xl">EasyPixy</h1> */}
            <small className="hidden md:block text-gray-500">SHOP DASHBOARD</small>
            <small className="hidden md:block text-gray-500">
              {ENV.deviceInfo.app.version}
              {__DEV__ && ' test'}
            </small>
          </div>

          {/* Buttons */}
          <ul className="md:hidden items-center flex flex-wrap list-none"></ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <h6 className="md:block text-left md:pb-2 text-gray-500 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0">
                    EasyPixy
                  </h6>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <hr className="my-4 md:min-w-full" />

            <h6
              onClick={() => setCollapseShow('hidden')}
              className="md:min-w-full text-gray-400 text-xs uppercase font-bold block no-underline"
            >
              <LinkItem
                title="Dashboard"
                icon="fa-home"
                uri={'/admin'}
                disabled={shopTypeData?.shopType === 'light' ? true : false}
              />
            </h6>

            <hr className="my-4 md:min-w-full" />

            <h6 className="md:min-w-full text-gray-400 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Shop
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <LinkItem
                  title="Prodotti"
                  icon="fa-image"
                  uri={'/admin/shop/products'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
                <LinkItem
                  title="Percorsi"
                  icon="fa-route"
                  uri={'/admin/shop/folders'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
              </li>
            </ul>

            <hr className="my-4 md:min-w-full" />

            <h6 className="md:min-w-full text-gray-400 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Vendite
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {/* <li className="items-center">
                <LinkItem
                  title="Clienti"
                  icon="fa-address-book"
                  uri={'/admin/dashboard'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
              </li> */}
              <li className="items-center">
                <LinkItem
                  title="Ordini"
                  icon="fa-file"
                  uri={'/admin/sales/orders'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
              </li>
              <li className="items-center">
                <LinkItem
                  title="Ordini Istantanei"
                  icon="fa-file-arrow-down"
                  uri={'/admin/sales/lightorders'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
              </li>
            </ul>

            <hr className="my-4 md:min-w-full" />

            <h6 className="md:min-w-full text-gray-400 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Impostazioni
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <LinkItem
                  title="Spedizione"
                  icon="fa-paper-plane"
                  uri={'/admin/settings/delivery'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
              </li>
              <li className="items-center">
                <LinkItem
                  title="Pagamento"
                  icon="fa-credit-card"
                  uri={'/admin/settings/payment'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
              </li>
              <li className="items-center">
                <LinkItem
                  title="Account"
                  icon="fa-cog"
                  uri={'/admin/settings/account'}
                  disabled={shopTypeData?.shopType === 'light' ? true : false}
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export { Sidebar };
