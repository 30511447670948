import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Colors, LOG } from '../../config';

import { SelectFolderColor, SelectFolderCover } from '..';
import { Button, MySwitch, SelectFilter, TextBox } from '../../ui';
import { useFormFields } from '../../hooks/forms';
import { EPNode, DisplayType, FOLDER_DISPLAY } from '../../stores/queries/shop';

interface NewFolderData {
  name?: string;
  description?: string;
  isSponsored?: boolean;
  isVisible?: boolean;
  display?: DisplayType;
  color?: Colors;
  pictureFile?: File | 'empty';
}

interface EditFolderModalProps {
  node: EPNode;
  treeIndex: number;
  onFolderChange: (treeIndex: number, folderData: NewFolderData) => void;
}

type Inputs = 'name' | 'description';

const log = LOG.extend('TREE_VIEW');

const EditFolderModal = ({ node, treeIndex, onFolderChange }: EditFolderModalProps) => {
  // -- -- --
  // -- STATES/REF --
  // -- -- --
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newFolderData = useRef<NewFolderData>({});
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in Inputs]: string }>({
    name: node.name || '',
    description: node.description || '',
  });
  const [newPictureFile, setNewPictureFile] = useState<File | 'empty' | undefined>();
  const [inputsError, setInputErrors] = useState<{ [key in Inputs]: string }>({
    name: '',
    description: '',
  });

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const getPictureUrl = (): string | undefined => {
    if (newPictureFile === 'empty') {
      return undefined;
    }
    if (newPictureFile) {
      return URL.createObjectURL(newPictureFile);
    }
    if (node.picture && node.picture.url) {
      return node.picture.url;
    }
    return undefined;
  };

  const resetForm = () => {
    setInputs({
      name: node.name || '',
      description: node.description || '',
    });
    setInputErrors({
      name: '',
      description: '',
    });
    newFolderData.current = {};
  };

  const resetError = (key: Inputs) => {
    setInputErrors(oldErrors => {
      return {
        ...oldErrors,
        [key]: '',
      };
    });
  };

  const isFormValid = () => {
    log.debug('CALL isFormValid()');
    let isFormValid = true;

    //name - not empty
    if (!inputs.name) {
      LOG.info('  - inputs.name NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          name: '* Campo obbligatorio',
        };
      });
      isFormValid = false;
    }

    return isFormValid;
  };

  const onChangeCover = (file: File) => {
    setNewPictureFile(file);
    newFolderData.current.pictureFile = file;
  };

  const onRemoveCover = () => {
    setNewPictureFile('empty');
    newFolderData.current.pictureFile = 'empty';
  };

  function closeModal() {
    resetForm();
    setNewPictureFile(undefined);
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  const getInitialDisplayItem = (display?: DisplayType) => {
    let item = FOLDER_DISPLAY.find(item => item.value === display);
    if (!item) item = FOLDER_DISPLAY[0];
    return item;
  };

  const renderInputFields = () => {
    if (node.type === 'product') {
      return (
        <div>
          <div className="mt-2">
            <p className="text-sm text-gray-500">In Evidenza</p>
          </div>
          <div className="">
            <MySwitch
              defaultValue={node.isSponsored}
              onChange={value => (newFolderData.current.isSponsored = value)}
            />
          </div>
          <div className="mt-4">
            <p className="text-sm text-gray-500">Colore</p>
          </div>

          <SelectFolderColor
            initialValue={node.color ? node.color : undefined}
            onChange={color => {
              newFolderData.current.color = color;
            }}
          />
          <div className="my-4">
            <p className="text-sm text-gray-500">
              Per modificare gli altri parametri (
              <span className="font-bold">Nome, Descrizione, Immagine</span>) vai nell'apposita
              lista
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="mt-4">
            <p className="text-sm text-gray-500">Nome</p>
          </div>
          <TextBox
            id="name"
            type="text"
            value={inputs.name}
            error={inputsError.name}
            placeholder={'Nome'}
            required={true}
            onChange={event => {
              newFolderData.current.name = event.target.value;
              handleInputChange(event);
              resetError('name');
            }}
          />

          <div className="my-2">
            <p className="text-sm text-gray-500">Descrizione</p>
          </div>
          <TextBox
            id="description"
            type="text"
            maxChar={500}
            value={inputs.description}
            placeholder={'Descrizione'}
            required={true}
            onChange={event => {
              newFolderData.current.description = event.target.value;
              handleInputChange(event);
            }}
          />

          <div className="mt-2">
            <p className="text-sm text-gray-500">Visualizzazione</p>
          </div>
          <div className="">
            <SelectFilter
              items={FOLDER_DISPLAY}
              placeholder={'Visualizzazione'}
              defaultValue={getInitialDisplayItem(node.display)}
              zIndex={1}
              onChange={item => {
                if (item?.value && item.value !== node.display) {
                  newFolderData.current.display = item.value as DisplayType;
                }
              }}
            />
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-500">In Evidenza</p>
          </div>
          <div className="">
            <MySwitch
              defaultValue={node.isSponsored}
              onChange={value => (newFolderData.current.isSponsored = value)}
            />
          </div>

          <div className="mt-2">
            <p className="text-sm text-gray-500">Visibile in App</p>
          </div>
          <div className="">
            <MySwitch
              defaultValue={node.isVisible}
              onChange={value => (newFolderData.current.isVisible = value)}
            />
          </div>

          <div className="mt-4">
            <p className="text-sm text-gray-500">Colore</p>
          </div>

          <SelectFolderColor
            initialValue={node.color ? node.color : undefined}
            onChange={color => {
              newFolderData.current.color = color;
            }}
          />

          <div className="mt-4 mb-2">
            <p className="text-sm text-gray-500">Immagine</p>
          </div>
          <SelectFolderCover
            value={getPictureUrl()}
            onChange={files => {
              onChangeCover(files[0]);
            }}
            onPressRemove={id => {
              onRemoveCover();
            }}
          />
        </div>
      );
    }
  };

  return (
    <>
      <Button
        className="mx-1"
        size="xs"
        color="purple"
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        <i className="fa fa-pencil-alt" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l my-2 font-bold uppercase">
                  Modifica Cartella
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div>
                  {renderInputFields()}

                  <div className="mt-4 flex flex-row gap-4">
                    <Button
                      onClick={() => {
                        if (isFormValid()) {
                          log.info('valid form -> upload');
                          onFolderChange(treeIndex, newFolderData.current);
                          closeModal();
                        } else {
                          log.info('NOT valid form -> NOT upload');
                        }
                      }}
                      color="purple"
                    >
                      Salva
                    </Button>
                    <Button onClick={closeModal} color="red">
                      Annulla
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { EditFolderModal };
