import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useMutation, useQuery } from '@apollo/client';
import {
  Shop,
  SHOP,
  SHOP_STRIPE_ENABLE,
  ShopStripeEnableData,
  ShopStripeEnableVariables,
  SHOP_STRIPE_DISABLE,
  ShopStripeDisableVariables,
  ShopStripeDisableData,
  UPDATE_SHOP,
  updateShopData,
  updateShopVariables,
} from '../../../stores/queries/shop';
import { ACTUAL_AUTH } from '../../../stores/db/auth';

import { LOG } from '../../../config';

import { openExternalLink, transformApolloData } from '../../../libs/utils';
import { StripeModal, StripeSuccessModal, StripeFailModal, OnSiteModal } from '../../../components';

import { Loading, MySwitch } from '../../../ui';

const log = LOG.extend('PAYMENT');

type PaymentParams = {
  view: 'stripesuccess' | 'stripefail';
};

const PaymentView = () => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const params = useParams<PaymentParams>();
  const [genericStripeError, setGenericStripeError] = useState<string | null>(null);

  // -- -- --
  // -- QUERY --
  // -- -- --
  const { error, data } = useQuery<{ AdminShop: Shop }, { _id: string }>(SHOP, {
    variables: { _id: ACTUAL_AUTH.shop || '' },
    fetchPolicy: 'cache-and-network',
  });

  // -- -- --
  // -- MUTATIONS --
  // -- -- --

  const [enableStripe, { error: enableStripeError, loading: enableStripeLoading }] = useMutation<
    ShopStripeEnableData,
    ShopStripeEnableVariables
  >(SHOP_STRIPE_ENABLE);

  const [disableStripe, { error: disableStripeError, loading: disableStripeLoading }] = useMutation<
    ShopStripeDisableData,
    ShopStripeDisableVariables
  >(SHOP_STRIPE_DISABLE);

  const [updateShop, { error: updateShopError }] = useMutation<updateShopData, updateShopVariables>(
    UPDATE_SHOP
  );

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const getUpdateDeliveryInfo = (
    type: 'stripe' | 'onSite',
    enable: boolean
  ): Shop['deliveryInfo'] | undefined => {
    log.info('CALL updateOnSiteDeliveryInfo');

    if (!data?.AdminShop) {
      log.error('  - updateOnSiteDeliveryInfo() can NOT get data.AdminShop');
      return;
    }

    let newDeliveryInfo = {
      ...data.AdminShop.deliveryInfo,
    };

    // abilito pagamento in negozio...
    if (type === 'onSite' && enable) {
      // .. abilito pagamento in negozio in ritiro
      newDeliveryInfo.atShop = {
        ...newDeliveryInfo.atShop,
        allowedPaymentTypes: {
          onSite: true,
          stripe: newDeliveryInfo.atShop.allowedPaymentTypes?.stripe || false,
        },
      };
    }
    // disabilito pagamento in negozio
    if (type === 'onSite' && !enable) {
      // .. disabilito pagamento in negozio sia in ritiro che in spedizione
      newDeliveryInfo.atShop = {
        ...newDeliveryInfo.atShop,
        allowedPaymentTypes: {
          onSite: false,
          stripe: newDeliveryInfo.atShop.allowedPaymentTypes?.stripe || false,
        },
      };
      newDeliveryInfo.atHome = {
        ...newDeliveryInfo.atHome,
        allowedPaymentTypes: {
          onSite: false,
          stripe: newDeliveryInfo.atHome.allowedPaymentTypes?.stripe || false,
        },
      };
    }
    // abilito pagamento stripe...
    if (type === 'stripe' && enable) {
      // .. abilito pagamento stripe in spedizione
      newDeliveryInfo.atHome = {
        ...newDeliveryInfo.atHome,
        allowedPaymentTypes: {
          onSite: newDeliveryInfo.atHome.allowedPaymentTypes?.onSite || false,
          stripe: true,
        },
      };
    }
    // disabilito pagamento in stripe
    if (type === 'stripe' && !enable) {
      // .. disabilito pagamento in negozio sia in ritiro che in spedizione
      newDeliveryInfo.atShop = {
        ...newDeliveryInfo.atShop,
        allowedPaymentTypes: {
          onSite: newDeliveryInfo.atShop.allowedPaymentTypes?.onSite || false,
          stripe: false,
        },
      };
      newDeliveryInfo.atHome = {
        ...newDeliveryInfo.atHome,
        allowedPaymentTypes: {
          onSite: newDeliveryInfo.atHome.allowedPaymentTypes?.onSite || false,
          stripe: false,
        },
      };
    }

    let cleanNewDeliveryInfo = transformApolloData(newDeliveryInfo);

    log.info(`  - update Shop.deliveryInfo:`);
    log.info(`    - atHome to ${newDeliveryInfo.atHome}`);
    log.info(`    - atShop to ${newDeliveryInfo.atShop}`);

    return cleanNewDeliveryInfo;
  };

  const updateOnSitePayment = (enable: boolean) => {
    log.info('CALL updatePaymentMethods');

    if (!data?.AdminShop) {
      log.error('  - updateOnSitePayment() can NOT get data.AdminShop');
      return;
    }

    let newPaymentMethods = {
      ...data.AdminShop.paymentMethods,
    };

    newPaymentMethods.onSite = {
      isEnabled: enable,
    };

    let cleanNewPaymentMethods = transformApolloData(newPaymentMethods);
    // update deliveryInfo
    let newDeliveryInfo = getUpdateDeliveryInfo('onSite', enable);

    updateShop({
      variables: {
        _id: ACTUAL_AUTH.shop || '',
        shop: {
          paymentMethods: cleanNewPaymentMethods,
          deliveryInfo: newDeliveryInfo,
        },
      },
    });

    log.info(`  - update Shop.paymentMethods.onSite to ${enable}`);
  };

  const askStripeDisabilitation = async () => {
    log.debug('CALL stripeDisabilitation()');

    const { data } = await disableStripe({
      variables: {
        _id: ACTUAL_AUTH.shop || '',
      },
    });

    let newDeliveryInfo = getUpdateDeliveryInfo('stripe', false);

    updateShop({
      variables: {
        _id: ACTUAL_AUTH.shop || '',
        shop: {
          deliveryInfo: newDeliveryInfo,
        },
      },
    });
  };

  const askStripeAbilitation = async () => {
    log.debug('CALL askStripeAbilitation()');

    try {
      const { data } = await enableStripe({
        variables: {
          _id: ACTUAL_AUTH.shop || '',
        },
      });

      if (!data || !data.AdminShopStripeEnable.success) {
        // request fail
        log.warn('askStripeAbilitation - Fail!');
        log.warn(`stripeResult: ${JSON.stringify(data?.AdminShopStripeEnable)}`);
        setGenericStripeError(
          'ops! Qualcosa è andato storto.\nRiprova la procedura o chiama il supporto tecnico per risolvere il problema'
        );
        return;
      }

      let newDeliveryInfo = getUpdateDeliveryInfo('stripe', true);

      updateShop({
        variables: {
          _id: ACTUAL_AUTH.shop || '',
          shop: {
            deliveryInfo: newDeliveryInfo,
          },
        },
      });

      // redirect to abilitation link if needed
      if (data.AdminShopStripeEnable.link) {
        log.debug('  - redirect to stripe registration');
        openExternalLink(data.AdminShopStripeEnable.link);
      }
    } catch (err) {
      log.error(err);
    }
  };

  const togglePaymentType = (
    method: 'stripe' | 'onSite',
    delivery: 'atHome' | 'atShop',
    value: boolean
  ) => {
    log.debug('CALL togglePaymentType()');

    if (!data?.AdminShop) {
      log.error('  - togglePaymentType() can NOT get data.AdminShop');
      return;
    }

    let newDeliveryInfo: Shop['deliveryInfo'] = {
      ...data.AdminShop.deliveryInfo,
    };

    if (method === 'stripe' && delivery === 'atHome') {
      newDeliveryInfo = {
        ...data.AdminShop.deliveryInfo,
        atHome: {
          ...data.AdminShop.deliveryInfo.atHome,
          allowedPaymentTypes: {
            ...data.AdminShop.deliveryInfo.atHome.allowedPaymentTypes,
            stripe: value,
          },
        },
      };
    }
    if (method === 'onSite' && delivery === 'atHome') {
      newDeliveryInfo = {
        ...data.AdminShop.deliveryInfo,
        atHome: {
          ...data.AdminShop.deliveryInfo.atHome,
          allowedPaymentTypes: {
            ...data.AdminShop.deliveryInfo.atHome.allowedPaymentTypes,
            onSite: value,
          },
        },
      };
    }
    if (method === 'stripe' && delivery === 'atShop') {
      newDeliveryInfo = {
        ...data.AdminShop.deliveryInfo,
        atShop: {
          ...data.AdminShop.deliveryInfo.atShop,
          allowedPaymentTypes: {
            ...data.AdminShop.deliveryInfo.atShop.allowedPaymentTypes,
            stripe: value,
          },
        },
      };
    }
    if (method === 'onSite' && delivery === 'atShop') {
      newDeliveryInfo = {
        ...data.AdminShop.deliveryInfo,
        atShop: {
          ...data.AdminShop.deliveryInfo.atShop,
          allowedPaymentTypes: {
            ...data.AdminShop.deliveryInfo.atShop.allowedPaymentTypes,
            onSite: value,
          },
        },
      };
    }

    let cleanNewDeliveryInfo = transformApolloData(newDeliveryInfo);

    updateShop({
      variables: {
        _id: ACTUAL_AUTH.shop || '',
        shop: {
          deliveryInfo: cleanNewDeliveryInfo,
        },
      },
    });

    log.info(`  - Set Shop.deliveryInfo [${delivery}] [${method}] to: ${value}`);
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  const renderPaymentOnSiteOption = () => {
    if (!data?.AdminShop.paymentMethods.onSite.isEnabled) return null;

    let atShopEnabled = data.AdminShop.deliveryInfo.atShop.allowedPaymentTypes?.onSite || false;
    let atHomeEnabled = data.AdminShop.deliveryInfo.atHome.allowedPaymentTypes?.onSite || false;

    return (
      <div className="mt-4">
        <hr className="border-b-1 border-gray-200" />
        <div className="flex flex-row items-center justify-between mt-2">
          <h6 className="uppercase text-gray-500 text-xs font-bold">{'Per ritiro in negozio'}</h6>
          <MySwitch
            defaultValue={atShopEnabled}
            onChange={value => {
              togglePaymentType('onSite', 'atShop', value);
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-between mt-2">
          <h6 className="uppercase text-gray-500 text-xs font-bold">{'Per spedizione'}</h6>
          <MySwitch
            defaultValue={atHomeEnabled}
            onChange={value => {
              togglePaymentType('onSite', 'atHome', value);
            }}
          />
        </div>
      </div>
    );
  };

  const renderPaymentStripeOption = () => {
    if (!data?.AdminShop.paymentMethods.stripe.isEnabled) return null;

    let atShopEnabled = data.AdminShop.deliveryInfo.atShop.allowedPaymentTypes?.stripe || false;
    let atHomeEnabled = data.AdminShop.deliveryInfo.atHome.allowedPaymentTypes?.stripe || false;

    return (
      <div className="mt-4">
        <hr className="border-b-1 border-gray-200" />
        <div className="flex flex-row items-center justify-between mt-2">
          <h6 className="uppercase text-gray-500 text-xs font-bold">{'Per ritiro in negozio'}</h6>
          <MySwitch
            defaultValue={atShopEnabled}
            onChange={value => {
              togglePaymentType('stripe', 'atShop', value);
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-between mt-2">
          <h6 className="uppercase text-gray-500 text-xs font-bold">{'Per spedizione'}</h6>
          <MySwitch
            defaultValue={atHomeEnabled}
            onChange={value => {
              togglePaymentType('stripe', 'atHome', value);
            }}
          />
        </div>
      </div>
    );
  };

  if (error) {
    log.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  if (enableStripeError) {
    log.error(enableStripeError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${enableStripeError}`}
        </div>
      </div>
    );
  }

  if (updateShopError) {
    log.error(updateShopError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${updateShopError}`}
        </div>
      </div>
    );
  }

  if (disableStripeError) {
    log.error(disableStripeError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${disableStripeError}`}
        </div>
      </div>
    );
  }

  if (genericStripeError) {
    log.error(genericStripeError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {genericStripeError}
        </div>
      </div>
    );
  }

  if (!data?.AdminShop || enableStripeLoading || disableStripeLoading) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row  w-full flex-wrap">
      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
          Pagamento In Negozio
        </h6>
        <div className="w-full px-4 py-2">
          <div className="relative w-full my-2 flex flex-row items-center justify-between">
            <h6 className="uppercase text-gray-500 text-xs font-bold">
              {data.AdminShop.paymentMethods.onSite.isEnabled ? 'Abilitato' : 'Disabilitato'}
            </h6>
            <OnSiteModal
              isOnSiteEnabled={data.AdminShop.paymentMethods.onSite.isEnabled}
              onEnable={() => {
                log.info('CALL OnSiteModal onEnable');
                updateOnSitePayment(true);
              }}
              onDisable={() => {
                log.info('CALL OnSiteModal onDisable');
                updateOnSitePayment(false);
              }}
            />
          </div>
          {renderPaymentOnSiteOption()}
        </div>
      </div>
      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
          Pagamento via Stripe
        </h6>
        <div className="w-full px-4 py-2">
          <div className="relative w-full flex flex-row items-center justify-between my-2">
            <h6 className="uppercase text-gray-500 text-xs font-bold">
              {`${data.AdminShop.paymentMethods.stripe.isEnabled ? 'Abilitato' : 'Disabilitato'}`}
            </h6>
            <StripeModal
              isStripeEnabled={data.AdminShop.paymentMethods.stripe.isEnabled}
              onEnable={() => {
                log.info('CALL StripeModal onEnable');
                askStripeAbilitation();
              }}
              onDisable={() => {
                log.info('CALL StripeModal onDisable');
                askStripeDisabilitation();
              }}
            />
          </div>
          {renderPaymentStripeOption()}
        </div>
      </div>
      <StripeSuccessModal
        isOpen={params.view === 'stripesuccess'}
        onClose={() => {
          //   let newDeliveryInfo = getUpdateDeliveryInfo('stripe', true);
          //   updateShop({
          //     variables: {
          //       _id: ACTUAL_AUTH.shop || '',
          //       shop: {
          //         deliveryInfo: newDeliveryInfo,
          //       },
          //     },
          //   });
        }}
      />
      <StripeFailModal
        isOpen={params.view === 'stripefail'}
        onClose={() => {
          let newDeliveryInfo = getUpdateDeliveryInfo('stripe', false);

          updateShop({
            variables: {
              _id: ACTUAL_AUTH.shop || '',
              shop: {
                deliveryInfo: newDeliveryInfo,
              },
            },
          });
        }}
      />
    </div>
  );
};

export { PaymentView };
