import React, { useState, useEffect } from 'react';
import { DndProvider, DragSource } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  getFlatDataFromTree,
  getTreeFromFlatData,
  removeNode,
  SortableTreeWithoutDndContext as SortableTree,
} from 'react-sortable-tree';

import { Colors, LOG } from '../../../config';

import { useMutation, useQuery } from '@apollo/client';
import {
  addFilesData,
  addFilesVariables,
  ADD_FILE,
  Product,
  PRODUCTS_FOR_TREE,
} from '../../../stores/queries/product';
import {
  ProductTreeInput,
  ProductTreeLvl,
  Shop,
  SHOP,
  EDIT_PRODUCT_TREE,
  EditProductTreeData,
  EditProductTreeVariables,
  EPNode,
  DisplayType,
  FileInput,
} from '../../../stores/queries/shop';

import { ACTUAL_AUTH } from '../../../stores/db/auth';

import {
  ProductElement,
  GroupElement,
  TreeItemTitle,
  EditFolderModal,
  RemoveElementModal,
  AddFolderModal,
} from '../../../components';
import { Loading, TextBox } from '../../../ui';
import { useFormFields } from '../../../hooks/forms';
import { resizeImage } from '../../../libs/utils';

const log = LOG.extend('TREE_VIEW');

//  ---- N.B! --------------------------------
//  ------------------------------------------
// Bug on externalNodeDrop outsideTree
// FIX:
// node_modules/react-sortable-tree/dist/index.esm.js
// 2742 - this.endDrag();
// 2743 + setTimeout(() => {this.endDrag()});
//  ------------------------------------------
//  ------------------------------------------

const externalNodeType = 'yourNodeType';
const externalNodeSpec = {
  // This needs to return an object with a property `node` in it.
  // Object rest spread is recommended to avoid side effects of
  // referencing the same object in different trees.
  beginDrag: (componentProps: any) => ({ node: { ...componentProps.node } }),
};
const externalNodeCollect = (connect: any, monitor: any) => ({
  connectDragSource: connect.dragSource(),
  // connectDroptarget: connect.dropTarget(),
  // Add props via react-dnd APIs to enable more visual
  // customization of your component
  // isDragging: monitor.isDragging(),
  // didDrop: monitor.didDrop(),
});

const DragableProduct = DragSource(
  externalNodeType,
  externalNodeSpec,
  externalNodeCollect
)(ProductElement);

const DragableGroup = DragSource(
  externalNodeType,
  externalNodeSpec,
  externalNodeCollect
)(GroupElement);

const TreeView = () => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const [treeItems, setTreeItems] = useState<EPNode[]>([]);
  const [inputs, handleInputChange] = useFormFields<{
    productsFilter: string;
  }>({
    productsFilter: '',
  });
  // workAround per constringere EditFolderModal al Re-render
  const [renderNum, setRenderNum] = useState(0);

  // -- -- --
  // -- QUERIES --
  // -- -- --
  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery<{ AdminProducts: Product[] }, { shopId: string }>(PRODUCTS_FOR_TREE, {
    variables: {
      shopId: ACTUAL_AUTH.shop || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: shopLoading,
    error: shopError,
    data: shopData,
  } = useQuery<{ AdminShop: Shop }, { _id: string }>(SHOP, {
    variables: {
      _id: ACTUAL_AUTH.shop || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  // -- -- --
  // -- MUTATIONS --
  // -- -- --

  const [
    updateTree,
    { error: updateTreeError, loading: updateTreeLoading, data: newProductTreeData },
  ] = useMutation<EditProductTreeData, EditProductTreeVariables>(EDIT_PRODUCT_TREE);

  const [addFile, { error: addFileError, loading: addFileLoading }] = useMutation<
    addFilesData,
    addFilesVariables
  >(ADD_FILE);

  // -- -- --
  // -- EFFECTS --
  // -- -- --
  useEffect(() => {
    if (shopData?.AdminShop) {
      const treeItems = getTreeFromDBData(shopData.AdminShop.productTree);
      setTreeItems(treeItems);
      setRenderNum(num => num + 1);
    }
  }, [shopData]);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --
  const updateProductTree = (
    tree: EditProductTreeVariables['productTree'],
    files?: EditProductTreeVariables['files']
  ) => {
    log.info('CALL updateProductTree');
    log.info(`  - fileActions: ${JSON.stringify(files)}`);
    updateTree({
      variables: {
        _id: ACTUAL_AUTH.shop || '',
        productTree: tree,
        files: files,
      },
    });
  };

  const getNodeFromDBElement = (productTreeLvl: ProductTreeLvl) => {
    // let node: TreeItem = {
    let node: EPNode = {
      title: 'Errore Cartella|Prodotto|Gruppo',
    };

    switch (productTreeLvl.type) {
      case 'folder':
        node = {
          title: (
            <TreeItemTitle
              type="folder"
              isSponsored={productTreeLvl.isSponsored || false}
              isVisible={productTreeLvl?.isVisible ?? true}
              name={productTreeLvl.name || ''}
              color={productTreeLvl.style?.color}
            />
          ),
          type: 'folder',
          isSponsored: productTreeLvl.isSponsored || false,
          isVisible: productTreeLvl?.isVisible ?? true,
          display: productTreeLvl.style?.display,
          name: productTreeLvl.name || '',
          description: productTreeLvl.description || '',
          picture: {
            url: productTreeLvl.picture?.url || null,
            _id: productTreeLvl.picture?._id || null,
          },
          color: productTreeLvl.style?.color,
        };
        break;

      case 'product':
        // restituisci null un caso di errore
        if (!productTreeLvl.product) {
          log.warn(`getNodeFromDBElement - productTreeLvl.type: product but NO product found`);
          return null;
        }
        node = {
          title: (
            <TreeItemTitle
              type="product"
              name={productTreeLvl.product?.name || ''}
              isSponsored={productTreeLvl.isSponsored || false}
              color={productTreeLvl.style?.color}
            />
          ),
          type: 'product',
          isSponsored: productTreeLvl.isSponsored || false,
          name: productTreeLvl.product?.name || '',
          description: productTreeLvl.product?.descriptions?.short || '',
          picture: undefined,
          color: productTreeLvl.style?.color,
          product: productTreeLvl.product,
        };
        break;

      default:
        log.error(`Node ${productTreeLvl?.product?.name || productTreeLvl?.name} NOT has type`);
        break;
    }
    return node;
  };

  const getTreeFromDBData = (productTree: ProductTreeLvl[]) => {
    log.info('CALL getTreeFromDBData');
    let tree: EPNode[] = [];
    let childrenLvl1: EPNode[] = [];
    let childrenLvl2: EPNode[] = [];

    // -- Lvl 0 --
    for (let i = 0; i < productTree.length; i++) {
      let node = getNodeFromDBElement(productTree[i]);
      if (!node) continue;

      // -- Lvl 1 --
      childrenLvl1 = [];
      for (let j = 0; j < productTree[i].next.length; j++) {
        let nodeLvl1 = getNodeFromDBElement(productTree[i].next[j]);
        if (!nodeLvl1) continue;

        // -- Lvl 2 --
        childrenLvl2 = [];
        for (let k = 0; k < productTree[i].next[j].next.length; k++) {
          let nodeLvl2 = getNodeFromDBElement(productTree[i].next[j].next[k]);
          if (!nodeLvl2) continue;
          childrenLvl2.push(nodeLvl2);
        }

        childrenLvl1.push({
          ...nodeLvl1,
          children: childrenLvl2.length ? childrenLvl2 : undefined,
          expanded: childrenLvl2.length ? true : undefined,
        });
      }

      tree.push({
        ...node,
        children: childrenLvl1.length ? childrenLvl1 : undefined,
        expanded: childrenLvl1.length ? true : undefined,
      });
    }

    return tree;
  };

  const getElementFromTreeNode = (node: EPNode) => {
    let element: ProductTreeInput = {
      type: 'folder',
    };

    log.debug(`get element from node: ${node.name}`);

    switch (node.type) {
      case 'product':
        if (!node.product?._id) {
          log.error('getElementFromTreeNode - node.type: product but NO node.product found');
          return null;
        }
        element.type = 'product';
        element.product = node.product._id;
        element.isSponsored = node.isSponsored || false;
        if (node.color) {
          element.style = {
            color: node.color,
          };
        }
        return element;

      case 'folder':
        element.type = 'folder';
        element.name = node.name;
        element.isSponsored = node.isSponsored || false;
        element.isVisible = node?.isVisible ?? true;
        element.description = node.description;
        if (node.picture) {
          element.picture = {
            url: node.picture.url,
            _id: node.picture._id,
          };
        }
        if (node.color || node.display) {
          element.style = {
            color: node.color || null,
            display: node.display,
          };
        }
        return element;

      default:
        log.error('getElementFromTreeNode - somwthing went wrong with node:');
        log.error(`${node}`);
        return null;
    }
  };

  const onTreeItemsChange = (treeItems: EPNode[], files?: FileInput[]) => {
    log.debug('CALL onTreeItemsChange');

    let productTree: ProductTreeInput[] = [];
    let childrenLvl1: ProductTreeInput[] = [];
    let childrenLvl2: ProductTreeInput[] = [];

    // -- Lvl 0 --
    for (let i = 0; i < treeItems.length; i++) {
      let lvl0 = getElementFromTreeNode(treeItems[i]);
      if (lvl0) productTree.push(lvl0);

      // -- Lvl 1 --
      let treeItemsLvl1 = treeItems[i].children as EPNode[];
      if (treeItemsLvl1 && treeItemsLvl1.length) {
        childrenLvl1 = [];
        for (let j = 0; j < treeItemsLvl1.length; j++) {
          let lvl1 = getElementFromTreeNode(treeItemsLvl1[j]);
          if (lvl1) childrenLvl1.push(lvl1);

          // -- Lvl 2 --
          let treeItemsLvl2 = treeItemsLvl1[j].children as EPNode[];
          if (treeItemsLvl2 && treeItemsLvl2.length) {
            childrenLvl2 = [];
            for (let k = 0; k < treeItemsLvl2.length; k++) {
              let lvl2 = getElementFromTreeNode(treeItemsLvl2[k]);
              if (lvl2) childrenLvl2.push(lvl2);
            }

            childrenLvl1[j].next = childrenLvl2;
          }
        }

        productTree[i].next = childrenLvl1;
      }
    }

    // Send new productTree to DB
    updateProductTree(productTree, files);

    // Set new treeItems State
    setTreeItems(treeItems);
    // update renderNum to force re-render of EditFolderModal
    setRenderNum(num => num + 1);
  };

  const onItemRemove = (treeIndex: number) => {
    log.debug(`CALL - onItemRemove() - item ${treeIndex}`);

    let flatData = getFlatDataFromTree({
      treeData: treeItems,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true,
    });

    let newData = removeNode({
      treeData: treeItems,
      path: flatData[treeIndex].path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });

    if (!newData?.treeData) {
      log.error('onItemRemove - something went wrong evaluating new treeData');
      return;
    }

    // remove also file if needed
    let fileInput: EditProductTreeVariables['files'] = [];
    if (flatData[treeIndex]?.node.picture?._id) {
      fileInput.push({
        fileId: flatData[treeIndex].node.picture._id,
        action: 'remove',
      });
      log.debug(` - remove also picture: ${flatData[treeIndex].node.picture._id}`);
    }

    log.debug(` - item removed succesfull -> set new treeData`);
    onTreeItemsChange(newData.treeData, fileInput.length ? fileInput : undefined);
  };

  const onFolderChange = async (
    treeIndex: number,
    folderData: {
      name?: string;
      description?: string;
      isSponsored?: boolean;
      isVisible?: boolean;
      display?: DisplayType;
      color?: Colors;
      pictureFile?: File | 'empty';
    }
  ) => {
    log.debug('CALL - onFolderChange()');
    log.info('edit item: ', treeIndex);

    let flatData = getFlatDataFromTree({
      treeData: treeItems,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: true,
    });

    if (folderData.name) {
      log.info(`  - name: ${flatData[treeIndex].node.name} -> ${folderData.name}`);
      flatData[treeIndex].node.name = folderData.name;
    }

    if (folderData.description) {
      log.info(
        `  - description: ${flatData[treeIndex].node.description} -> ${folderData.description}`
      );
      flatData[treeIndex].node.description = folderData.description;
    }

    if (folderData.color && flatData[treeIndex].node?.color !== folderData.color) {
      log.info(`  - color: ${flatData[treeIndex].node.color} -> ${folderData.color}`);
      flatData[treeIndex].node.color = folderData.color;
    }
    if (folderData.display) {
      log.info(`  - display: ${flatData[treeIndex].node.display} -> ${folderData.display}`);
      flatData[treeIndex].node.display = folderData.display;
    }

    if (
      folderData.isSponsored !== undefined &&
      flatData[treeIndex].node?.isSponsored !== folderData?.isSponsored
    ) {
      log.info(
        `  - isSponsored: ${flatData[treeIndex].node.isSponsored} -> ${folderData.isSponsored}`
      );
      flatData[treeIndex].node.isSponsored = folderData.isSponsored;
    }

    if (
      folderData.isVisible !== undefined &&
      flatData[treeIndex].node?.isVisible !== folderData?.isVisible
    ) {
      log.info(`  - isVisible: ${flatData[treeIndex].node.isVisible} -> ${folderData.isVisible}`);
      flatData[treeIndex].node.isVisible = folderData.isVisible;
    }

    // picture handerl
    let fileInput: EditProductTreeVariables['files'] = [];

    if (folderData.pictureFile) {
      // remove old picture if needed
      if (flatData?.[treeIndex].node?.picture?._id) {
        fileInput.push({
          fileId: flatData[treeIndex].node.picture._id,
          action: 'remove',
        });
      }

      // insert new picture if needed
      let newPicture: EPNode['picture'] = {
        _id: null,
        url: null,
      };
      if (folderData.pictureFile !== 'empty') {
        try {
          // upload file
          let resizedImg = await resizeImage(folderData.pictureFile);
          let addFileResult = await addFile({
            variables: {
              files: [resizedImg],
              scope: 'treeImages',
            },
          });
          if (!addFileResult.data || !addFileResult.data.AdminAddFiles[0]) {
            log.error(`  - addFile Error: ${addFileResult.errors}`);
            return;
          }
          const { _id, url, filename } = addFileResult.data?.AdminAddFiles[0];
          log.info(`  - addFile upload success - ${_id} - ${filename}`);
          // aggiungo nuova immagine
          newPicture = {
            _id: _id,
            url: url,
          };
          fileInput.push({
            fileId: _id,
            action: 'insert',
          });
          log.debug(`  - add NEW folderCover file: ${_id}`);
        } catch (error) {
          log.error(`  - updatePicture File something went wrong`);
          log.error(`${error}`);
        }
      }

      log.info(`  - pictureId: ${flatData[treeIndex].node.picture?._id} -> ${newPicture._id}`);
      flatData[treeIndex].node.picture = newPicture;
    }

    let newData = getTreeFromFlatData({
      flatData: flatData,
      getKey: (item: any) => item.treeIndex + 1,
      getParentKey: (item: any) => {
        let parent = item.path[item.path.length - 2];
        if (parent === undefined) {
          parent = 0;
        } else {
          parent++;
        }
        return parent;
      },
      rootKey: 0,
    });

    // get newTree from newData
    let newTree: EPNode[] = [];
    for (let j = 0; j < newData.length; j++) {
      newTree.push(newData[j].node);
    }

    log.debug(` - item change succesfull -> set new treeData`);
    onTreeItemsChange(newTree, fileInput.length ? fileInput : undefined);
  };

  const onFolderAdd = async (folderData: {
    name?: string;
    description?: string;
    color?: Colors;
    display?: DisplayType;
    pictureFile?: File;
  }) => {
    log.debug('CALL - onFolderAdd()');
    log.info(`add folder ${folderData.name}`);

    let newElement: ProductTreeLvl = {
      name: folderData.name || '',
      type: 'folder',
      isSponsored: false,
      isVisible: true,
      description: folderData.description || '',
      picture: {
        url: null,
        _id: null,
      },
      style: {
        color: folderData.color ? folderData.color : null,
        display: folderData.display,
      },
      next: [],
      product: null,
    };

    let fileInput: EditProductTreeVariables['files'] = [];

    // se c'è file da aggiungere ...
    if (folderData.pictureFile) {
      try {
        // upload file
        let resizedImg = await resizeImage(folderData.pictureFile);
        let addFileResult = await addFile({
          variables: {
            files: [resizedImg],
            scope: 'treeImages',
          },
        });
        if (!addFileResult.data || !addFileResult.data.AdminAddFiles[0]) {
          log.error(`  - addFile Error: ${addFileResult.errors}`);
          return;
        }
        const { _id, url, filename } = addFileResult.data?.AdminAddFiles[0];
        log.info(`  - addFile upload success - ${_id} - ${filename}`);
        // aggiungo nuova immagine
        let newPicture = {
          _id: _id,
          url: url,
        };
        fileInput.push({
          fileId: _id,
          action: 'insert',
        });
        log.debug(`  - add NEW folderCover file: ${_id}`);
        newElement.picture = newPicture;
      } catch (error) {
        log.error(`  - updatePicture File something went wrong`);
        log.error(`${error}`);
      }
    }

    let newNode = getNodeFromDBElement(newElement);
    if (newNode) {
      treeItems.push(newNode);
      onTreeItemsChange(treeItems, fileInput.length ? fileInput : undefined);
    }
  };

  // -- -- --
  // -- RENDER --
  // -- -- --
  if (productError) LOG.error(`${productError}`);

  if (shopError) {
    log.error(shopError);
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${shopError}`}
        </div>
      </div>
    );
  }
  if (updateTreeError) {
    log.error(updateTreeError);
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${updateTreeError}`}
        </div>
      </div>
    );
  }
  if (addFileError) {
    log.error(addFileError);
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${addFileError}`}
        </div>
      </div>
    );
  }

  if (!shopData) {
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <Loading color={'#666'} />
      </div>
    );
  }

  const renderProducts = (products: Product[]) => {
    if (!products || !products.length) return null;

    let filteredProducts = products.filter(item =>
      item.name.toLowerCase().includes(inputs.productsFilter.toLowerCase())
    );

    let productElements = [];

    for (let i = 0; i < filteredProducts.length; i++) {
      productElements.push(
        <DragableProduct
          key={filteredProducts[i]._id}
          node={{
            title: <TreeItemTitle type="product" name={filteredProducts[i].name} />,
            type: 'product',
            product: filteredProducts[i],
            name: filteredProducts[i].name,
            description: filteredProducts[i].descriptions.short,
          }}
        />
      );
    }

    return (
      <div
        style={{
          height: 'calc(100vh - 200px)',
          position: 'relative',
        }}
      >
        <div className="mb-2" style={{ position: 'fixed', zIndex: 10, width: '300px' }}>
          <TextBox
            id="productsFilter"
            type="text"
            placeholder={'Cerca per nome...'}
            required={true}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ paddingTop: '60px', position: 'relative' }}>{productElements}</div>
      </div>
    );
  };

  return (
    // @ts-ignore
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-wrap h-full">
        <div className="w-full lg:w-6/12 p-2">
          <div className="rounded-t-2xl w-full px-6 py-4 flex flex-row gap-4 items-center">
            <h6 className="text-gray-500 text-xl font-bold">Percorso Utente</h6>
            <AddFolderModal onFolderAdd={onFolderAdd} />
          </div>
          {/* @ts-ignore */}
          <SortableTree
            treeData={treeItems}
            innerStyle={{
              paddingBottom: '120px',
            }}
            style={{
              height: 'calc(100vh - 200px)',
              overflow: 'visible',
            }}
            getNodeKey={({ treeIndex }) => treeIndex}
            generateNodeProps={({ node, treeIndex }) => ({
              buttons: [
                <EditFolderModal
                  key={renderNum}
                  node={node}
                  treeIndex={treeIndex}
                  onFolderChange={onFolderChange}
                />,
                <RemoveElementModal
                  node={node}
                  treeIndex={treeIndex}
                  onItemRemove={onItemRemove}
                />,
              ],
            })}
            maxDepth={3}
            onChange={onTreeItemsChange}
            dndType={externalNodeType}
            canDrop={({ nextParent }) => {
              if (nextParent?.type === 'product' || nextParent?.type === 'productGroup') {
                return false;
              }
              return true;
            }}
          />
        </div>
        <div className="w-full lg:w-6/12 p-2">
          <div className="rounded-t-2xl w-full px-6 py-4 flex">
            <h6 className="text-gray-500 text-xl font-bold">Prodotti</h6>
          </div>

          <div
            className={'min-w-0 break-words w-full'}
            style={{
              height: 'calc(100vh - 200px)',
              overflow: 'auto',
              borderLeft: '1px solid #ddd',
              padding: 10,
            }}
          >
            {renderProducts(productData?.AdminProducts || [])}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export { TreeView };
