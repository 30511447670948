import React, { ReactNode } from 'react';
import DataTable from 'react-data-table-component';

import { Loading } from './Loading';

interface TableProps {
  columns: any;
  data: any[];
  height: string;
  loading: boolean;
  onRowClick?: (row: any, e: any) => void;
  addElementComponent?: ReactNode;
  selectableRows?: boolean;
  defaultSortFieldId?: string;
  defaultSortInvert?: boolean;
  centerHeadCells?: boolean;
}

const TableInCard = (props: TableProps) => {
  const styles = {
    container: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
    table: {
      subHeader: {
        style: {
          borderRadius: 30,
        },
      },
      headRow: {
        style: {
          textTransform: 'uppercase',
          backgroundColor: '#e9e9e9',
          borderBottomWidth: '0px',
          borderBottomColor: '#eee',
          borderBottomStyle: 'solid',
        },
      },
      headCells: {
        style: {
          fontSize: '12px',
          fontWeight: 600,
          textAlign: props.centerHeadCells ? 'center' : 'left',
          color: '#8f8f8f',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
      rows: {
        style: {
          backgroundColor: '#f7f7f7',
          '&:not(:last-of-type)': {
            borderBottomStyle: 'solid',
            borderBottomWidth: '0px',
            borderBottomColor: '#eee',
          },
        },
        stripedStyle: {
          backgroundColor: '#f2f2f2',
        },
      },
      cells: {
        style: {
          color: '#666',
        },
      },
      table: {
        style: {
          overflow: 'hidden',
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          borderBottomRightRadius: '1rem',
        },
        backgroud: {},
      },
      tableWrapper: {
        style: {
          display: 'table',
        },
      },
    },
  };

  return (
    <DataTable
      noHeader
      striped={true}
      fixedHeaderScrollHeight={props.height}
      responsive={true}
      columns={props.columns}
      data={props.data || []}
      style={styles.container}
      customStyles={styles.table}
      progressPending={props.loading}
      selectableRows={props.selectableRows}
      defaultSortFieldId={props.defaultSortFieldId}
      defaultSortAsc={props.defaultSortInvert ? false : true}
      noDataComponent={
        <div className="w-full flex items-center justify-center py-8 font-bold text-gray-400">
          Nessun elemento da visualizzare
        </div>
      }
      progressComponent={
        <div className="w-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      }
      onRowClicked={props.onRowClick}
    />
  );
};

export { TableInCard };
