import React from 'react';
import { Shop } from '../../../stores/queries/shop';
import { numberToEuro } from '../../../libs/utils';

interface StateHomeProps {
  shop: Shop;
}
const StateHome = ({ shop }: StateHomeProps) => {
  const endDate = shop.servicePayments.endDate
    ? `${new Date(shop.servicePayments.endDate).toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })}`
    : '-';

  const getServiceState = () => {
    let state = {
      message: 'Sconosciuto',
      txtColor: 'text-yellow-500',
    };

    if (
      !shop.servicePayments.endDate ||
      new Date(shop.servicePayments.endDate) <= new Date(Date.now())
    ) {
      state.message = "Necessario rinnovare l'Abbonamento";
      return state;
    }
    if (!shop.servicePayments.credits || shop.servicePayments.credits < 1) {
      state.message = 'Necessario acquistare altri Crediti';
      return state;
    }

    state.message = 'Regolare';
    state.txtColor = 'text-green-500';
    return state;
  };

  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Stato</h6>
        <h3 className={`text-xl mb-2 font-bold ${getServiceState().txtColor}`}>
          {getServiceState().message}
        </h3>
      </div>

      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Credito Residuo</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{`${numberToEuro(
          shop.servicePayments.credits
        )}`}</h3>
      </div>

      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Scadenza Servizio</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{endDate}</h3>
      </div>
    </div>
  );
};

export { StateHome };
