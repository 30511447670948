import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ACTUAL_AUTH } from '../../../stores/db/auth';
import { Shop, SHOP } from '../../../stores/queries/shop';
import { Product, PRODUCTS } from '../../../stores/queries/product';
import { JOBS, JobsData, JobsVariable } from '../../../stores/queries/orders';

import { LOG } from '../../../config';
import { Button, Loading } from '../../../ui';

import { SalesHome } from './SalesHome';
import { StateHome } from './StateHome';
import { ProductsHome } from './ProductsHome';
import { ShopHome } from './ShopHome';
import { OrdersHome } from './OrdersHome';

const log = LOG.extend('HOME');

interface DashViewProps {
  route?: any;
}

const DashView = (props: DashViewProps) => {
  const navigate = useNavigate();

  // -- -- --
  // -- QUERY --
  // -- -- --
  const { error: shopError, data: shopData } = useQuery<{ AdminShop: Shop }, { _id: string }>(
    SHOP,
    {
      variables: { _id: ACTUAL_AUTH.shop || '' },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { error: productError, data: productData } = useQuery<
    { AdminProducts: Product[] },
    { shopId: string }
  >(PRODUCTS, {
    variables: {
      shopId: ACTUAL_AUTH.shop || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const { error: jobsError, data: jobsData } = useQuery<JobsData, JobsVariable>(JOBS, {
    variables: {
      shopId: ACTUAL_AUTH.shop || '',
      limit: 50,
    },
    fetchPolicy: 'cache-and-network',
  });

  // -- -- --
  // -- RENDER --
  // -- -- --

  if (shopError) {
    log.error(shopError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${shopError}`}
        </div>
      </div>
    );
  }
  if (productError) {
    log.error(productError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${productError}`}
        </div>
      </div>
    );
  }
  if (jobsError) {
    log.error(jobsError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${jobsError}`}
        </div>
      </div>
    );
  }

  if (!shopData?.AdminShop) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }
  if (!productData?.AdminProducts) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }
  if (!jobsData?.AdminJobs.jobs) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col">
      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <div className="flex flex-row justify-between my-2">
          <h6 className="text-gray-400 text-lg font-bold uppercase">Stato servizio</h6>
          <Button
            size="xs"
            color="purple"
            onClick={() => {
              navigate('/admin/settings/account');
            }}
          >
            <i className="fa fa-sign-in-alt" />
          </Button>
        </div>
        <StateHome shop={shopData.AdminShop} />
      </div>

      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <div className="flex flex-row justify-between my-2">
          <h6 className="text-gray-400 text-lg font-bold uppercase">Revenue</h6>
          {/* <Button
            size="xs"
            color="purple"
            onClick={() => {
              navigate('/admin/shop/products');
            }}
          >
            <i className="fa fa-sign-in-alt" />
          </Button> */}
        </div>
        <SalesHome
          shopEarning={shopData.AdminShop.servicePayments.earning}
          jobs={jobsData.AdminJobs.jobs}
        />
      </div>

      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <div className="flex flex-row justify-between my-2">
          <h6 className="text-gray-400 text-lg font-bold uppercase">Ordini</h6>
          <Button
            size="xs"
            color="purple"
            onClick={() => {
              navigate('/admin/sales/orders');
            }}
          >
            <i className="fa fa-sign-in-alt" />
          </Button>
        </div>
        <OrdersHome jobs={jobsData.AdminJobs.jobs} />
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
          <div className="flex flex-row justify-between my-2">
            <h6 className="text-gray-400 text-lg font-bold uppercase">Prodotti</h6>
            <Button
              size="xs"
              color="purple"
              onClick={() => {
                navigate('/admin/shop/products');
              }}
            >
              <i className="fa fa-sign-in-alt" />
            </Button>
          </div>
          <ProductsHome shop={shopData.AdminShop} products={productData.AdminProducts} />
        </div>
        <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
          <div className="flex flex-row justify-between my-2">
            <h6 className="text-gray-400 text-lg font-bold uppercase">Negozio</h6>
            <Button
              size="xs"
              color="purple"
              onClick={() => {
                navigate('/admin/settings/account');
              }}
            >
              <i className="fa fa-sign-in-alt" />
            </Button>
          </div>
          <ShopHome shop={shopData.AdminShop} />
        </div>
      </div>
    </div>
  );
};

export { DashView };
