import React from 'react';
import { arrayMoveImmutable } from 'array-move';
import { useMutation } from '@apollo/client';

import {
  Page,
  updateProductData,
  updateProductVariables,
  UPDATE_PRODUCT,
} from '../../stores/queries/product';

import { LOG } from '../../config';

import { transformApolloData } from '../../libs/utils';

import { ProductPageChoices } from '..';
import { Button } from '../../ui';

const log = LOG.extend('PRODUCT_PAGES');

interface ProductPagesProps {
  _id: string;
  pages: Page[] | undefined;
}
const ProductPages = ({ _id, pages }: ProductPagesProps) => {
  // -- -- --
  // -- MUTATIONS --
  // -- -- --
  const [updateProduct, { error: updateProductError, loading: updateProductLoading }] = useMutation<
    updateProductData,
    updateProductVariables
  >(UPDATE_PRODUCT);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const update = (
    newProduct: updateProductVariables['product'],
    files?: updateProductVariables['files']
  ) => {
    log.info('CALL updateProduct');

    let cleanNewProduct = transformApolloData(newProduct);
    updateProduct({
      variables: {
        _id: _id,
        product: cleanNewProduct,
        files,
      },
    });
  };

  const movePagePosition = (index: number, where: 'up' | 'down') => {
    log.debug('CALL movePagePosition');

    if (!pages) {
      log.error('  - pages NOT found');
      return;
    }
    if (index === 0 && where === 'up') {
      log.debug('  - can NOT move Up index 0');
      return;
    }
    if (index === pages.length - 1 && where === 'down') {
      log.debug('  - can NOT move Down last index');
      return;
    }

    let newArray: Page[] = [];
    if (where === 'up') {
      log.debug(`  - move item:${index} up`);
      newArray = arrayMoveImmutable(pages, index, index - 1);
    }
    if (where === 'down') {
      log.debug(`  - move item:${index} down`);
      newArray = arrayMoveImmutable(pages, index, index + 1);
    }

    update({
      pages: newArray,
    });
  };

  const deletePage = (index: number) => {
    log.debug('CALL deletePage');

    if (!pages) {
      log.error('  - pages NOT found');
      return;
    }
    if (!pages[index]) {
      log.error(`  - page ${index} NOT found in pages`);
      return;
    }

    let fileInput: updateProductVariables['files'] = [];

    // rimuovo vecchie picture se necessario
    for (let i = 0; i < pages[index].choices.length; i++) {
      let picId = pages[index].choices[i].picture._id;
      if (picId) {
        fileInput.push({
          fileId: picId,
          action: 'remove',
        });
        log.debug(`  - remove OLD pages[${index}].choices[${i}].picture file: ${picId}`);
      }
    }

    let newPages = [...pages];

    newPages.splice(index, 1);

    update({
      pages: newPages,
    });
    log.info(`  - delete page[${index}]`);
  };

  const updatePage = (
    pageIndex: number,
    newPage: Page,
    files?: updateProductVariables['files']
  ) => {
    log.debug('CALL updatePage');

    if (!pages) {
      log.error('  - pages NOT found');
      return;
    }
    if (!pages[pageIndex]) {
      log.error(`  - page ${pageIndex} NOT found in pages`);
      return;
    }

    log.info(`  - update page:${pageIndex}`);

    let newPages = [...pages];
    newPages[pageIndex] = newPage;

    update(
      {
        pages: newPages,
      },
      files
    );
  };

  const addEmptyPage = () => {
    log.debug('CALL addEmptyPage');

    if (!pages) {
      log.error('  - pages NOT found');
      return;
    }

    log.info(`  - add empty page @ ${pages.length}`);

    let newPages = [...pages];
    newPages.push({
      name: 'Senza nome',
      choices: [
        {
          name: 'opzione 1',
          percentualPriceIncrement: 0,
          picture: {
            _id: null,
            url: null,
          },
        },
        {
          name: 'opzione 2',
          percentualPriceIncrement: 0,
          picture: {
            _id: null,
            url: null,
          },
        },
      ],
    });

    update({
      pages: newPages,
    });
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  if (!pages) {
    log.warn(`Pages NOT found for product ${_id}`);
    return (
      <div
        className="relative break-words w-full bg-gray-50 rounded-2xl border-0 flex flex-col"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          Ops - C'è stato un problema. Varianti non disponibili
        </div>
      </div>
    );
  }

  if (updateProductError) {
    log.error(updateProductError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 rounded-2xl border-0 flex flex-col"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${updateProductError}`}
        </div>
      </div>
    );
  }

  const renderList = () => {
    if (!pages.length) {
      return (
        <div>
          <h6 className="text-gray-500 mt-3 mb-4">
            Non sono ancora presenti variabili per questo prodotto
          </h6>
        </div>
      );
    }

    return pages.map((page, index) => {
      return (
        <ProductPageChoices
          key={index}
          page={page}
          pageIndex={index}
          onPageMove={movePagePosition}
          onPageDelete={deletePage}
          onPageEdit={updatePage}
          loading={updateProductLoading}
        />
      );
    });
  };

  return (
    <div
      className="relative break-words w-full bg-gray-50 rounded-2xl border-0 flex flex-col"
      style={{ height: 'calc(100vh - 200px)' }}
    >
      <div className="flex-col px-4 lg:px-10 pb-10 overflow-auto w-full h-full">
        <h4 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">LISTA VARIANTI</h4>
        {renderList()}
        <Button
          className="ml-8 my-3"
          size="xs"
          color="green"
          onClick={() => {
            addEmptyPage();
          }}
        >
          <i className="fa fa-plus" />
        </Button>
      </div>
    </div>
  );
};

export { ProductPages };
