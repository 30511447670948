import React, { ReactNode, useMemo } from 'react';
import DataTable from 'react-data-table-component';

import { useFormFields } from '../hooks/forms';
import { resolveObjectPath } from '../libs/utils';
import { TextBox } from './TextBox';
import { Loading } from './Loading';
import { PaginationChangePage } from 'react-data-table-component/dist/src/DataTable/types';

interface TableProps {
  columns: any;
  data: any[];
  height: string;
  title: string;
  loading: boolean;
  onRowClick?: (row: any, e: any) => void;
  addElementComponent?: ReactNode;
  selectableRows?: boolean;
  filterSelector?: string;
  filterPlaceholder?: string;
  defaultSortFieldId?: string;
  defaultSortInvert?: boolean;
  pagination?: boolean;
  paginationServer?: boolean;
  paginationDefaultPage?: number;
  paginationPerPage?: number;
  onChangePage?: PaginationChangePage;
  paginationTotalRows?: number;
}

const Table = (props: TableProps) => {
  const [inputs, handleInputChange] = useFormFields<{
    filter: string;
  }>({
    filter: '',
  });

  const filteredItems = props.filterSelector
    ? props.data?.filter(row => {
        let item = resolveObjectPath(row, props.filterSelector as string, '-') as string;
        if (!item) return false;
        return item.toLowerCase().includes(inputs.filter.toLowerCase());
      })
    : props.data;
  // ? props.data?.filter(
  //     item =>
  //       item[props.filterSelector as any] &&
  // item[props.filterSelector as any].toLowerCase().includes(inputs.filter.toLowerCase())
  //   )
  // : props.data;

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="flex flex-row justify-between items-center w-full py-2">
        <h3 className={'font-semibold text-2xl text-gray-600'}>{props.title}</h3>
        <div className="flex flex-row justify-end">
          {props.addElementComponent ? props.addElementComponent : null}

          <div className={'flex flex-row'} style={{ width: '200px' }}>
            {props.filterSelector && (
              <TextBox
                id="filter"
                type="text"
                placeholder={props.filterPlaceholder || 'Cerca per nome ...'}
                required={true}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.filter]);

  let paginationComponentOptions = undefined;
  if (props.pagination) {
    paginationComponentOptions = {
      rowsPerPageText: 'Elementi per pagina',
      rangeSeparatorText: 'di',
      noRowsPerPage: true,
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Tutti',
    };
  }

  return (
    <DataTable
      noHeader
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      fixedHeader
      highlightOnHover
      striped={true}
      fixedHeaderScrollHeight={props.height}
      title={props.title}
      responsive={true}
      columns={props.columns}
      data={filteredItems || []}
      style={styles.container}
      customStyles={styles.table}
      pointerOnHover
      pagination={props.pagination}
      paginationDefaultPage={props.paginationDefaultPage}
      paginationPerPage={props.paginationPerPage}
      paginationComponentOptions={paginationComponentOptions}
      paginationServer={props.paginationServer}
      onChangePage={props.onChangePage}
      paginationTotalRows={props.paginationTotalRows}
      progressPending={props.loading}
      selectableRows={props.selectableRows}
      defaultSortFieldId={props.defaultSortFieldId}
      defaultSortAsc={props.defaultSortInvert}
      noDataComponent={
        <div className="w-full flex items-center justify-center py-8 font-bold text-gray-400">
          Nessun elemento da visualizzare
        </div>
      }
      progressComponent={
        <div className="w-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      }
      onRowClicked={props.onRowClick}
    />
  );
};

const styles = {
  container: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
  table: {
    subHeader: {
      style: {
        borderRadius: 30,
      },
    },
    headRow: {
      style: {
        textTransform: 'uppercase',
        backgroundColor: '#e9e9e9',
        borderBottomWidth: '0px',
        borderBottomColor: '#eee',
        borderBottomStyle: 'solid',
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#8f8f8f',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    rows: {
      style: {
        '&:not(:last-of-type)': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '0px',
          borderBottomColor: '#eee',
        },
      },
    },
    cells: {
      style: {
        color: '#666',
      },
    },
    pagination: {
      style: {
        borderBottomLeftRadius: '1rem',
        borderBottomRightRadius: '1rem',
      },
    },
    table: {
      style: {
        overflow: 'hidden',
        borderBottomLeftRadius: '1rem',
        borderBottomRightRadius: '1rem',
      },
    },
    tableWrapper: {
      style: {
        display: 'table',
      },
    },
  },
};

export { Table };
