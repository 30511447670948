import React, { Fragment, useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Dialog, Transition } from '@headlessui/react';

import { LOG } from '../../config';

import { Discount } from '../../stores/queries/product';

import { Button, TextBox } from '../../ui';
import { useFormFields } from '../../hooks/forms';
import { addDaysToDate, isNumber, isNumberAndNotEmpty } from '../../libs/utils';

const log = LOG.extend('PRODUCT_PRICES');

type Inputs = 'from' | 'newPrice' | 'timeNewPrice';

interface ProductPriceAddModalProps {
  maxQuantity: number;
  minQuantity: number;
  onAdd: (newDiscount: Discount) => void;
}
const ProductPriceAddModal = ({ maxQuantity, minQuantity, onAdd }: ProductPriceAddModalProps) => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newDiscount = useRef<Discount>({
    newPrice: null,
    quantityFrom: null,
    quantityTo: null,
    timeDiscount: {
      from: null,
      to: null,
      newPrice: null,
    },
  });
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in Inputs]: string }>({
    from: '',
    newPrice: '',
    timeNewPrice: '',
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [inputsError, setInputErrors] = useState<{ [key in Inputs]: string }>({
    from: '',
    newPrice: '',
    timeNewPrice: '',
  });

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const resetForm = () => {
    setInputs({
      from: '',
      newPrice: '',
      timeNewPrice: '',
    });
    setInputErrors({
      from: '',
      newPrice: '',
      timeNewPrice: '',
    });
    newDiscount.current = {
      newPrice: null,
      quantityFrom: null,
      quantityTo: null,
      timeDiscount: {
        from: null,
        to: null,
        newPrice: null,
      },
    };
  };

  const resetError = (key: Inputs) => {
    setInputErrors(oldErrors => {
      return {
        ...oldErrors,
        [key]: '',
      };
    });
  };

  const isFormValid = () => {
    log.debug('CALL isFormValid()');
    let isFormValid = true;

    //from - number not null
    if (!isNumberAndNotEmpty(inputs.from)) {
      log.info('  - inputs.from NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          from: '* campo obbligatorio. Solo numeri',
        };
      });
      isFormValid = false;
    }

    //from - inside min & max quantity
    if (Number(inputs.from) <= minQuantity || Number(inputs.from) >= maxQuantity) {
      log.info('  - inputs.from NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          from: '* quantità inserita al fuori dei limiti.',
        };
      });
      isFormValid = false;
    }

    //newPrice - number not null
    if (!isNumberAndNotEmpty(inputs.newPrice)) {
      log.info('  - inputs.newPrice NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          newPrice: '* campo obbligatorio. Solo numeri',
        };
      });
      isFormValid = false;
    }

    //timeNewPrice - number
    if (!isNumber(inputs.timeNewPrice)) {
      log.info('  - inputs.timeNewPrice NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          timeNewPrice: '* Amessi solo numeri',
        };
      });
      isFormValid = false;
    }

    return isFormValid;
  };

  function closeModal() {
    resetForm();
    setStartDate(null);
    setEndDate(null);
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <>
      <Button
        className="ml-8 my-3"
        size="xs"
        color="green"
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        <i className="fa fa-plus" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l mb-2 font-bold uppercase">
                  Nuova Fascia di prezzi
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">Quantità</p>
                  <p className="text-xs text-gray-400">{'A partire da...'}</p>
                  <TextBox
                    id="from"
                    type="text"
                    isNumber
                    value={inputs.from}
                    placeholder={'Da...'}
                    required={true}
                    error={inputsError.from}
                    onChange={event => {
                      newDiscount.current.quantityFrom = Number(event.target.value) || null;
                      log.debug(`update prices.quantityFrom with [${event.target.value}]`);
                      handleInputChange(event);
                      resetError('from');
                    }}
                  />
                </div>

                <div className="mt-2">
                  <p className="text-sm text-gray-500">{'Prezzo Unitario [€]'}</p>
                  <p className="text-xs text-gray-400">
                    {'Applicato per questa quantità di prodotti, se non sono presenti promozioni'}
                  </p>
                </div>
                <TextBox
                  id="newPrice"
                  type="text"
                  isNumber
                  selectOnFocus={true}
                  value={inputs.newPrice}
                  placeholder={'prezzo per questa fascia'}
                  required={true}
                  error={inputsError.newPrice}
                  onChange={event => {
                    newDiscount.current.newPrice = Number(event.target.value);
                    log.info(`update prices.newPrice with [${Number(event.target.value)}]`);
                    handleInputChange(event);
                    resetError('newPrice');
                  }}
                />

                <hr className="mt-2 border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">{'In promozione'}</p>

                  <div className="flex flex-row items-center">
                    {/* @ts-ignore */}
                    <DatePicker
                      className="shadow my-2 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
                      dateFormat="dd MMMM yyyy"
                      selected={startDate}
                      placeholderText="A partire da..."
                      onChange={date => {
                        if (date === null) {
                          newDiscount.current.timeDiscount.from = null;
                        } else {
                          newDiscount.current.timeDiscount.from = (date as Date).toISOString();
                        }
                        setStartDate(date as Date | null);
                      }}
                    />
                    <p className="text-sm mx-2 text-gray-500">-</p>
                    {/* @ts-ignore */}
                    <DatePicker
                      className="shadow my-2 px-3 py-2 placeholder-gray-400 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
                      dateFormat="dd MMMM yyyy"
                      selected={endDate}
                      placeholderText="Fino a..."
                      onChange={date => {
                        if (date === null) {
                          newDiscount.current.timeDiscount.to = null;
                        } else {
                          newDiscount.current.timeDiscount.to = addDaysToDate(
                            date as Date,
                            1
                          ).toISOString();
                        }
                        setEndDate(date as Date | null);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{'Prezzo Unitario [€]'}</p>
                  <p className="text-xs text-gray-400">
                    {
                      'Applicato per questa quantità di prodotti, nel periodo di promozione indicato'
                    }
                  </p>
                </div>
                <TextBox
                  id="timeNewPrice"
                  type="text"
                  isNumber
                  value={inputs.timeNewPrice}
                  selectOnFocus={true}
                  placeholder={'Prezzo nel periodo di promozione'}
                  required={true}
                  error={inputsError.timeNewPrice}
                  onChange={event => {
                    newDiscount.current.timeDiscount.newPrice = Number(event.target.value);
                    log.debug(
                      `update prices.timeDiscount.newPrice with [${Number(event.target.value)}]`
                    );
                    handleInputChange(event);
                    resetError('timeNewPrice');
                  }}
                />

                <div className="mt-4 flex flex-row gap-4">
                  <Button
                    onClick={() => {
                      if (isFormValid()) {
                        log.info('valid form -> upload');
                        onAdd(newDiscount.current);
                        closeModal();
                      } else {
                        log.info('NOT valid form -> NOT upload');
                      }
                    }}
                    color="purple"
                  >
                    Salva
                  </Button>
                  <Button onClick={closeModal} color="red">
                    Annulla
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ProductPriceAddModal };
