import jwtDecode from 'jwt-decode';

import { ENV, LOG } from '../../config';
import { userLogged, shopType } from '../../stores/db/appstate';
import { DB_AUTH } from '../../stores/db/auth';

const log = LOG.extend('AUTHLIB');

const defaultError = 'Undefined Error';

type FetchNewAccessToken = (
  refreshToken: string
) => Promise<{ accessToken: string; refreshToken: string | undefined } | undefined>;

type AuthCodeRes = {
  success: boolean;
  errors: string[];
};

// Variabile e funzione per accedere al client apollo dall'esterno dei componenti
let extApolloClient: any = null;
const setExtApolloClient = (client: any): boolean => {
  extApolloClient = client;
  return true;
};

// Funzione che controlla la validità di un Token
const isTokenValid = (token?: string | null): boolean => {
  if (!token) {
    return false;
  }
  const decodedToken: any = decodeToken(token);
  if (!decodedToken) {
    return false;
  }
  const now = new Date();
  return now.getTime() < decodedToken.exp * 1000;
};

// Funzione che decodifica un Token
const decodeToken = (token?: string | null) => {
  if (!token) {
    return undefined;
  }

  let decodedToken: { [key: string]: any };

  try {
    decodedToken = jwtDecode<{ [key: string]: any }>(token);
  } catch ({ message }) {
    log.error(`Decode Access Token ERROR: ${message}`);
    return undefined;
  }

  if (!decodedToken) {
    log.warn('Token decode error (=null) | ' + token);
    return undefined;
  }

  return decodedToken;
};

// Funzione che recupera un nuovo AccessToken dato il RefreshToken
const fetchNewAccessToken: FetchNewAccessToken = async refreshToken => {
  if (!ENV.auth.uri) {
    throw new Error('ENV.auth.uri must be set to use refresh token link');
  }

  try {
    const body = JSON.stringify({
      refreshToken,
    });

    const response = await fetch(ENV.auth.uri + '/auth/refreshToken', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const refreshResponse = await response.json();

    if (!refreshResponse || !refreshResponse.refreshToken || !refreshResponse.accessToken) {
      return undefined;
    }

    return {
      accessToken: refreshResponse.accessToken,
      refreshToken: refreshResponse.refreshToken,
    };
  } catch ({ message }) {
    log.error(`Fetch New Access Token ERROR: ${message}`);
    return undefined;
  }
};

// Funzione che gestisce la risposta ad un login, salva i nuovi token e setta gli stati
const handleLoginResponse = async (response: any) => {
  const loginResponse = await response.json();

  if (loginResponse?.errors[0]) {
    log.error(loginResponse.errors);
    return {
      success: false,
      msg: loginResponse?.errors[0] || defaultError,
    };
  }

  if (!loginResponse) {
    return {
      success: false,
      msg: defaultError,
    };
  }

  if (!loginResponse || !loginResponse.accessToken || !loginResponse.refreshToken) {
    log.debug('Login Request Faild, NO TOKENS');
    return {
      success: false,
      msg: loginResponse?.errors[0] || defaultError,
    };
  }

  const tokens = {
    accessToken: loginResponse.accessToken,
    refreshToken: loginResponse.refreshToken,
    atDecoded: decodeToken(loginResponse.accessToken),
  };

  await DB_AUTH.set({
    user: tokens?.atDecoded?.user?._id,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
  });

  //userLogged(tokens?.atDecoded?.user?._id);

  return {
    success: true,
    msg: 'Login success',
    user: tokens?.atDecoded?.user?._id,
  };
};

// Funzione per cambiare shop
const changeShop = async (shopId: string | null, type: 'full' | 'light') => {
  if (!shopId) {
    log.error('changeShop with no shopID -> logOut');
    logout();
    return false;
  }
  if (!type) {
    log.error('changeShop with no shop type -> logOut');
    logout();
    return false;
  }

  await DB_AUTH.set({
    shop: shopId,
    shopType: type,
  });
  shopType(type);

  return true;
};

// Login user con email e password
const loginEmail = async (email: string, password: string) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to login');
    throw new Error('ENV.auth.uri must be set to login');
  }

  if (!email || !password) {
    log.debug('Login Request Faild, NO EMAIL OR PASSWORD');
    return { success: false, msg: defaultError };
  }

  if (password.length < 8) {
    log.debug('Login Request Faild, Password small');
    return { success: false, msg: 'Password troppo corta' };
  }

  try {
    const body = JSON.stringify({
      email,
      password,
    });

    const response = await fetch(ENV.auth.uri + '/auth/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const loginResponse = await handleLoginResponse(response);

    return loginResponse;
  } catch ({ message }) {
    log.error(`loginEmail ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// Login user con codice di verifica
const loginCode = async (email: string, code: string) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to login');
    throw new Error('ENV.auth.uri must be set to login');
  }

  if (!email || !code) {
    log.debug('Login Request Faild, NO EMAIL OR CODE');
    return { success: false, msg: defaultError };
  }

  try {
    const body = JSON.stringify({
      email,
      code,
    });

    const response = await fetch(ENV.auth.uri + '/auth/loginCode', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const loginResponse = await handleLoginResponse(response);

    return loginResponse;
  } catch ({ message }) {
    log.error(`loginCode ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// Invia codice di verifica se utente è abilitato
const sendAuthCode = async (email: string) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to login');
    throw new Error('ENV.auth.uri must be set to login');
  }

  if (!email) {
    log.debug('Login Request Faild, NO EMAIL OR PASSWORD');
    return { success: false, msg: 'email not valid' };
  }

  try {
    const body = JSON.stringify({
      email,
    });

    const response = await fetch(ENV.auth.uri + '/auth/sendAuthCodeForAdmin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      return { success: false, msg: 'can not connect to the server' };
    }

    const responseData = (await response.json()) as AuthCodeRes;

    if (!responseData.success) {
      log.debug('sendAuthCode() - response.success: false');
      for (let i = 0; i < responseData.errors.length; i++) {
        log.debug(responseData.errors[i]);
      }
      return { success: false, msg: responseData.errors?.[0] };
    }

    // utente esiste, mandato il codice via mail
    return { success: true, msg: 'everything fine' };
  } catch ({ message }) {
    log.error(`sendAuthCode ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// Registrazione user con email
const registerEmail = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string
) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to register');
    throw new Error('ENV.auth.uri must be set to register');
  }

  if (!email || !password || !firstName || !lastName) {
    log.debug('Register Request Faild, INCOMPLETE DATA');
    return { success: false, msg: 'Compila tutti i campi' };
  }

  if (password.length < 8) {
    log.debug('Login Request Faild, Password small');
    return { success: false, msg: 'Password troppo corta' };
  }

  try {
    const body = JSON.stringify({
      email,
      password,
      basicInfo: {
        firstName,
        lastName,
      },
    });

    const response = await fetch(ENV.auth.uri + '/auth/register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const loginResponse = await handleLoginResponse(response);

    return loginResponse;
  } catch ({ message }) {
    log.error(`loginEmail ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// funzione di verifica email utente
const verifyEmail = async (email: string, code: number) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to use refresh token link');
    throw new Error('ENV.auth.uri must be set to use refresh token link');
  }

  if (!email) {
    log.debug('Verify Email Request Faild, NO email');
    return { success: false, msg: defaultError };
  }

  if (!code) {
    log.debug('Verify Email Request Faild, NO code');
    return { success: false, msg: 'Codice verifica email obbligatorio!' };
  }

  try {
    const body = JSON.stringify({
      email,
      code,
    });

    const response = await fetch(ENV.auth.uri + '/auth/verifyEmail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const verifyResponse = await response.json();

    if (!verifyResponse || !verifyResponse.success) {
      return verifyResponse;
    }

    return {
      success: true,
      msg: email,
    };
  } catch ({ message }) {
    log.error(`Verify Email ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// funzione di reinvio codice verifica email
const resendVerifyEmail = async (email: string) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to Resend Verify Email');
    throw new Error('ENV.auth.uri must be set to Resend Verify Email');
  }

  if (!email) {
    log.debug('Resend Verify Email Request Faild, NO email');
    return { success: false, msg: defaultError };
  }

  try {
    const body = JSON.stringify({
      email,
    });

    const response = await fetch(ENV.auth.uri + '/auth/resendVerifyEmail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const verifyResponse = await response.json();

    if (!verifyResponse || !verifyResponse.success) {
      return verifyResponse;
    }

    return {
      success: true,
      msg: email,
    };
  } catch ({ message }) {
    log.error(`Resend Verify Email ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// Funzioone per richiedere l'email di recupero password
const recoverPassword = async (email: string) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to use refresh token link');
    throw new Error('ENV.auth.uri must be set to use refresh token link');
  }

  if (!email) {
    log.debug('Recover Password Request Faild, NO email');
    return { success: false, msg: 'Email obbligatoria' };
  }

  try {
    const body = JSON.stringify({
      email,
    });

    const response = await fetch(ENV.auth.uri + '/auth/sendChangePasswordEmail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const recoverResponse = await response.json();

    if (!recoverResponse || !recoverResponse.success) {
      return recoverResponse;
    }

    return {
      success: true,
      msg: email,
    };
  } catch ({ message }) {
    log.error(`Recover password Email ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// Funzione che deregistra un utente eliminandolo
const deregister = async (email: string, password: string) => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to deregister');
    throw new Error('ENV.auth.uri must be set to deregister');
  }

  if (!email) {
    log.debug('Deregister Request Faild, NO email');
    return { success: false, msg: 'Email obbligatoria' };
  }

  if (!password) {
    log.debug('Deregister Request Faild, NO password');
    return { success: false, msg: 'Password obbligatoria' };
  }

  try {
    const body = JSON.stringify({
      email,
      password,
    });

    const response = await fetch(ENV.auth.uri + '/auth/deregister', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    });

    const deregisterResponse = await response.json();

    if (!deregisterResponse || !deregisterResponse.success) {
      return deregisterResponse;
    }

    return {
      success: true,
      msg: email,
    };
  } catch ({ message }) {
    log.error(`deregister ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// Logout user e clear cache
const logout = async () => {
  log.debug('LOGOUT: clear auth');
  await DB_AUTH.clear();
  log.debug('LOGOUT: reset state');
  userLogged(null);
  log.debug('LOGOUT: clear cache');
  //await extApolloClient.clearStore();
  return true;
};

export {
  isTokenValid,
  fetchNewAccessToken,
  decodeToken,
  loginEmail,
  sendAuthCode,
  loginCode,
  registerEmail,
  logout,
  verifyEmail,
  resendVerifyEmail,
  recoverPassword,
  deregister,
  setExtApolloClient,
  changeShop,
};
