import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import {
  addFilesData,
  addFilesVariables,
  ADD_FILE,
  Choice,
  Page,
  updateProductVariables,
} from '../../stores/queries/product';

import { LOG } from '../../config';

import { Button, EditBoxNumber, EditBoxText, EditImage } from '../../ui';
import { isNumber, resizeImage } from '../../libs/utils';

const log = LOG.extend('PRODUCT_PAGES');

interface SingleChoiceProps {
  choice: Choice;
  choiceIndex: number;
  setEditMode: (value: boolean) => void;
  onChoiceEdit: (
    choiceIndex: number,
    newChoice: Choice,
    files?: updateProductVariables['files']
  ) => void;
  onChoiceDelete: (choiceIndex: number) => void;
}
const SingleChoice = ({
  choice,
  choiceIndex,
  setEditMode,
  onChoiceEdit,
  onChoiceDelete,
}: SingleChoiceProps) => {
  // -- -- --
  // -- MUTATIONS --
  // -- -- --
  const [addFile, { error: addFileError, loading: addFileLoading, data: fileData }] = useMutation<
    addFilesData,
    addFilesVariables
  >(ADD_FILE);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const uploadChoiceCover = async (image: File | undefined) => {
    log.debug('CALL uploadChoiceCover()');

    if (!image) {
      log.error('  - can NOT find input image');
      return;
    }

    try {
      let resizedImg = await resizeImage(image);
      let addFileResult = await addFile({
        variables: {
          files: [resizedImg],
          scope: 'gallery',
        },
      });

      if (!addFileResult.data || !addFileResult.data.AdminAddFiles[0]) {
        log.error(`  - addFile Error: ${addFileResult.errors}`);
        return;
      }

      const { _id, url, filename } = addFileResult.data?.AdminAddFiles[0];
      log.info(`  - addFile upload success - ${_id} - ${filename}`);

      let fileInput: updateProductVariables['files'] = [];

      // rimuovo vecchia picture se necessario
      if (choice.picture._id) {
        fileInput.push({
          fileId: choice.picture._id,
          action: 'remove',
        });
        log.debug(`  - remove OLD choice.picture file: ${choice.picture._id}`);
      }

      // aggiungo nuova picture
      let newChoice = {
        ...choice,
        picture: {
          _id: _id,
          url: url,
        },
      };

      fileInput.push({
        fileId: _id,
        action: 'insert',
      });
      log.debug(`  - add NEW choice.picture file: ${_id}`);

      onChoiceEdit(choiceIndex, newChoice, fileInput.length ? fileInput : undefined);
    } catch (err) {
      log.error('uploadFolderPicture - something went wrong');
      log.error(err);
    }
  };

  const removeChoiceCover = async () => {
    log.debug('CALL removeChoiceCover()');

    let fileInput: updateProductVariables['files'] = [];

    // rimuovo vecchia picture se necessario
    if (choice.picture._id) {
      fileInput.push({
        fileId: choice.picture._id,
        action: 'remove',
      });
      LOG.debug(`  - remove OLD choice.picture file: ${choice.picture._id}`);
    }

    let newChoice = {
      ...choice,
      picture: {
        _id: null,
        url: null,
      },
    };
    log.info(`  - update choice with image: null`);

    onChoiceEdit(choiceIndex, newChoice, fileInput.length ? fileInput : undefined);
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  const renderDeleteChoiceButton = (index: number) => {
    if (index < 2) return null;

    return (
      <Button
        className="ml-8 my-3"
        size="xs"
        style={{ alignSelf: 'flex-start' }}
        color="redLight"
        onClick={() => {
          onChoiceDelete(index);
        }}
      >
        <i className="fa fa-minus" />
      </Button>
    );
  };

  if (addFileError) {
    log.error(addFileError);
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <div className="p-4 text-white text-sm bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${addFileError}`}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col mb-2">
      <div className="flex flex-row justify-between">
        <h4 className="text-gray-600 text-l my-2 font-bold uppercase">{`Opzione ${
          choiceIndex + 1
        }`}</h4>
        {renderDeleteChoiceButton(choiceIndex)}
      </div>
      <hr className="border-b-1 border-gray-200 mb-2" />
      <div className="flex flex-row flex-wrap w-full h-full gap-2">
        <div className="flex flex-col flex-1 w-1/2">
          <EditBoxText
            editable={true}
            value={choice.name}
            label="nome"
            maxChar={50}
            onEnterEdit={() => {
              setEditMode(true);
            }}
            onExitEdit={() => {
              setEditMode(false);
            }}
            onPressSave={value => {
              log.info(`update choice[${choiceIndex}].name with [${value}]`);
              onChoiceEdit(choiceIndex, {
                ...choice,
                name: value || '',
              });
            }}
            isInputValid={value => value !== ''}
            inputInvalidMsg="* campo obbligatorio"
          />
          <EditBoxNumber
            editable={true}
            value={choice.percentualPriceIncrement}
            label="Incremento del prezzo %"
            unit="%"
            maxChar={6}
            onEnterEdit={() => {
              setEditMode(true);
            }}
            onExitEdit={() => {
              setEditMode(false);
            }}
            onPressSave={value => {
              log.info(`update choice[${choiceIndex}].percentualPriceIncrement with [${value}]`);
              onChoiceEdit(choiceIndex, {
                ...choice,
                percentualPriceIncrement: value || 0,
              });
            }}
            validateInput={value => isNumber(value, 0, 100)}
            inputInvalidMsg="* Amessi solo numeri fra 0 e 100"
          />
        </div>
        <div className="flex flex-col flex-1 w-1/2">
          <EditImage
            editable={true}
            label="cover"
            value={choice.picture.url ? choice.picture.url : undefined}
            id={choice.picture._id ? choice.picture._id : undefined}
            loading={addFileLoading}
            onChange={files => {
              uploadChoiceCover(files[0]);
            }}
            onPressRemove={id => {
              removeChoiceCover();
            }}
          />
        </div>
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

interface ProductPageChoicesProps {
  pageIndex: number;
  page: Page;
  onPageMove: (index: number, where: 'up' | 'down') => void;
  onPageDelete: (index: number) => void;
  onPageEdit: (pageIndex: number, newPage: Page, files?: updateProductVariables['files']) => void;
  loading: boolean;
}
const ProductPageChoices = ({
  page,
  pageIndex,
  onPageMove,
  onPageDelete,
  onPageEdit,
  loading,
}: ProductPageChoicesProps) => {
  // -- -- --
  // -- STATTES --
  // -- -- --
  const [showChoices, setShowChoices] = useState(false);
  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const updateChoice = (
    choiceIndex: number,
    newChoice: Choice,
    files?: updateProductVariables['files']
  ) => {
    log.debug('CALL updateChoice()');

    if (!page.choices[choiceIndex]) {
      log.error(`  - choice ${choiceIndex} NOT found in page choices`);
      return;
    }

    log.info(`  - update choice:${choiceIndex}`);

    let newChoices = [...page.choices];
    newChoices[choiceIndex] = newChoice;

    let newPage: Page = {
      ...page,
      choices: newChoices,
    };

    onPageEdit(pageIndex, newPage, files);
  };

  const deleteChoice = (choiceIndex: number) => {
    log.debug('CALL deleteChoice()');

    if (!page.choices[choiceIndex]) {
      log.error(`  - choice ${choiceIndex} NOT found in page choices`);
      return;
    }

    log.info(`  - delete choice:${choiceIndex}`);

    let fileInput: updateProductVariables['files'] = [];

    let picId = page.choices[choiceIndex].picture._id;
    if (picId) {
      fileInput.push({
        fileId: picId,
        action: 'remove',
      });
      log.debug(`  - remove OLD choices[${choiceIndex}].picture file: ${picId}`);
    }

    let newChoices = [...page.choices];
    newChoices.splice(choiceIndex, 1);

    let newPage: Page = {
      ...page,
      choices: newChoices,
    };

    onPageEdit(pageIndex, newPage, fileInput.length ? fileInput : undefined);
  };

  const updatePageName = (name: string) => {
    log.debug('CALL updateChoice()');

    log.info(`  - update page:${pageIndex} name`);

    let newPage: Page = {
      ...page,
      name: name,
    };

    onPageEdit(pageIndex, newPage);
  };

  const addEmptyChoice = () => {
    log.debug('CALL addEmptyChoice()');

    log.info(`  - add empty choice @ ${page.choices.length}`);

    let newChoices = [...page.choices];
    newChoices.push({
      name: 'opzione X',
      percentualPriceIncrement: 0,
      picture: {
        _id: null,
        url: null,
      },
    });

    let newPage: Page = {
      ...page,
      choices: newChoices,
    };

    onPageEdit(pageIndex, newPage);
  };

  const setEditMode = (value: boolean) => {
    setActiveEdit(value);
  };

  // -- -- --
  // -- RENDER --
  // -- -- --
  const renderEditOptions = () => {
    if (!showChoices) return null;

    let choices = page.choices.map((choice, index) => {
      return (
        <SingleChoice
          key={index}
          choice={choice}
          choiceIndex={index}
          setEditMode={setEditMode}
          onChoiceEdit={updateChoice}
          onChoiceDelete={deleteChoice}
        />
      );
    });

    return (
      <div className="relative my-2 px-4 py-1 flex flex-col flex-1 rounded-2xl bg-gray-100">
        <div className="flex flex-col mb-2">
          <h4 className="text-gray-600 text-l my-2 font-bold uppercase">Titolo</h4>
          <hr className="border-b-1 border-gray-200 mb-2" />
          <EditBoxText
            editable={true}
            value={page.name}
            maxChar={30}
            onEnterEdit={() => {
              setEditMode(true);
            }}
            onExitEdit={() => {
              setEditMode(false);
            }}
            onPressSave={value => {
              if (value) {
                log.info(`update page name with [${value}]`);
                updatePageName(value);
              }
            }}
            isInputValid={value => value !== ''}
            inputInvalidMsg="* campo obbligatorio"
          />
        </div>
        {choices}
        <div className="flex flex-col mb-2">
          <Button
            className="ml-8 my-3"
            size="xs"
            style={{ alignSelf: 'flex-start' }}
            color="green"
            onClick={() => {
              addEmptyChoice();
            }}
          >
            <i className="fa fa-plus" />
          </Button>
        </div>
        {activeEdit && (
          <div
            className="w-full h-full absolute top-0 left-0 rounded-2xl"
            style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
          ></div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-row gap-4">
      <div className="px-4 py-1">
        <h4 className="text-gray-400 text-lg mt-3 mb-3 font-bold">{`Scelta ${pageIndex + 1} `}</h4>
      </div>
      <div
        className={`px-4 py-1 flex flex-col flex-1 rounded-2xl hover:bg-white ${
          showChoices ? 'bg-white' : ''
        }`}
      >
        <div className="flex flex-row items-center gap-4">
          <h4 className="text-gray-400 text-lg mt-3 mb-3 font-bold">{` ${page.name}`}</h4>
          <div>
            <Button
              className="mx-1"
              color="purpleLight"
              disabled={loading}
              onClick={() => {
                onPageMove(pageIndex, 'up');
              }}
            >
              <i className="fa fa-arrow-up" />
            </Button>
            <Button
              className="mx-1"
              color="purpleLight"
              disabled={loading}
              onClick={() => {
                onPageMove(pageIndex, 'down');
              }}
            >
              <i className="fa fa-arrow-down" />
            </Button>
            <Button
              className="mx-1"
              color={showChoices ? 'purple' : 'purpleLight'}
              disabled={loading}
              onClick={() => {
                setShowChoices(state => !state);
              }}
            >
              <i className="fa fa-pencil-alt" />
            </Button>
            <Button
              className="mx-1"
              color="redLight"
              disabled={loading}
              onClick={() => {
                onPageDelete(pageIndex);
              }}
            >
              <i className="fa fa-trash-alt" />
            </Button>
          </div>
        </div>
        {renderEditOptions()}
      </div>
    </div>
  );
};

export { ProductPageChoices };
