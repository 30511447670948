import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import logo from '../../../assets/img/loghi/logo.png';
import { LOG } from '../../../config';
import { Shop } from '../../../stores/queries/shop';
import { Button } from '../../../ui';

interface ShopHomeProps {
  shop: Shop;
}
const ShopHome = ({ shop }: ShopHomeProps) => {
  const paymentStripe = shop.paymentMethods?.stripe?.isEnabled ? 'Abilitato' : 'Non abilitato';
  const shopStatus = shop.isEnabled ? 'Attivo' : 'Non attivo';

  const downloadQRCode = () => {
    LOG.debug('CALL downloadQRCode()');
    const el = document.getElementById('qrCodeEl') as HTMLCanvasElement;
    if (!el) {
      LOG.error('  - qrCodeEl NOT fount');
      return;
    }
    const url = el.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = url;
    aEl.download = 'Shop_QR_code.png';
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <div>
      <div className="grid grid-cols-2 p-4">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Nome</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{shop.name || '-'}</h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Codice</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{shop.code || '-'}</h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Pagamento via Stripe</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{paymentStripe}</h3>
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Stato del servizio</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{shopStatus}</h3>
      </div>
      <div className="w-full flex flex-col gap-4 justify-center items-center">
        <QRCodeCanvas
          id="qrCodeEl"
          value={`https://get.easypixy.com/${shop.code}`}
          size={200}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'M'}
          includeMargin={false}
          imageSettings={{
            src: logo,
            x: undefined,
            y: undefined,
            height: 35,
            width: 35,
            excavate: true,
          }}
        />
        <Button
          size="xs"
          color="purple"
          onClick={() => {
            LOG.debug('click');
            downloadQRCode();
          }}
        >
          Download QR-code
        </Button>
      </div>
    </div>
  );
};

export { ShopHome };
