import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOG } from '../../config';

import { useQuery } from '@apollo/client';
import {
  Job,
  JOBS,
  JobStatus,
  FILTER_STATUS,
  STATUS,
  JobsData,
  JobsVariable,
} from '../../stores/queries/orders';
import { ACTUAL_AUTH } from '../../stores/db/auth';

import { Loading, SelectFilter, Table } from '../../ui';
import { OrderUserInfoModal, OrderPaymentStatusModal, OrderStatusModal } from '..';
import { numberToEuro } from '../../libs/utils';

const log = LOG.extend('ORDERS_LIST');

const getStatusName = (status: JobStatus) => {
  let statusObj = STATUS.find(s => s.value === status);
  let statusLabel = statusObj ? statusObj.label : 'Sconosciuto';

  return statusLabel;
};

const getStatusTxtColor = (status: JobStatus) => {
  let statusColor = 'text-gray-500';

  switch (status) {
    // case 'initializing':
    //   statusColor = 'text-gray-500';
    //   break;
    // case 'readyForPayment':
    //   statusColor = 'text-gray-500';
    //   break;
    // case 'readyForProcessing':
    //   statusColor = 'text-gray-500';
    //   break;
    case 'processing':
      statusColor = 'text-yellow-500';
      break;
    case 'confirmed':
      statusColor = 'text-yellow-500';
      break;
    case 'inElaboration':
      statusColor = 'text-gray-500';
      break;
    case 'elaborated':
      statusColor = 'text-green-500';
      break;
    case 'ready':
      statusColor = 'text-green-500';
      break;
    // -- --
    case 'canceledByUser':
      statusColor = 'text-red-500';
      break;
    case 'canceledByAdmin':
      statusColor = 'text-red-500';
      break;
    default:
      break;
  }

  return statusColor;
};

const tableColumns = [
  {
    id: 'creationDate',
    name: 'Ricevuto il',
    selector: (row: Job) => row.creationDate,
    sortable: true,
    minWidth: '15%',
    cell: (row: Job) => {
      if (!row.creationDate) return '-';
      let date = new Date(row.creationDate);
      let dateString = `${date.toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })} - ${date.toLocaleTimeString()}`;
      return dateString;
    },
  },
  {
    id: 'user',
    name: 'Cliente',
    selector: (row: Job) => row.user.email,
    sortable: true,
    minWidth: '15%',
    cell: (row: Job) => {
      let email = row.user.email;
      return (
        <div style={{ pointerEvents: 'none' }}>
          <p className="text-xs">{email || '-'}</p>
        </div>
      );
    },
  },
  {
    id: 'delivery',
    name: 'Consegna',
    selector: (row: Job) => row.delivery.type,
    sortable: true,
    hide: 'md',
    minWidth: '15%',
    cell: (row: Job) => {
      if (!row.delivery) return 'Sconosciuta!';
      if (row.delivery.type === 'atHome') {
        return (
          <div style={{ pointerEvents: 'none' }}>
            <h4 className="font-bold text-sm">{'Spedizione'}</h4>
          </div>
        );
      }
      return (
        <div style={{ pointerEvents: 'none' }}>
          <h4 className="font-bold text-sm">{'In Negozio'}</h4>
          <p className="text-xs">{row.delivery.address?.name || '-'}</p>
        </div>
      );
    },
  },
  {
    id: 'price',
    // name: 'Prezzo e Crediti',
    name: <div>Prezzo e Crediti</div>,
    selector: (row: Job) => row.finalPrice,
    sortable: true,
    center: true,
    hide: 'md',
    minWidth: '15%',
    cell: (row: Job) => {
      let fee = row.shop?.servicePayments?.actualPercentualFee
        ? numberToEuro((row.shop.servicePayments.actualPercentualFee / 100) * row.finalPrice)
        : '-';
      let feeString = row.jobPayedFromShop ? 'Già versati' : 'Non versati';
      return (
        <div style={{ pointerEvents: 'none' }}>
          <h4 className="font-bold text-sm">{`${numberToEuro(row.finalPrice)}`}</h4>
          <p className="text-xs">{`${fee} ${feeString}`}</p>
        </div>
      );
    },
  },
  // {
  //   name: 'Metodo pagamento',
  //   selector: 'payment.type',
  //   sortable: true,
  //   hide: 'md',
  //   minWidth: '10%',
  //   cell: (row: Job) => {
  //     if (row.payment.type === 'onSite') return 'in Negozio';
  //     if (row.payment.type === 'stripe') return 'OnLine';
  //   },
  // },
  {
    id: 'status',
    name: 'Stato Ordine',
    selector: (row: Job) => row.status,
    minWidth: '15%',
    sortable: true,
    center: true,
    // cell: (row: Job) => {
    //   let color = getStatusTxtColor(row.status);
    //   let statusLabel = getStatusName(row.status);
    //   return (
    //     <div style={{ pointerEvents: 'none' }}>
    //       <h4 className={`font-bold text-sm text-center ${color}`}>{statusLabel}</h4>
    //     </div>
    //   );
    // },
    cell: (row: Job) => (
      <OrderStatusModal status={row.status} deliveryType={row.delivery.type} jobId={row._id} />
    ),
  },
  {
    id: 'payment',
    name: 'Stato pagamento',
    selector: (row: Job) => row.payment.status,
    sortable: true,
    center: true,
    minWidth: '15%',
    style: { borderLeft: '1px solid #eee' },
    cell: (row: Job) => (
      <OrderPaymentStatusModal paymentStatus={row.payment.status} jobId={row._id} />
    ),
  },
  {
    id: 'user_info',
    // name: 'info cliente',
    name: <div>info cliente</div>,
    cell: (row: Job, index: number) => (
      <OrderUserInfoModal userInfo={row.user} delivery={row.delivery} />
    ),
    minWidth: '10%',
    sortable: false,
    center: true,
  },
];

const JOBS_FOR_PAGES = 30;

const OrdersList = () => {
  const navigate = useNavigate();
  const [typeFilter, setTypeFilter] = useState<JobStatus | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<Job[]>([]);
  const [totalJobs, setTotalJobs] = useState<number>(0);
  const [page, setPage] = useState(1);

  const { loading, error, data } = useQuery<JobsData, JobsVariable>(JOBS, {
    variables: {
      shopId: ACTUAL_AUTH.shop || '',
      offset: (page - 1) * JOBS_FOR_PAGES,
      limit: JOBS_FOR_PAGES,
      orderStatuses: [
        'canceledByAdmin',
        'confirmed',
        'errorInProcessing',
        'inElaboration',
        'elaborated',
        'ready',
      ],
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  // ogni volta che cambiano i dati o uno dei filtri
  useEffect(() => {
    if (data?.AdminJobs) {
      log.debug('New data Fetched:');
      log.debug(`fetch: jobs:${data.AdminJobs.jobs.length} count:${data.AdminJobs.count}`);
    }

    let newFilteredOrders: Job[] = data?.AdminJobs.jobs || [];
    // let numOfJobs = data?.AdminJobs.count;

    if (typeFilter) {
      newFilteredOrders = newFilteredOrders.filter(item => item.status === typeFilter);
    }

    setFilteredOrders(newFilteredOrders);

    let TotNumOfJobs = data?.AdminJobs.count;
    if (TotNumOfJobs && TotNumOfJobs !== totalJobs) {
      setTotalJobs(TotNumOfJobs);
    }
  }, [data, typeFilter]);

  if (loading)
    return (
      <div className="w-full h-full flex items-center justify-center py-8">
        <Loading color={'#666'} />
      </div>
    );

  if (error) {
    log.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  return (
    <div className={'relative flex flex-1 flex-col min-w-0 w-full'}>
      <div className={'flex flex-row gap-2 items-center'}>
        <h6 className={'font-semibold text-1xl text-gray-600 py-4 px-2'}>Filtri attivi:</h6>
        <div className={'sm:min-w-[12rem]'}>
          <SelectFilter
            zIndex={998}
            items={FILTER_STATUS}
            placeholder="Stato ordini..."
            emptySelection={true}
            onChange={item => {
              item ? setTypeFilter(item.value as JobStatus) : setTypeFilter(null);
            }}
          />
        </div>
      </div>
      <div
        className={
          'flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white'
        }
      >
        <Table
          columns={tableColumns}
          data={filteredOrders}
          height={'calc(100vh - 240px)'}
          title={'Lista Ordini'}
          filterSelector={'user.email'}
          filterPlaceholder={'Cerca per Email ...'}
          loading={filteredOrders ? false : true}
          pagination
          paginationServer
          paginationPerPage={JOBS_FOR_PAGES}
          paginationTotalRows={totalJobs}
          paginationDefaultPage={page}
          onChangePage={page => {
            log.debug(`change page to ${page}`);
            setPage(page);
          }}
          onRowClick={(row: Job, event) => {
            log.debug(`CLICK on ${row._id}`);
            navigate('/admin/sales/orders/' + row._id);
          }}
        />
      </div>
    </div>
  );
};

export { OrdersList };
