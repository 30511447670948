import React, { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Colors, LOG } from '../../config';

import { SelectFolderColor, SelectFolderCover } from '..';
import { Button, SelectFilter, TextBox } from '../../ui';
import { useFormFields } from '../../hooks/forms';
import { DisplayType, FOLDER_DISPLAY } from '../../stores/queries/shop';

const log = LOG.extend('TREE_VIEW');

interface NewFolderData {
  name?: string;
  description?: string;
  color?: Colors;
  display?: DisplayType;
  pictureFile?: File;
}

type Inputs = 'name' | 'description';

interface AddFolderModalProps {
  onFolderAdd: (folderData: NewFolderData) => void;
}
const AddFolderModal = ({ onFolderAdd }: AddFolderModalProps) => {
  // -- -- --
  // -- STATES/REF --
  // -- -- --
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newFolderData = useRef<NewFolderData>({ color: 'violet' });
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in Inputs]: string }>({
    name: '',
    description: '',
  });
  const [newPictureFile, setNewPictureFile] = useState<File | undefined>();
  const [inputsError, setInputErrors] = useState<{ [key in Inputs]: string }>({
    name: '',
    description: '',
  });

  const resetForm = () => {
    setInputs({
      name: '',
      description: '',
    });
    setInputErrors({
      name: '',
      description: '',
    });
    newFolderData.current = { color: 'violet' };
  };

  const resetError = (key: Inputs) => {
    setInputErrors(oldErrors => {
      return {
        ...oldErrors,
        [key]: '',
      };
    });
  };

  const isFormValid = () => {
    log.debug('CALL isFormValid()');
    let isFormValid = true;

    //timeNewPrice - not empty
    if (!inputs.name) {
      LOG.info('  - inputs.name NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          name: '* Campo obbligatorio',
        };
      });
      isFormValid = false;
    }

    return isFormValid;
  };

  const onChangeCover = (file: File) => {
    setNewPictureFile(file);
    newFolderData.current.pictureFile = file;
  };

  const onRemoveCover = () => {
    setNewPictureFile(undefined);
    newFolderData.current.pictureFile = undefined;
  };

  function closeModal() {
    resetForm();
    setNewPictureFile(undefined);
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <>
      <Button
        className="mx-3"
        size="xs"
        color="green"
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        <i className="fa fa-folder-plus" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l my-2 font-bold uppercase">
                  Nuova Cartella
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">Nome</p>
                </div>
                <TextBox
                  id="name"
                  type="text"
                  value={inputs.name}
                  error={inputsError.name}
                  placeholder={'Nome'}
                  required={true}
                  onChange={event => {
                    newFolderData.current.name = event.target.value;
                    handleInputChange(event);
                    resetError('name');
                  }}
                />

                <div className="mt-2">
                  <p className="text-sm text-gray-500">Descrizione</p>
                </div>
                <TextBox
                  id="description"
                  type="text"
                  maxChar={500}
                  value={inputs.description}
                  placeholder={'Descrizione'}
                  required={true}
                  onChange={event => {
                    newFolderData.current.description = event.target.value;
                    handleInputChange(event);
                  }}
                />

                <div className="mt-2">
                  <p className="text-sm text-gray-500">Visualizzazione</p>
                </div>
                <div className="">
                  <SelectFilter
                    items={FOLDER_DISPLAY}
                    placeholder={'Visualizzazione'}
                    defaultValue={FOLDER_DISPLAY[0]}
                    zIndex={1}
                    onChange={item => {
                      if (item?.value) {
                        newFolderData.current.display = item.value as DisplayType;
                      }
                    }}
                  />
                </div>

                <div className="mt-4">
                  <p className="text-sm text-gray-500">Colore</p>
                </div>

                <SelectFolderColor
                  initialValue={newFolderData.current.color}
                  onChange={color => {
                    newFolderData.current.color = color;
                  }}
                />

                <div className="mt-4 mb-2">
                  <p className="text-sm text-gray-500">Immagine</p>
                </div>
                <SelectFolderCover
                  value={newPictureFile ? URL.createObjectURL(newPictureFile) : undefined}
                  onChange={files => {
                    onChangeCover(files[0]);
                  }}
                  onPressRemove={id => {
                    onRemoveCover();
                  }}
                />

                <div className="mt-4 flex flex-row gap-4">
                  <Button
                    onClick={() => {
                      if (isFormValid()) {
                        log.info('valid form -> upload');
                        onFolderAdd(newFolderData.current);
                        closeModal();
                      } else {
                        log.info('NOT valid form -> NOT upload');
                      }
                    }}
                    color="purple"
                  >
                    Salva
                  </Button>
                  <Button onClick={closeModal} color="red">
                    Annulla
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { AddFolderModal };
