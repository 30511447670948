import React from 'react';

import imgDefault from '../../assets/img/image_not_found.png';
import { EPNode } from '../../stores/queries/shop';

interface GroupElementProps {
  connectDragSource: any;
  node: EPNode;
}
const GroupElement = ({ connectDragSource, node }: GroupElementProps) => {
  const { productGroup } = node;

  return connectDragSource(
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        padding: 20,
      }}
    >
      <div
        className="flex flex-col items-center justify-center shadow-lg rounded-lg"
        style={{
          display: 'inline-block',
          width: 200,
          height: 130,
          paddingTop: 63,
          background:
            'linear-gradient(348deg, rgba(132,126,252,1) 0%, rgba(214,217,254,1) 44%, rgba(232,230,252,1) 100%)',
          color: 'white',
          textAlign: 'left',
        }}
      >
        <h2 className="relative uppercase text-gray-600 text-sm font-bold mx-2 truncate">
          {productGroup?.name || 'Sconosciuto'}
        </h2>
        <h4 className="text-gray-500 uppercase text-xs font-bold mx-2">
          {productGroup?.type || 'Sconosciuto'}
        </h4>
        <p className="text-gray-500 text-xs mx-2 truncate">
          {productGroup?.descriptions?.short || ''}
        </p>
      </div>
      <img
        alt="img"
        src={productGroup?.images.cover.url || imgDefault}
        className="absolute h-auto align-middle border-none rounded-lg shadow-lg"
        style={{
          objectFit: 'cover',
          top: 0,
          left: 40,
          display: 'inline-block',
          width: 160,
          height: 80,
        }}
      />
    </div>,
    { dropEffect: 'copy' }
  );
};

export { GroupElement };
