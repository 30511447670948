import React, { useEffect, useState } from 'react';
import { Job } from '../../../stores/queries/orders';

import { LOG } from '../../../config';
import { getPriorDate } from '../../../libs/utils';

const log = LOG.extend('HOME');

const getJobsStats = (jobs: Job[]) => {
  log.debug('CALL getRevenue()');
  let job30 = 0;
  let jobTot = 0;
  let jobToDo = 0;
  const now = new Date();
  const date30 = getPriorDate(now, 30);

  for (let i = 0; i < jobs.length; i++) {
    if (!jobs[i].creationDate) {
      log.warn(`job[${jobs[i]._id}] do NOT have creationDate`);
      continue;
    }
    //TODO: filtare ordini 'canceledByUser lato BackEnd
    if (
      jobs[i].status === 'canceledByUser' ||
      jobs[i].status === 'initializing' ||
      jobs[i].status === 'readyForPayment'
    ) {
      // log.warn(`job[${jobs[i]._id}] do NOT have creationDate`);
      continue;
    }

    if (new Date(jobs[i].creationDate) > date30) {
      job30 += 1;
    }
    if (jobs[i].status === 'confirmed') {
      jobToDo += 1;
    }
    jobTot += 1;
  }

  return {
    jobToDo,
    job30,
    jobTot,
  };
};

interface OrdersHomeProps {
  jobs: Job[];
}
const OrdersHome = ({ jobs }: OrdersHomeProps) => {
  const [jobToDo, setJobToDo] = useState<number>(0);
  const [jobLast30Days, setJobLast30Days] = useState<number>(0);
  const [jobTot, setJobTot] = useState<number>(0);

  useEffect(() => {
    if (jobs && jobs.length) {
      const stats = getJobsStats(jobs);
      setJobToDo(stats.jobToDo);
      setJobLast30Days(stats.job30);
      setJobTot(stats.jobTot);
    } else {
      log.warn('OrdersHome - jobs.length NOT found');
    }
  }, []);

  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Da Elaborare</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{jobToDo || '-'}</h3>
      </div>

      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Ricevuti - Ultimi 30 Giorni</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{jobLast30Days || '-'}</h3>
      </div>

      <div className="flex flex-col">
        <h6 className="text-gray-400 text-sm mt-1 mb-2 font-bold">Ricevuti - Totali</h6>
        <h3 className="text-gray-600 text-xl mb-2 font-bold">{jobTot || '-'}</h3>
      </div>
    </div>
  );
};

export { OrdersHome };
