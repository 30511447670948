import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Shop,
  SHOP,
  updateShopData,
  updateShopVariables,
  UPDATE_SHOP,
} from '../../../stores/queries/shop';
import { ACTUAL_AUTH } from '../../../stores/db/auth';

import { LOG } from '../../../config';

import { EditBox, Loading } from '../../../ui';
import { isNumber, transformApolloData } from '../../../libs/utils';

const DeliveryView = () => {
  // -- -- --
  // -- STATTES --
  // -- -- --
  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  // -- -- --
  // -- QUERY --
  // -- -- --
  const { error, data } = useQuery<{ AdminShop: Shop }, { _id: string }>(SHOP, {
    variables: { _id: ACTUAL_AUTH.shop || '' },
    fetchPolicy: 'cache-and-network',
  });

  // -- -- --
  // -- MUTATIONS --
  // -- -- --
  const [updateShop, { error: updateShopError }] = useMutation<updateShopData, updateShopVariables>(
    UPDATE_SHOP
  );

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const update = (newShop: updateShopVariables['shop']) => {
    LOG.info('CALL updateProduct');

    let cleanNewShop = transformApolloData(newShop);
    updateShop({
      variables: {
        _id: ACTUAL_AUTH.shop || '',
        shop: cleanNewShop,
      },
    });
  };

  if (error) {
    LOG.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${error}`}
        </div>
      </div>
    );
  }

  if (updateShopError) {
    LOG.error(updateShopError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${updateShopError}`}
        </div>
      </div>
    );
  }

  if (!data?.AdminShop) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="w-full h-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      </div>
    );
  }

  const { atHome: deliveryAtHome } = data.AdminShop.deliveryInfo;
  const { atShop: deliveryAtShop } = data.AdminShop.deliveryInfo;

  return (
    <div className=" flex flex-row  w-full flex-wrap">
      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Ritiro In Negozio</h6>
        <EditBox
          editable={true}
          value={deliveryAtShop.time ? deliveryAtShop.time.toString() : ''}
          label="Tempo di consegna - [giorni]"
          unit="giorni"
          isNumber
          maxChar={50}
          onEnterEdit={() => {
            setActiveEdit(true);
          }}
          onExitEdit={() => {
            setActiveEdit(false);
          }}
          onPressSave={value => {
            LOG.info(`update deliveryAtShop.time with [${value}]`);
            update({
              deliveryInfo: {
                atHome: deliveryAtHome,
                atShop: {
                  ...deliveryAtShop,
                  time: Number(value) || null,
                },
              },
            });
          }}
          isInputValid={isNumber}
          inputInvalidMsg="* Ammessi solo numeri"
        />
        <EditBox
          editable={true}
          value={deliveryAtShop.cost ? deliveryAtShop.cost.toString() : ''}
          label="costo"
          unit="€"
          isNumber
          maxChar={10}
          onEnterEdit={() => {
            setActiveEdit(true);
          }}
          onExitEdit={() => {
            setActiveEdit(false);
          }}
          onPressSave={value => {
            LOG.info(`update deliveryAtShop.cost with [${value}]`);
            update({
              deliveryInfo: {
                atHome: deliveryAtHome,
                atShop: {
                  ...deliveryAtShop,
                  cost: Number(value) || null,
                },
              },
            });
          }}
          isInputValid={isNumber}
          inputInvalidMsg="* Ammessi solo numeri"
        />
        {activeEdit && (
          <div
            className=" w-full h-full absolute top-0 left-0 rounded-2xl"
            style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
          ></div>
        )}
      </div>

      <div className="relative flex flex-auto flex-col  mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 mt-4 mx-4 p-4">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Spedizione A Casa</h6>
        <EditBox
          editable={true}
          value={deliveryAtHome.timings[0]?.time ? deliveryAtHome.timings[0].time.toString() : ''}
          label="Tempo di consegna - [giorni]"
          unit="giorni"
          isNumber
          maxChar={50}
          onEnterEdit={() => {
            setActiveEdit(true);
          }}
          onExitEdit={() => {
            setActiveEdit(false);
          }}
          onPressSave={value => {
            LOG.info(`update deliveryAtHome.timings[0].time with [${value}]`);
            update({
              deliveryInfo: {
                atShop: deliveryAtShop,
                atHome: {
                  ...deliveryAtHome,
                  timings: [
                    {
                      country: 'it',
                      time: Number(value) || null,
                    },
                  ],
                },
              },
            });
          }}
          isInputValid={isNumber}
          inputInvalidMsg="* Ammessi solo numeri"
        />
        <EditBox
          editable={true}
          value={deliveryAtHome.costs[0]?.cost ? deliveryAtHome.costs[0].cost.toString() : ''}
          label="costo"
          unit="€"
          isNumber
          maxChar={10}
          onEnterEdit={() => {
            setActiveEdit(true);
          }}
          onExitEdit={() => {
            setActiveEdit(false);
          }}
          onPressSave={value => {
            LOG.info(`update deliveryAtHome.costs[0].cost with [${value}]`);
            update({
              deliveryInfo: {
                atShop: deliveryAtShop,
                atHome: {
                  ...deliveryAtHome,
                  costs: [
                    {
                      country: 'it',
                      cost: Number(value) || null,
                    },
                  ],
                },
              },
            });
          }}
          isInputValid={isNumber}
          inputInvalidMsg="* Ammessi solo numeri"
        />
        {activeEdit && (
          <div
            className=" w-full h-full absolute top-0 left-0 rounded-2xl"
            style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
          ></div>
        )}
      </div>
    </div>
  );
};

export { DeliveryView };
