import axios from 'axios';
import { ENV, LOG } from '../../config';
import { base64ToFile, objectToFile } from '../../libs/utils';

const log = LOG.extend('API');

type AddAttachmentResData = {
  success: boolean;
  fileUrl?: string;
  errors?: string[];
};

const uploadFileForAdmin = async (
  file: File,
  shopId: string,
  jobId: string,
  wizardId: string,
  orderKey: string,
  attachmentKey: string,
  scope: 'attachment' | 'history',
  dpi?: number,
) => {
  if (!dpi) dpi = 0;
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('shopId', shopId);
    formData.append('jobId', jobId);
    formData.append('wizardId', wizardId);
    formData.append('orderKey', orderKey);
    formData.append('attachmentKey', attachmentKey);
    formData.append('scope', scope);
    formData.append('dpi', String(dpi))

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const { data } = await axios.post<AddAttachmentResData>(
      ENV.editor.storage + '/uploadFileForAdmin',
      formData,
      config
    );

    if (!data.success) {
      log.error('uploadFileForAdmin() - something went wrong:');
    }

    return data;
  } catch (err) {
    log.error(JSON.stringify(err));
    let res: AddAttachmentResData = {
      success: false,
      errors: [JSON.stringify(err)],
    };
    return res;
  }
};

const uploadImageToStorage = async (
  imageBase64: string,
  shopId: string,
  jobId: string,
  wizardId: string,
  orderKey: string,
  attachmentKey: string,
  dpi: number,
) => {
  log.debug('CALL uploadImageToStorage()');
  try {
    const fileName = `${jobId}_Image_${Date.now()}.jpg`;
    const imageFile = await base64ToFile(imageBase64, fileName);
    const data = uploadFileForAdmin(imageFile,shopId,jobId,wizardId,orderKey,attachmentKey, 'attachment', dpi);
    return data;
  } catch (error) {
    log.error(JSON.stringify(error));
    let res: AddAttachmentResData = {
      success: false,
      errors: [JSON.stringify(error)],
    };
    return res;
  }
}

const uploadHistoryToStorage = async (
  history: Object,
  shopId: string,
  jobId: string,
  wizardId: string,
  orderKey: string,
  attachmentKey: string,
) => {
  log.debug('CALL uploadHistoryToStorage()');
  try {
    const fileName = `${jobId}_History_${Date.now()}.json`;
    const historyFile = objectToFile(history, fileName);
    const data = uploadFileForAdmin(historyFile,shopId,jobId,wizardId,orderKey,attachmentKey, 'history');
    return data;
  } catch (error) {
    log.error(JSON.stringify(error));
    let res: AddAttachmentResData = {
      success: false,
      errors: [JSON.stringify(error)],
    };
    return res;
  }
}

export { uploadImageToStorage, uploadHistoryToStorage };
