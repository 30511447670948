import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOG } from '../../config';

import {
  createJobZipData,
  createJobZipVariables,
  CREATE_JOB_ZIP,
  Job,
} from '../../stores/queries/orders';

import { Button, EditBox, Loading } from '../../ui';
import { OrderSingleProduct } from './OrderSingleProduct';

function downloadURI(uri: string, name: string) {
  let link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const log = LOG.extend('ORDER_DETAIL');

interface OrderDetailProps {
  job: Job;
  refetch: () => void;
}
const OrderDetail = ({ job, refetch }: OrderDetailProps) => {
  const [loadDownload, setLoadDownload] = useState(false);

  useEffect(() => {
    if (job.status === 'inElaboration' && !loadDownload) {
      log.debug('useEffect Job:inElaboration -> setLoadDownload(true)');
      setLoadDownload(true);
    } else if (job.status === 'elaborated' && loadDownload) {
      log.debug('useEffect Job:elaborated -> setLoadDownload(false)');
      setLoadDownload(false);
    }
  }, [job.status]);

  // -- -- --
  // -- MUTATIONS --
  // -- -- --

  const [createJobZip, { error: createJobZipError, data: createJobZipData }] = useMutation<
    createJobZipData,
    createJobZipVariables
  >(CREATE_JOB_ZIP);

  const lastUpdate = job.lastUpdateDate
    ? new Date(job.lastUpdateDate).toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : new Date(job.creationDate).toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
  const paymentMethod = job.payment.type === 'onSite' ? 'in Negozio' : 'OnLine';
  const paymentStatus = job.payment.status === 'payed' ? 'avvenuto' : 'in attesa';

  let deliveryMethod = 'Sconosciuto';
  if (job.delivery.type === 'atShop') deliveryMethod = 'In Negozio';
  let disableDownload = true;
  if (job.downloadUrl && job.jobPayedFromShop) {
    disableDownload = false;
  }

  const processDownload = async () => {
    log.debug('CALL processDownload()');

    // se il job è già stato processato scarico direttamente
    if (job.downloadUrl) {
      downloadURI(job.downloadUrl, job._id);
      log.debug(`  - Download job ${job._id}`);
      return;
    }
    // controllo che non ci sia la possibilita di generare un nuovo zip se l'elaborazione è ancora in corso
    if (loadDownload) {
      log.warn('  - job is processing now can not do it again!');
      return;
    }

    // imposta loading e nascondi la possibilità di scaricare
    setLoadDownload(true);

    // chiamo la mutation per creare il job zip
    createJobZip({
      variables: {
        jobId: job._id,
      },
    });

    // essendoci un polling ogni 2 sec quando cambia lo stato del job
    // 'confirmed' -> 'inElaboration' -> 'elaborated
    // lo useEffect riabilita la possibilità di scaricare
  };

  // -- -- -- --
  // -- RENDER --
  // -- -- -- --

  const renderDeliveryAdress = () => {
    if (!job.delivery?.type) {
      return <EditBox editable={false} value={'Sconosciuta'} label="Consegna" />;
    }

    if (job.delivery.type === 'atShop') {
      let contacts = `${job.delivery.business?.denomination || ''}\n${
        job.delivery.business?.street || ''
      }\n${job.delivery.business?.zipCode || ''} - ${job.delivery.business?.city || ''}`;
      return <EditBox editable={false} value={contacts} label="Ritiro In Negozio" />;
    }

    if (job.delivery.type === 'atHome') {
      let address = `${job.delivery.address?.name}\n${job.delivery.address?.street}\n${job.delivery.address?.zipCode} - ${job.delivery.address?.city}\n${job.delivery.address?.province} - ${job.delivery.address?.state}`;
      return <EditBox editable={false} value={address} label="Spedizione" />;
    }
  };

  const renderSingleProducts = () => {
    if (!job.orders || !job.orders.length) {
      log.error('renderSingleProducts() - there is NOT job.orders || job.orders.length');
      return;
    }

    return job.orders.map(order => {
      return (
        <OrderSingleProduct
          key={order._id}
          order={order}
          jobId={job._id}
          isProcessing={loadDownload}
          refetch={refetch}
        />
      );
    });
  };

  const renderDownloadButton = () => {
    if (createJobZipError) {
      return (
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${createJobZipError}`}
        </div>
      );
    }
    if (createJobZipData?.AdminCreateJobZip?.success === false) {
      let message = 'qualcosa è andato storto';
      if (createJobZipData?.AdminCreateJobZip?.errors?.length) {
        message = createJobZipData.AdminCreateJobZip.errors[0];
        console.log('message', message);
      }
      return (
        <Button size="xs" disabled={true} color="red" onClick={() => {}}>
          {`Errore: ${message}`}
        </Button>
      );
    }
    if (loadDownload) {
      return (
        <Button size="xs" disabled={true} color="gray">
          <div className="flex flex-row items-center">
            <Loading height={15} color="white" />
            <p className="ml-2">Creazione Archivio Ordine ...</p>
          </div>
        </Button>
      );
    }

    if (!job.downloadUrl) {
      return (
        <Button
          size="xs"
          color="yellow"
          onClick={() => {
            processDownload();
          }}
        >
          Prepara Archivio Ordine
        </Button>
      );
    }

    if (job.downloadUrl) {
      return (
        <Button
          size="xs"
          color="purple"
          onClick={() => {
            processDownload();
          }}
        >
          Scarica Archivio Ordine
        </Button>
      );
    }
  };

  return (
    <div
      className="relative break-words w-full bg-gray-50 rounded-2xl border-0 flex flex-col"
      style={{ height: 'calc(100vh - 200px)' }}
    >
      <div className="flex-auto px-4 lg:px-10 pb-10 overflow-auto w-full h-full">
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-1/2">
            <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
              Riepilogo Ordine
            </h6>
            <div className="flex flex-wrap">
              <EditBox editable={false} value={'-'} label="Stato ordine" />
              <EditBox
                editable={false}
                value={new Date(job.creationDate).toLocaleDateString(undefined, {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })}
                label="Ricevuto il"
              />

              <EditBox editable={false} value={lastUpdate} label="Ultima modifica" />
              <EditBox editable={false} value={paymentMethod} label="Metodo di pagamento" />
              {renderDeliveryAdress()}
            </div>
          </div>
          <div className="w-1/2">
            <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">
              Ordine effettuato da
            </h6>
            <div className="flex flex-col flex-wrap">
              <EditBox editable={false} value={job.user?.username || '-'} label="Username" />
              <EditBox editable={false} value={job.user?.email || '-'} label="E-mail" />
              <div className="mt-4">{renderDownloadButton()}</div>
            </div>
          </div>
        </div>

        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Prodotti ordinati</h6>
        <div className={'flex flex-1 flex-col break-words w-full mb-6'}>
          {renderSingleProducts()}
        </div>
      </div>
    </div>
  );
};

export { OrderDetail };
