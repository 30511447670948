import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { addDaysToDate, numberToEuro, transformApolloData } from '../../libs/utils';
import {
  Discount,
  MAX_PRODUCT_QUANTITY,
  MIN_PRODUCT_QUANTITY,
  Prices,
  updateProductData,
  updateProductVariables,
  UPDATE_PRODUCT,
} from '../../stores/queries/product';

import { LOG } from '../../config';

import { EditBoxNumber, EditBoxText, TableInCard } from '../../ui';
import { ProductPriceAddModal, ProductPriceEditModal, ProductPriceRemoveModal } from '..';

const log = LOG.extend('PRODUCT_PRICES');

const getMaxQuantity = (prices: Prices | null) => {
  let discounts = prices?.quantityDiscounts || [];
  let max = discounts.length
    ? discounts?.[discounts.length - 1]?.quantityTo || MAX_PRODUCT_QUANTITY
    : MAX_PRODUCT_QUANTITY;
  return max;
};

interface ProductPricesprops {
  _id: string;
  prices: Prices | null;
}
const ProductPrices = ({ _id, prices }: ProductPricesprops) => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const [minQuantity, setMinQuantity] = useState<number>(
    prices?.quantityDiscounts?.[0]?.quantityFrom || MIN_PRODUCT_QUANTITY
  );
  const [maxQuantity, setMaxQuantity] = useState<number>(getMaxQuantity(prices));
  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  const tableColumns = [
    {
      id: 'quantityFrom',
      name: 'Qtà da',
      selector: (row: Discount) => row.quantityFrom,
      sortable: false,
      center: true,
      minWidth: '10%',
    },
    {
      id: 'quantityTo',
      name: 'Qtà a',
      selector: (row: Discount) => row.quantityTo,
      sortable: false,
      center: true,
      minWidth: '10%',
    },
    {
      id: 'price',
      name: 'Prezzo Qtà',
      cell: (row: Discount) => {
        if (!row.newPrice) return '-';
        return numberToEuro(row.newPrice);
      },
      sortable: false,
      center: true,
      minWidth: '10%',
      style: { borderRight: '1px solid #eee' },
    },
    {
      id: 'promoFrom',
      name: 'In Promo da',
      sortable: false,
      center: true,
      cell: (row: Discount) => {
        if (!row.timeDiscount?.from) return '-';
        let date = new Date(row.timeDiscount.from);
        return date.toLocaleDateString(undefined, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
      },
      minWidth: '20%',
      hide: 'md',
    },
    {
      id: 'promoTo',
      name: 'In Promo fino a',
      cell: (row: Discount) => {
        if (!row.timeDiscount?.to) return '-';
        let date = new Date(row.timeDiscount.to);
        date = addDaysToDate(date, -1);
        return date.toLocaleDateString(undefined, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
      },
      sortable: false,
      center: true,
      minWidth: '20%',
      hide: 'md',
    },
    {
      id: 'promoPrice',
      name: 'Prezzo Promo',
      cell: (row: Discount) => {
        if (!row.timeDiscount?.newPrice) return '-';
        return (
          <div style={{ pointerEvents: 'none' }}>
            <h4 className="font-bold text-sm">{`${row.timeDiscount.newPrice} €`}</h4>
          </div>
        );
      },
      sortable: false,
      center: true,
      minWidth: '10%',
    },
    {
      id: 'option',
      name: 'opzioni',
      cell: (row: Discount, index: number) => {
        if (!index)
          return (
            <div className="flex flex-row gap-2">
              <ProductPriceEditModal
                discount={row}
                discountIndex={index}
                onEdit={updateDiscount}
                quantityBefore={getQuantityFromBefore(index)}
                quantityAfter={getQuantityFromAfter(index)}
                isFirstRow={!index}
              />
            </div>
          );
        return (
          <div className="flex flex-row gap-2">
            <ProductPriceEditModal
              discount={row}
              discountIndex={index}
              onEdit={updateDiscount}
              quantityBefore={getQuantityFromBefore(index)}
              quantityAfter={getQuantityFromAfter(index)}
              isFirstRow={!index}
            />
            <ProductPriceRemoveModal
              discount={row}
              discountIndex={index}
              onDelete={deleteDiscount}
            />
          </div>
        );
      },
      minWidth: '20%',
      sortable: false,
      center: true,
      style: { borderLeft: '1px solid #eee' },
    },
  ];

  // -- -- --
  // -- MUTATIONS --
  // -- -- --
  const [updateProduct, { error: updateProductError, loading: updateProductLoading }] = useMutation<
    updateProductData,
    updateProductVariables
  >(UPDATE_PRODUCT);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const getQuantityFromBefore = (index: number) => {
    if (!prices?.quantityDiscounts?.length || index < 1) return;
    return prices.quantityDiscounts[index - 1]?.quantityFrom || undefined;
  };

  const getQuantityFromAfter = (index: number) => {
    if (!prices?.quantityDiscounts?.length || index === prices.quantityDiscounts.length - 1) return;
    return prices.quantityDiscounts[index + 1]?.quantityFrom || undefined;
  };

  const update = (newProduct: updateProductVariables['product']) => {
    log.info('CALL updateProduct');

    let cleanNewProduct = transformApolloData(newProduct);
    updateProduct({
      variables: {
        _id: _id,
        product: cleanNewProduct,
      },
    });
  };

  const updateDiscount = (index: number, newDiscount: Discount) => {
    log.debug('CALL updateDiscount()');

    if (!prices) {
      log.error('  - prices NOT found');
      return;
    }
    if (!prices.quantityDiscounts[index]) {
      log.error(`  - quantityDiscounts ${index} NOT found in prices`);
      return;
    }

    log.info(`  - update quantityDiscounts:${index}`);

    let discounts = [...prices.quantityDiscounts];

    // modifica fascia corrente
    discounts[index] = newDiscount;

    // modifica quantityTo fascia precedente
    if (index >= 1 && discounts[index - 1]?.quantityTo && newDiscount.quantityFrom) {
      log.info(`  - update quantityDiscounts:${index - 1}.quantityTo`);
      discounts[index - 1] = {
        ...discounts[index - 1],
        quantityTo: newDiscount.quantityFrom - 1,
      };
    }

    // modifica minQuantity e maxQuantity se serve
    if (index === 0 && newDiscount.quantityFrom && newDiscount.quantityFrom !== minQuantity) {
      log.info(`  - set new MIN quantity to:${newDiscount.quantityFrom}`);
      setMinQuantity(newDiscount.quantityFrom);
    }
    if (
      index === discounts.length - 1 &&
      newDiscount.quantityTo &&
      newDiscount.quantityTo !== maxQuantity
    ) {
      log.info(`  - set new MAX quantity to:${newDiscount.quantityTo}`);
      setMaxQuantity(newDiscount.quantityTo);
    }

    let newPrices: Prices = {
      ...prices,
      quantityDiscounts: discounts,
    };

    update({
      prices: newPrices,
    });
  };

  const addDiscount = (newDiscount: Discount) => {
    log.debug('CALL addDiscount()');

    if (!prices) {
      log.error('  - prices NOT found');
      return;
    }

    let discounts = [...prices.quantityDiscounts];

    let newQuantityFrom = newDiscount.quantityFrom;
    if (!newQuantityFrom) {
      log.error('  - newDiscount.quantityFrom NOT found');
      return;
    }

    // ignore if outside quantity limits
    if (newQuantityFrom <= minQuantity) {
      log.info('  - newDiscount.quantityFrom < MIN_PRODUCT_QUANTITY. ignore newQuantity');
      return;
    }
    if (newQuantityFrom >= maxQuantity) {
      log.info('  - newDiscount.quantityFrom > MAX_PRODUCT_QUANTITY. ignore newQuantity');
      return;
    }

    let isInserted = false;
    // evito di inserire una riga al primo posto
    for (let i = 1; i < discounts.length; i++) {
      let oldQuantityFrom = discounts[i].quantityFrom;
      if (!oldQuantityFrom) {
        log.error(`  - missing quantityFrom @ ${i}`);
        return;
      }

      // - SE DEVO INSSERIRE UNA NUOVA RIGA IN MEZZO
      if (newQuantityFrom < oldQuantityFrom) {
        log.info(`  - insert new quantity before ${oldQuantityFrom} @index${i}`);
        // diminuisco quantityTo della riga precedente
        discounts[i - 1] = {
          ...discounts[i - 1],
          quantityTo: newQuantityFrom - 1,
        };
        // imposto il quantityTo della riga da inserire
        let newQuantityTo = discounts[i].quantityFrom;
        if (newQuantityTo) {
          newDiscount.quantityTo = newQuantityTo - 1;
        }
        // inserisco la nuova riga
        discounts.splice(i, 0, newDiscount);
        isInserted = true;
        break;
      }
    }

    // - SE DEVO INSSERIRE UNA NUOVA RIGA IN FONDO
    if (!isInserted) {
      // controllo che non sia la stessa quantità
      if (discounts.length && newQuantityFrom === discounts[discounts.length - 1].quantityFrom) {
        log.warn(`  - can not push new discount. new quantityFrom == old quantityFrom`);
        return;
      }
      log.info(`  - push new quantity @last`);

      // diminuisco quantityTo della riga precedente
      if (discounts.length && discounts.length >= 1) {
        discounts[discounts.length - 1] = {
          ...discounts[discounts.length - 1],
          quantityTo: newQuantityFrom - 1,
        };
      }
      // imposto il quantityTo della riga da inserire
      newDiscount.quantityTo = maxQuantity;
      // inserisco la nuova riga
      discounts.push(newDiscount);
    }

    let newPrices: Prices = {
      ...prices,
      quantityDiscounts: discounts,
    };

    update({
      prices: newPrices,
    });
  };

  const deleteDiscount = (index: number) => {
    log.debug('CALL deleteDiscount');

    if (!prices) {
      log.error('  - prices NOT found');
      return;
    }
    if (!prices.quantityDiscounts[index]) {
      log.error(`  - quantityDiscounts ${index} NOT found in prices`);
      return;
    }

    log.info(`  - delete quantityDiscounts:${index}`);

    let discounts = [...prices.quantityDiscounts];
    discounts.splice(index, 1);

    // cambio quantityTo della riga precedente
    let newQuantityFrom = discounts[index]?.quantityFrom;
    if (!newQuantityFrom) {
      log.info(`  - update quantityDiscounts:${index - 1}.quantityTo to maxQuantity`);
      discounts[index - 1] = {
        ...discounts[index - 1],
        quantityTo: maxQuantity,
      };
    } else if (index >= 1 && newQuantityFrom) {
      log.info(`  - update quantityDiscounts:${index - 1}.quantityTo next quantityFrom`);
      discounts[index - 1] = {
        ...discounts[index - 1],
        quantityTo: newQuantityFrom - 1,
      };
    }

    let newPrices: Prices = {
      ...prices,
      quantityDiscounts: discounts,
    };

    update({
      prices: newPrices,
    });
  };

  const updateBasePriceLabel = (value?: string) => {
    if (!prices) {
      log.error('  - prices NOT found');
      return;
    }
    const newLabel = value || null;
    const newPrices: Prices = {
      ...prices,
      basePriceLabel: newLabel,
    };
    log.info(`update product.prices.basePriceLabel with [${newLabel}]`);
    update({
      prices: newPrices,
    });
  };

  const updateBasePrice = (value?: number) => {
    if (!prices) {
      log.error('  - prices NOT found');
      return;
    }

    const newPrices: Prices = {
      ...prices,
      basePrice: value,
    };
    log.info(`update product.prices.basePrice with [${value}]`);
    update({
      prices: newPrices,
    });
  };
  const updateBasePriceMaxQnt = (value?: number) => {
    if (!prices) {
      log.error('  - prices NOT found');
      return;
    }

    const newPrices: Prices = {
      ...prices,
      basePriceMaxQnt: value,
    };
    log.info(`update product.prices.basePriceMaxQnt with [${value}]`);
    update({
      prices: newPrices,
    });
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  const renderBasePriceSection = () => {
    return (
      <div className="flex flex-1 flex-row gap-4 break-words w-full mb-6 p-2 bg-gray-100 rounded-2xl border-0">
        <EditBoxText
          editable={true}
          onEnterEdit={() => {
            setActiveEdit(true);
          }}
          onExitEdit={() => {
            setActiveEdit(false);
          }}
          label="nome"
          value={prices?.basePriceLabel || ''}
          maxChar={30}
          onPressSave={updateBasePriceLabel}
        />
        <EditBoxNumber
          editable={true}
          onEnterEdit={() => {
            setActiveEdit(true);
          }}
          onExitEdit={() => {
            setActiveEdit(false);
          }}
          label="costo"
          value={prices?.basePrice || undefined}
          isCurrency
          isPositive
          maxChar={6}
          onPressSave={updateBasePrice}
        />
        <EditBoxNumber
          editable={true}
          onEnterEdit={() => {
            setActiveEdit(true);
          }}
          onExitEdit={() => {
            setActiveEdit(false);
          }}
          value={prices?.basePriceMaxQnt || undefined}
          label="non Applicare sopra a:"
          unit="prodotti"
          isInteger
          isPositive
          onPressSave={updateBasePriceMaxQnt}
        />
      </div>
    );
  };

  if (!prices) {
    log.warn(`prices NOT found for product ${_id}`);
    return (
      <div
        className="relative break-words w-full bg-gray-50 rounded-2xl border-0 flex flex-col"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          Ops - C'è stato un problema. Prezzi non disponibili
        </div>
      </div>
    );
  }

  return (
    <div
      className="relative break-words w-full bg-gray-50 rounded-2xl border-0 flex flex-col"
      style={{ height: 'calc(100vh - 200px)' }}
    >
      <div className="flex-col px-4 lg:px-10 pb-10 overflow-auto w-full h-full">
        <h4 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">COSTO FISSO</h4>
        {renderBasePriceSection()}
        <h4 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">LISTINO</h4>
        <div className={'flex flex-1 flex-col break-words w-full mb-6'}>
          <TableInCard
            columns={tableColumns}
            data={prices.quantityDiscounts}
            height={'calc(100vh - 240px)'}
            loading={prices.quantityDiscounts ? false : true}
            defaultSortInvert={true}
            centerHeadCells={true}
          />
        </div>
        <ProductPriceAddModal
          minQuantity={minQuantity}
          maxQuantity={maxQuantity}
          onAdd={addDiscount}
        />
        {activeEdit && (
          <div
            className="w-full h-full absolute top-0 left-0 rounded-b-2xl"
            style={{ backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 1 }}
          ></div>
        )}
      </div>
    </div>
  );
};

export { ProductPrices };
