import React, { useState } from 'react';

import imgDefault from '../../assets/img/image_not_found.png';
import { LOG, theme } from '../../config';

import { useMutation } from '@apollo/client';
import {
  Product,
  UPDATE_PRODUCT,
  updateProductData,
  updateProductVariables,
  TYPOLOGIES,
  ADD_FILE,
  addFilesData,
  addFilesVariables,
  Wizard,
} from '../../stores/queries/product';

import { EditBox, EditBoxNumber, EditBoxText, EditImage, MySwitch } from '../../ui';
import { resizeImage, transformApolloData } from '../../libs/utils';

interface WizardDetailProps {
  key: any;
  wizardInput: Wizard;
  index: number;
  numOfWizards: number;
  productType: Product['type'];
  setActiveEdit: (isEditing: boolean) => void;
  updateWizard: (wizard: Wizard, index: number, files?: updateProductVariables['files']) => void;
}
// const WizardDetail = (props: WizardDetailProps) => {
const WizardDetail = ({
  wizardInput,
  index,
  numOfWizards,
  productType,
  setActiveEdit,
  updateWizard,
}: WizardDetailProps) => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const [wizard, setWizard] = useState<Wizard>(wizardInput);

  let widthMM = wizard.sizes?.width;
  let widthPX: number | undefined = undefined;
  if (widthMM) {
    widthPX = Math.ceil((widthMM / 25.4) * 300);
  }
  let heightMM = wizard.sizes?.height;
  let heightPX: number | undefined = undefined;
  if (heightMM) {
    heightPX = Math.ceil((heightMM / 25.4) * 300);
  }
  // -- -- --
  // -- MUTATIONS --
  // -- -- --

  const [addFile, { error: addFileError, loading: addFileLoading, data: fileData }] = useMutation<
    addFilesData,
    addFilesVariables
  >(ADD_FILE);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const updateWizardSize = (type: 'height' | 'width', dim: number) => {
    LOG.debug('CALL updateWizardSize()');
    if (typeof dim !== 'number') {
      LOG.warn(` - [${dim}] is invalid dimension - do NOT update`);
      return;
    }
    if (!wizard.sizes) {
      LOG.error(` - product.wizards[${index}] do NOT has size - do NOT update`);
      return;
    }

    let sizes = {
      height: wizard.sizes?.height || 0,
      width: wizard.sizes?.width || 0,
    };
    sizes[type] = dim;

    let newWizard = {
      ...wizard,
      sizes: sizes,
    };

    updateWizard(newWizard, index);

    LOG.info(` - update sizes.[${type}] to [${dim}]mm`);
    setWizard(wizard => {
      return {
        ...wizard,
        sizes: sizes,
      };
    });
  };

  const updateBorderDimension = (
    border: 'top' | 'bottom' | 'right' | 'left',
    dim: string | number | undefined
  ) => {
    LOG.debug('CALL updateBorderDimension()');
    if (typeof dim !== 'number') {
      LOG.warn(` - [${dim}] is invalid dimension - do NOT update`);
      return;
    }

    let borders = {
      top: {
        size: wizard.borders?.top.size || 0,
        color: wizard.borders?.top.color || '#FFFFFF',
      },
      bottom: {
        size: wizard.borders?.bottom.size || 0,
        color: wizard.borders?.bottom.color || '#FFFFFF',
      },
      right: {
        size: wizard.borders?.right.size || 0,
        color: wizard.borders?.right.color || '#FFFFFF',
      },
      left: {
        size: wizard.borders?.left.size || 0,
        color: wizard.borders?.left.color || '#FFFFFF',
      },
    };

    borders[border].size = dim;

    let newWizard = {
      ...wizard,
      borders: borders,
    };

    updateWizard(newWizard, index);

    LOG.info(` - update border[${border}] to [${dim}]`);
    setWizard(wizard => {
      return {
        ...wizard,
        borders: borders,
      };
    });
  };

  const updateFrameDimension = (dim: string | number | undefined) => {
    LOG.debug('CALL updateFrameDimension()');
    if (typeof dim !== 'number') {
      LOG.warn(` - [${dim}] is invalid dimension - do NOT update`);
      return;
    }

    let newFrame: Wizard['frame'] = {
      ...wizard.frame,
      thickness: {
        size: dim,
      },
    };

    let newWizard = {
      ...wizard,
      frame: newFrame,
    };

    updateWizard(newWizard, index);

    LOG.info(` - update frame dimension to [${dim}]mm`);
    setWizard(wizard => {
      return {
        ...wizard,
        frame: newFrame,
      };
    });
  };

  const updateFrameMode = (mode: 'mirrored' | 'colored', value: boolean) => {
    LOG.debug('CALL updateFrameMode()');

    let newFrame: Wizard['frame'] = undefined;

    if (mode === 'mirrored' && wizard.frame?.mode?.mirrored !== value) {
      newFrame = {
        ...wizard.frame,
        mode: {
          mirrored: value,
          colored: wizard.frame?.mode?.colored,
          normal: wizard.frame?.mode?.normal,
        },
      };
    }
    if (mode === 'colored' && wizard.frame?.mode?.colored !== value) {
      newFrame = {
        ...wizard.frame,
        mode: {
          colored: value,
          mirrored: wizard.frame?.mode?.mirrored,
          normal: wizard.frame?.mode?.normal,
        },
      };
    }
    if (!newFrame) {
      LOG.debug('  - nothing change. do NOT update wizard');
      return;
    }

    let newWizard = {
      ...wizard,
      frame: newFrame,
    };

    updateWizard(newWizard, index);

    LOG.info(` - update frame mode[${mode}] to ${value}`);
    setWizard(wizard => {
      return {
        ...wizard,
        frame: newFrame,
      };
    });
  };

  const updateLabel = (label: string | undefined) => {
    LOG.debug('CALL updateLabel()');

    let newWizard = {
      ...wizard,
      label: label,
    };

    updateWizard(newWizard, index);

    LOG.info(` - update label to ${label}`);
    setWizard(wizard => {
      return {
        ...wizard,
        label: label,
      };
    });
  };

  const updateWizardMask = async (type: 'cut' | 'safety', image: File) => {
    LOG.debug('CALL updateWizardMask()');

    let cutMask = {
      _id: wizard.masks?.cut?._id || null,
      url: wizard.masks?.cut?.url || null,
    };
    let safetyMask = {
      _id: wizard.masks?.safety?._id || null,
      url: wizard.masks?.safety?.url || null,
    };

    try {
      let addFileResult = await addFile({
        variables: {
          files: [image],
          scope: 'masks',
        },
      });

      if (!addFileResult.data || !addFileResult.data.AdminAddFiles[0]) {
        LOG.error(`  - addFile Error: ${addFileResult.errors}`);
        return;
      }

      const { _id, url, filename } = addFileResult.data?.AdminAddFiles[0];
      LOG.info(`  - addFile upload success - ${_id} - ${filename}`);

      let fileInput: updateProductVariables['files'] = [];
      // rimuovo vecchia cutMask se necessario

      if (type === 'cut') {
        // rimuovo vecchia cutMask se necessario
        if (cutMask._id) {
          fileInput.push({
            fileId: cutMask._id,
            action: 'remove',
          });
          LOG.debug(`  - remove OLD cutMask file: ${cutMask._id}`);
        }
        // aggiungo nuova cutmMsk
        cutMask = {
          _id: _id,
          url: url,
        };

        fileInput.push({
          fileId: _id,
          action: 'insert',
        });
        LOG.debug(`  - add NEW cutMask file: ${_id}`);
      }

      if (type === 'safety') {
        // rimuovo vecchia safetyMask se necessario
        if (safetyMask._id) {
          fileInput.push({
            fileId: safetyMask._id,
            action: 'remove',
          });
          LOG.debug(`  - remove OLD safetyMask file: ${safetyMask._id}`);
        }
        safetyMask = {
          _id: _id,
          url: url,
        };

        fileInput.push({
          fileId: _id,
          action: 'insert',
        });
        LOG.debug(`  - add NEW safetyMask file: ${_id}`);
      }

      let newMasks: Wizard['masks'] = {
        cut: cutMask,
        safety: safetyMask,
      };

      let newWizard = {
        ...wizard,
        masks: newMasks,
      };

      updateWizard(newWizard, index, fileInput.length ? fileInput : undefined);

      LOG.info(`  - update masks.[${type}] with Image: ${_id} - ${filename}`);
      setWizard(wizard => {
        return {
          ...wizard,
          masks: newMasks,
        };
      });
    } catch (err) {
      LOG.error(`  - updateWizardMask something went wrong`);
      LOG.error(`${err}`);
      return;
    }
  };

  const removeWizardMask = (type: 'cut' | 'safety') => {
    LOG.debug('CALL removeWizardMask()');

    let cutMask = wizard.masks?.cut;
    let safetyMask = wizard.masks?.safety;

    let fileInput: updateProductVariables['files'] = [];

    if (type === 'cut') {
      // rimuovo vecchia cutMask se necessario
      if (cutMask?._id) {
        fileInput.push({
          fileId: cutMask._id,
          action: 'remove',
        });
        LOG.debug(`  - remove OLD cutMask file: ${cutMask._id}`);
      }
      cutMask = {
        _id: null,
        url: null,
      };
    }

    if (type === 'safety') {
      // rimuovo vecchia safetyMask se necessario
      if (safetyMask?._id) {
        fileInput.push({
          fileId: safetyMask._id,
          action: 'remove',
        });
        LOG.debug(`  - remove OLD safetyMask file: ${safetyMask._id}`);
      }
      safetyMask = {
        _id: null,
        url: null,
      };
    }

    let newMasks: Wizard['masks'] = {
      cut: cutMask,
      safety: safetyMask,
    };

    let newWizard = {
      ...wizard,
      masks: newMasks,
    };

    updateWizard(newWizard, index, fileInput.length ? fileInput : undefined);

    LOG.info(`  - remove masks.[${type}]`);
    setWizard(wizard => {
      return {
        ...wizard,
        masks: newMasks,
      };
    });
  };

  const renderLabel = () => {
    if (numOfWizards <= 1) return null;

    return (
      <div className={'relative w-full px-4'}>
        {/* <hr className="mt-6 border-b-1 border-gray-200" /> */}
        <label className="block uppercase text-gray-600 text-xs font-bold mt-4 mb-2">
          {`Immagine ${index + 1}`}
        </label>

        <div className={'flex flex-row'}>
          <EditBox
            editable={true}
            value={wizard.label}
            label="Etichetta"
            maxChar={50}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              LOG.info(`update product.wizards[${index}].label with [${value}]`);
              updateLabel(value);
            }}
          />
        </div>
        <hr className="mt-6 border-b-1 border-gray-200" />
      </div>
    );
  };

  return (
    <div className="mt-6 border-t-2 border-gray-500 odd:border-t-1 odd:border-gray-200">
      {renderLabel()}

      <div className={'relative w-full px-4'}>
        <label className="block uppercase text-gray-600 text-xs font-bold mt-4 mb-2">
          Dimensioni (mm)
        </label>

        <div className={'flex flex-row'}>
          <EditBoxNumber
            editable={false}
            value={wizard.sizes?.height}
            label="altezza"
            unit="mm"
            isPositive
            isInteger
            isNotEmpty
            maxChar={6}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              LOG.info(`update product.wizards.size.height with [${value}]`);
              updateWizardSize('height', value || 0);
            }}
          />
          <EditBoxNumber
            editable={false}
            value={wizard.sizes?.width}
            label="lunghezza"
            unit="mm"
            isPositive
            isInteger
            isNotEmpty
            maxChar={6}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              LOG.info(`update product.wizards.size.width with [${value}]`);
              updateWizardSize('width', value || 0);
            }}
          />
        </div>
      </div>
      <div className={'relative w-full px-4'}>
        <hr className="mt-6 border-b-1 border-gray-200" />
        <label className="block uppercase text-gray-600 text-xs font-bold mt-4 mb-2">
          Bordi (mm)
        </label>

        <div className={'flex flex-row'}>
          <EditBoxNumber
            editable={true}
            value={wizard.borders?.top.size}
            isInteger
            isPositive
            label="in alto"
            unit="mm"
            maxChar={6}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              updateBorderDimension('top', value);
            }}
          />
          <EditBoxNumber
            editable={true}
            value={wizard.borders?.bottom.size}
            label="in basso"
            unit="mm"
            isInteger
            isPositive
            maxChar={6}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              updateBorderDimension('bottom', value);
            }}
          />
        </div>
        <div className={'flex flex-row'}>
          <EditBoxNumber
            editable={true}
            value={wizard.borders?.right.size}
            label="a destra"
            unit="mm"
            isPositive
            isInteger
            maxChar={6}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              updateBorderDimension('right', value);
            }}
          />
          <EditBoxNumber
            editable={true}
            value={wizard.borders?.left.size}
            label="a sinistra"
            unit="mm"
            isPositive
            isInteger
            maxChar={6}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              updateBorderDimension('left', value);
            }}
          />
        </div>
      </div>

      {productType === 'canvas' && (
        <div className={'relative w-full px-4'}>
          <hr className="mt-6 border-b-1 border-gray-200" />
          <label className="block uppercase text-gray-600 text-xs font-bold mt-4 mb-2">
            Cornice
          </label>

          <div className={'flex flex-row'}>
            <EditBoxNumber
              editable={true}
              value={wizard.frame?.thickness?.size}
              label="spessore"
              unit="mm"
              isPositive
              isInteger
              maxChar={6}
              onEnterEdit={() => {
                setActiveEdit(true);
              }}
              onExitEdit={() => {
                setActiveEdit(false);
              }}
              onPressSave={value => {
                updateFrameDimension(value);
              }}
            />
          </div>

          <div className={'flex flex-row items-center'}>
            <EditBoxText
              editable={false}
              value={wizard.frame?.mode?.mirrored ? 'Sì' : 'No'}
              label="cornice specchiata"
            />
            <div>
              <MySwitch
                defaultValue={wizard.frame?.mode?.mirrored}
                onChange={value => updateFrameMode('mirrored', value)}
              />
            </div>
          </div>
          <div className={'flex flex-row items-center'}>
            <EditBoxText
              editable={false}
              value={wizard.frame?.mode?.colored ? 'Sì' : 'No'}
              label="cornice colorata"
            />
            <div>
              <MySwitch
                defaultValue={wizard.frame?.mode?.colored}
                onChange={value => updateFrameMode('colored', value)}
              />
            </div>
          </div>
        </div>
      )}

      {productType === 'gadget' && (
        <div>
          <div className={'relative w-full px-4'}>
            <hr className="mt-6 border-b-1 border-gray-200" />
            <label className="block uppercase text-gray-600 text-xs font-bold mt-4 mb-2">
              Maschera di taglio
            </label>
            <p className="text-gray-500 text-xs">
              {`immagine in formato PNG. `}
              <span className="text-gray-500 text-xs mb-1 font-bold">{`${widthPX}px x ${heightPX}px (W x H)`}</span>
            </p>
            <p className="text-gray-500 text-xs mb-1">{`La parte trasparente è quella dove andrà la foto, la parte bianca è quella che verrà tagliata`}</p>

            <EditImage
              editable={true}
              value={wizard.masks?.cut?.url || undefined}
              imageFit="contain"
              id={wizard.masks?.cut?._id || undefined}
              loading={addFileLoading}
              onChange={files => {
                updateWizardMask('cut', files[0]);
              }}
              onPressRemove={id => {
                LOG.info(`click to remove ${id} mask`);
                removeWizardMask('cut');
              }}
            />
          </div>
          <div className={'relative w-full px-4'}>
            <hr className="mt-6 border-b-1 border-gray-200" />
            <label className="block uppercase text-gray-600 text-xs font-bold mt-4 mb-2">
              Maschera di sicurezza
            </label>
            <p className="text-gray-500 text-xs">
              {`immagine in formato PNG. `}
              <span className="text-gray-500 text-xs mb-1 font-bold">{`${widthPX}px x ${heightPX}px (W x H)`}</span>
            </p>
            <p className="text-gray-500 text-xs mb-1">{`La parte trasparente dovrebbe essere leggermente più piccola rispetto alla maschera di taglio. in questo modo l'utente sarà sicuro che la porzione di immagine che gli interessa non venga tagliata`}</p>

            <EditImage
              editable={true}
              value={wizard.masks?.safety?.url || undefined}
              imageFit="contain"
              id={wizard.masks?.safety?._id || undefined}
              loading={addFileLoading}
              onChange={files => {
                updateWizardMask('safety', files[0]);
              }}
              onPressRemove={id => {
                LOG.info(`click to remove ${id} mask`);
                removeWizardMask('safety');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface ProductDetailProps {
  product: Product;
}
const ProductDetail = ({ product }: ProductDetailProps) => {
  // -- -- --
  // -- STATTES --
  // -- -- --
  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  let typology = TYPOLOGIES.find(typo => typo.value === product.type);
  let typologyLabel = typology?.label || '';

  // -- -- --
  // -- MUTATIONS --
  // -- -- --
  const [updateProduct, { error: updateProductError }] = useMutation<
    updateProductData,
    updateProductVariables
  >(UPDATE_PRODUCT);

  const [addFile, { error: addFileError, loading: addFileLoading, data: fileData }] = useMutation<
    addFilesData,
    addFilesVariables
  >(ADD_FILE);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const update = (
    newProduct: updateProductVariables['product'],
    files?: updateProductVariables['files']
  ) => {
    LOG.info('CALL updateProduct');

    let cleanNewProduct = transformApolloData(newProduct);
    updateProduct({
      variables: {
        _id: product._id,
        product: cleanNewProduct,
        files: files,
      },
    });
  };

  const updateGalleryImage = async (image: File, index: number) => {
    LOG.debug('CALL updateGallery');
    if (index > product.images.gallery.length + 1) {
      LOG.warn(`index ${index} > gallery.length + 1`);
      return;
    }

    try {
      let resizedImg = await resizeImage(image);
      let addFileResult = await addFile({
        variables: {
          files: [resizedImg],
          scope: 'gallery',
        },
      });
      if (!addFileResult.data || !addFileResult.data.AdminAddFiles[0]) {
        LOG.error(`  - addFile Error: ${addFileResult.errors}`);
        return;
      }
      const { _id, url, filename } = addFileResult.data?.AdminAddFiles[0];
      LOG.info(`  - addFile upload success - ${_id} - ${filename}`);

      let fileInput: updateProductVariables['files'] = [];

      // rimuovo vecchia immagine se necessario
      let oldId = product.images.gallery[index]?._id;
      if (oldId) {
        fileInput.push({
          fileId: oldId,
          action: 'remove',
        });
        LOG.debug(`  - remove OLD gallery[${index}]: ${oldId}`);
      }
      // aggiungo nuova immagine
      let newImage = {
        _id: _id,
        url: url,
      };
      fileInput.push({
        fileId: _id,
        action: 'insert',
      });
      LOG.debug(`  - add NEW gallery file: ${_id}`);

      let newGallery = [...product.images.gallery];
      // caso in cui aggiungo index 1 ma non ho index 0 (non ho cover)
      if (index === 1 && !product.images.gallery[0]) {
        LOG.debug('  - add gallery[1] without gallery[0]');
        newGallery[0] = {
          _id: null,
          url: null,
        };
      }
      newGallery[index] = newImage;
      let newImages: Product['images'] = {
        ...product.images,
        gallery: newGallery,
      };
      update({ images: newImages }, fileInput.length ? fileInput : undefined);

      LOG.info(`  - update gallery.[${index}] with Image: ${_id} - ${filename}`);
    } catch (err) {
      LOG.error(`  - updateGallery something went wrong`);
      LOG.error(`${err}`);
      return;
    }
  };

  const removeGalleryImage = (index: number) => {
    LOG.debug('CALL removeGalleryImage');
    if (!product.images.gallery[index]._id) {
      LOG.warn(`there is no gallery[${index}] to remove`);
      return;
    }
    let fileInput: updateProductVariables['files'] = [];
    // rimuovo vecchia gallery[0] necessario
    let oldId = product.images.gallery[index]._id;
    if (oldId) {
      fileInput.push({
        fileId: oldId,
        action: 'remove',
      });
      LOG.debug(`  - remove OLD gallery image file: ${oldId}`);
    }
    let newGallery = [...product.images.gallery];
    newGallery.splice(index, 1);
    let newImages: Product['images'] = {
      ...product.images,
      gallery: newGallery,
    };
    update({ images: newImages }, fileInput.length ? fileInput : undefined);

    LOG.info(`  - remove gallery.[${index}]`);
  };

  const removeProductCover = () => {
    LOG.debug('CALL removeProductCover');
    if (!product.images.gallery[0]._id) {
      LOG.warn('there is no cover gallery[0] to remove');
      return;
    }

    let fileInput: updateProductVariables['files'] = [];

    // rimuovo vecchia gallery[0] necessario
    let oldId = product.images.gallery[0]._id;
    fileInput.push({
      fileId: oldId,
      action: 'remove',
    });
    LOG.debug(`  - remove OLD gallery[0] file: ${oldId}`);
    let newImage = {
      _id: null,
      url: null,
    };

    let newGallery = [...product.images.gallery];
    newGallery[0] = newImage;
    let newImages: Product['images'] = {
      ...product.images,
      gallery: newGallery,
    };
    update({ images: newImages }, fileInput.length ? fileInput : undefined);

    LOG.info(`  - update gallery.[0] with Image: null`);
  };

  const updateWizard = (wizard: Wizard, index: number, files?: updateProductVariables['files']) => {
    LOG.debug('CALL updateWizard()');

    if (!product.wizards[index]) {
      LOG.error(` - product.wizards[${index}] do NOT exist - do NOT update`);
      return;
    }

    let newWizards = [...product.wizards];
    newWizards[index] = wizard;

    LOG.info(` - update wizard: ${index} of product: ${product.name}`);

    update(
      {
        wizards: newWizards,
      },
      files
    );
  };

  const setActieEditFromWizard = (isEditing: boolean) => {
    setActiveEdit(isEditing);
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  if (updateProductError) {
    LOG.error(updateProductError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 rounded-2xl border-0 flex flex-col"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${updateProductError}`}
        </div>
      </div>
    );
  }

  if (addFileError) {
    LOG.error(addFileError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {`${addFileError}`}
        </div>
      </div>
    );
  }

  const renderWizardsDetail = () => {
    let wizards = [];

    for (let i = 0; i < product.wizards.length; i++) {
      wizards.push(
        <WizardDetail
          key={i}
          wizardInput={product.wizards[i]}
          numOfWizards={product.wizards.length}
          index={i}
          productType={product.type}
          setActiveEdit={setActieEditFromWizard}
          updateWizard={updateWizard}
        />
      );
    }

    return wizards;
  };

  const renderGallery = () => {
    let images = [];

    for (let i = 1; i < product.images.gallery.length; i++) {
      const url = product.images.gallery[i].url;
      const id = product.images.gallery[i]._id;
      if (!url) {
        LOG.error(`gallery[${i}].url NOT exist`);
        continue;
      }
      if (!id) {
        LOG.error(`gallery[${i}]._id NOT exist`);
        continue;
      }
      images.push(
        <EditImage
          key={i}
          halfCol={true}
          editable={true}
          label={`Gallery - ${i}`}
          value={url}
          id={id}
          loading={addFileLoading}
          onChange={files => {
            updateGalleryImage(files[0], i);
          }}
          onPressRemove={id => {
            LOG.debug(`click to remove img:${id} on gallery[${i}]`);
            removeGalleryImage(i);
          }}
        />
      );
    }
    // add empty image
    let last = product.images.gallery.length || 1;
    images.push(
      <EditImage
        key={last}
        halfCol={true}
        editable={true}
        label={`Gallery - Aggiungi`}
        value={undefined}
        id={undefined}
        loading={addFileLoading}
        onChange={files => {
          updateGalleryImage(files[0], last);
        }}
        onPressRemove={id => {
          LOG.debug(`click to remove img:${id} on gallery[${last}]`);
          removeGalleryImage(last);
        }}
      />
    );
    return images;
  };

  return (
    <div
      className="w-full h-full flex flex-row flex-wrap"
      style={{ height: 'calc(100vh - 200px)' }}
    >
      <div className="flex-auto px-4 lg:px-10 pb-10 overflow-auto w-full h-full lg:w-6/12">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Scheda Base</h6>

        <div className="flex flex-wrap">
          <EditBox
            editable={true}
            value={product.name}
            label="nome"
            maxChar={50}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              LOG.info(`update product.name with [${value}]`);
              update({ name: value });
            }}
          />
          <EditBox editable={false} value={typologyLabel} label="Tipologia" />
          <EditBox
            editable={true}
            value={product.descriptions.short}
            label="Descrizione"
            maxChar={500}
            onEnterEdit={() => {
              setActiveEdit(true);
            }}
            onExitEdit={() => {
              setActiveEdit(false);
            }}
            onPressSave={value => {
              LOG.info(`update product.descriptions.short with [${value}]`);
              update({
                descriptions: {
                  short: value || '',
                  long: product.descriptions.long,
                },
              });
            }}
          />
          <EditImage
            editable={true}
            label="cover"
            value={product.images.gallery[0]?.url || undefined}
            id={product.images.gallery[0]?._id || undefined}
            loading={addFileLoading}
            onChange={files => {
              updateGalleryImage(files[0], 0);
            }}
            onPressRemove={id => {
              LOG.debug(`click to remove cover-img:${id} on gallery[0]`);
              removeProductCover();
            }}
          />
          <div className="w-full flex flex-row flex-wrap">{renderGallery()}</div>

          <div className="flex w-full flex-col">{renderWizardsDetail()}</div>
        </div>
        {activeEdit && (
          <div
            className="w-full h-full absolute top-0 left-0 rounded-2xl"
            style={{ backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 20 }}
          ></div>
        )}
      </div>

      {/** ANTEPRIMA -- DX SCHERMO -- */}

      <div className="flex-auto px-4 lg:px-10 pb-10 overflow-auto w-full h-full lg:w-6/12 bg-white rounded-b-2xl">
        <h6 className="text-gray-400 text-xl mt-3 mb-4 font-bold uppercase">Anteprima</h6>
        <h6 className="uppercase text-gray-500 text-xs font-bold mb-2">Visuale normale</h6>

        <div
          style={{
            display: 'inline-block',
            position: 'relative',
            padding: 20,
            marginTop: 20,
          }}
        >
          <div
            className="flex flex-col items-center justify-center shadow-lg rounded-2xl"
            style={{
              display: 'inline-block',
              width: 240,
              height: 120,
              paddingTop: 60,
              paddingLeft: 5,
              marginTop: 40,
              // background: `linear-gradient(325deg, ${theme.colors.violet[0]} 0%, ${theme.colors.violet[2]} 94%)`,
              background: `${theme.colors.violet[2]}`,
              textAlign: 'left',
            }}
          >
            <h2
              className="uppercase text-white text-sm font-bold mx-2 truncate"
              style={{ color: `${theme.colors.violet[0]}` }}
            >
              {product?.name || 'Sconosciuto'}
            </h2>
            <p
              className="text-white text-xxs mx-2 truncate"
              style={{ color: `${theme.colors.violet[0]}` }}
            >
              {product?.descriptions?.short || ''}
            </p>
            <h2
              className="text-white text-xs font-bold mx-2 truncate"
              style={{ color: `${theme.colors.violet[0]}` }}
            >
              {'A partire da ... €'}
            </h2>
          </div>
          <img
            alt="img"
            src={product?.images.gallery[0]?.url || imgDefault}
            className="absolute h-auto align-middle border-none rounded-2xl shadow-lg"
            style={{
              objectFit: 'cover',
              top: 0,
              left: 40,
              display: 'inline-block',
              width: 200,
              height: 110,
            }}
          />
        </div>

        <h6 className="uppercase text-gray-500 text-xs font-bold mb-2">
          Visuale in cartella estesa
        </h6>

        <div className=" w-full" style={{ backgroundColor: theme.colors.green[2] }}>
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              margin: 20,
            }}
          >
            <img
              alt="img"
              src={product?.images.gallery[0]?.url || imgDefault}
              className="h-auto align-middle border-none rounded-2xl shadow-lg"
              style={{
                objectFit: 'cover',
                display: 'inline-block',
                width: 230,
                height: 140,
              }}
            />
            <div
              className="absolute flex flex-col items-center justify-center shadow-md rounded-2xl"
              style={{
                display: 'inline-block',
                bottom: 10,
                left: 10,
                width: 210,
                height: 60,
                padding: 4,
                background: `rgba(255,255,255, .7)`,
                borderColor: '#ffffff',
                borderWidth: '1px',
                textAlign: 'left',
              }}
            >
              <h2
                className="uppercase text-white text-xs font-bold mx-2 truncate"
                style={{ color: `${theme.colors.dark[0]}` }}
              >
                {product?.name || 'Sconosciuto'}
              </h2>
              <p
                className="text-white text-xxs mx-2 truncate"
                style={{ color: `${theme.colors.dark[0]}` }}
              >
                {product?.descriptions?.short || ''}
              </p>
              <h2
                className="text-white text-xxs font-bold mx-2 truncate"
                style={{ color: `${theme.colors.dark[0]}` }}
              >
                {'A partire da ... €'}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductDetail };
