import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { LOG, ENV } from '../../../config';
import { Wizard } from '../../../stores/queries/product';
import { Button, Loading } from '../../../ui';
import {
  uploadHistoryToStorage,
  uploadImageToStorage,
} from '../../../stores/api/uploadFileForAdmin';

const log = LOG.extend('EDITOR');

type EventRecived = 'isReady' | 'isRendered' | 'log' | 'editorOutput' | 'closeEditor' | 'loading';

type EventToSend = 'setEditorInput' | 'getEditorOutput' | 'setLoggerSeverity';

interface MessageRecived {
  event: EventRecived;
  data: any;
}

interface MessageToSend {
  event: EventToSend;
  data: any;
}

interface EditorFrameProps {
  editorInput: {
    imageUrl?: string;
    historyUrl?: string;
    editorConfig: Wizard;
  };
  orderParams: {
    shopId: string;
    jobId: string;
    orderKey: string;
    attachmentKey: string;
    wizardId: string;
  };
  onCloseEditor: () => void;
  onImageEditing: (newImageUrl: string, newHistoryUrl: string) => void;
}
const EditorFrame = ({
  editorInput,
  orderParams,
  onCloseEditor,
  onImageEditing,
}: EditorFrameProps) => {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [elaborating, setElaborating] = useState(false);
  const [inputError, setInputError] = useState<string | null>(null);
  const Editor = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const handleRecivedMessage = (message: MessageEvent): void => {
      // parsing message
      try {
        var event = JSON.parse(message.data);
      } catch (error) {
        log.error(`fromEditorToApp() - error parsing message recived: ${error}`);
      }
      // handle message recived
      if (!event) return;
      let messageRecived = event as MessageRecived;
      fromEditorToApp(messageRecived);
    };

    window.addEventListener('message', handleRecivedMessage);

    return () => {
      window.removeEventListener('message', handleRecivedMessage);
    };
  }, []);

  // -- -- --
  // COMMUNICATIONS
  // -- -- --
  const fromEditorToApp = ({ event, data }: MessageRecived): void => {
    switch (event) {
      case 'log':
        if (!data.type || !data.message) {
          log.warn(` - LOG event - message unreadable`);
        } else {
          log.debug(` - LOG event - [${data.type}]: ${data.message}`);
        }
        break;

      case 'isReady':
        log.info(` - isReady event -`);
        sendInputToEditor(editorInput);
        break;

      case 'isRendered':
        log.info(` - isRendered event -`);
        setLoading(false);
        break;

      case 'editorOutput':
        log.info(` - editorOutput event -`);
        uploadNewImage(data);
        break;

      case 'closeEditor':
        log.info(` - closeEditor event -`);
        onCloseEditor();
        break;

      case 'loading':
        log.info(` - loading event -`);
        setElaborating(true);
        break;

      default:
        log.error(` - UNKNOW event - ${data}`);
        break;
    }
  };

  const sendMessageToEditor = (message: MessageToSend) => {
    if (Editor.current && Editor.current.contentWindow) {
      Editor.current.contentWindow.postMessage(message, '*');
    } else {
      log.error('sendMessageToEditor() - can not find Editor Ref or Editor.contentWindow');
    }
  };

  const sendInputToEditor = async ({
    imageUrl,
    historyUrl,
    editorConfig,
  }: {
    imageUrl?: string; // base64
    historyUrl?: string; // JSON of previous CANVAS
    editorConfig: Wizard;
  }) => {
    if (historyUrl) {
      let response = await axios.get(historyUrl).catch(err => {
        log.error(err);
      });
      if (response && response.data) {
        log.info(`sendInputToEditor() - send History`);
        sendMessageToEditor({
          event: 'setEditorInput',
          data: { history: response.data, editorConfig },
        });
      }
    } else if (imageUrl) {
      log.info(`sendInputToEditor() - send ImageUrl`);
      sendMessageToEditor({
        event: 'setEditorInput',
        data: { image: imageUrl, editorConfig },
      });
    } else {
      log.error('Editor can not find originalImageUrl NOR historyUrl');
      setInputError('Editor can not find originalImageUrl NOR historyUrl');
    }
  };

  // -- -- --
  // MUTATION
  // -- -- --

  const uploadNewImage = async ({
    image,
    history,
    dpi,
  }: {
    image: string;
    history: any;
    dpi: number;
  }) => {
    if (!image) {
      log.error('uploadNewImage() - Editor did NOT exported image');
      return;
    }
    if (!history) {
      log.error('uploadNewImage() - Editor did NOT exported history');
      return;
    }
    if (!dpi) {
      log.error('uploadNewImage() - Editor did NOT exported dpi');
      return;
    }
    setElaborating(false);
    setUploading(true);

    try {
      const uploadImageRes = await uploadImageToStorage(
        image,
        orderParams.shopId,
        orderParams.jobId,
        orderParams.wizardId,
        orderParams.orderKey,
        orderParams.attachmentKey,
        dpi
      );

      if (!uploadImageRes.success || !uploadImageRes.fileUrl) {
        log.error('uploadNewImage() - image is NOT uploaded');
        return;
      }
      log.info('uploadNewImage() - image Uploaded Succesful');

      const uploadHistoryRes = await uploadHistoryToStorage(
        history,
        orderParams.shopId,
        orderParams.jobId,
        orderParams.wizardId,
        orderParams.orderKey,
        orderParams.attachmentKey
      );

      if (!uploadHistoryRes.success || !uploadHistoryRes.fileUrl) {
        log.error('uploadNewImage() - history is NOT uploaded');
        return;
      }
      log.info('uploadNewImage() - history Uploaded Succesful');

      onImageEditing(uploadImageRes.fileUrl || '', uploadHistoryRes.fileUrl || '');

      setUploading(false);
      onCloseEditor();
    } catch (err) {
      log.error(JSON.stringify(err));
      return false;
    }
  };
  // -- -- --
  // RENDER
  // -- -- --

  const renderErrorAndLoading = () => {
    if (inputError) {
      return (
        <div className={`w-full flex flex-col items-center justify-center py-8 h-full`}>
          <h3 className="text-sm text-red-400 font-bold py-8">{inputError}</h3>
          <Button
            onClick={() => {
              setInputError(null);
              onCloseEditor();
            }}
            color="redLight"
          >
            Chiudi
          </Button>
          {/* <Loading color={'#666'} /> */}
        </div>
      );
    }

    if (elaborating) {
      return (
        <div className={`w-full flex flex-col items-center justify-center py-8 h-full`}>
          <h3 className="text-sm text-gray-500 font-bold py-8">Elaboration New Image</h3>
          <Loading color={'#666'} />
        </div>
      );
    }

    if (uploading) {
      return (
        <div className={`w-full flex flex-col items-center justify-center py-8 h-full`}>
          <h3 className="text-sm text-gray-500 font-bold py-8">Uploading New Image</h3>
          <Loading color={'#666'} />
        </div>
      );
    }

    if (loading) {
      return (
        <div className={`w-full flex flex-col items-center justify-center py-8 h-full`}>
          <h3 className="text-sm text-gray-500 font-bold py-8">Loading Editor</h3>
          <Loading color={'#666'} />
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ height: 'calc(100vh - 180px)', overflow: 'hidden' }}>
      {renderErrorAndLoading()}
      <iframe
        ref={Editor}
        // style={{ visibility: loading ? 'hidden' : 'visible' }}
        src={ENV.editor.uri}
        className="w-full h-full"
        // style={{ width: '100%', height: '600px', border: 0 }}
      />
    </div>
  );
};

export { EditorFrame };
