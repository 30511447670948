import React, { CSSProperties } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Navbar, Sidebar } from '../components';
import { LOG, theme } from '../config';

// views
import { DashView } from '../views/admin/home/DashView';
import { useQuery } from '@apollo/client';
import { SHOP_TYPE, USER_LOGGED } from '../stores/queries/appstate';
import { TreeView } from '../views/admin/shop/TreeView';
import { ProductsView } from '../views/admin/shop/ProductsView';
import { ProductView } from '../views/admin/shop/ProductView';
import { OrdersView } from '../views/admin/sales/OrdersView';
import { OrderView } from '../views/admin/sales/OrderView';
import { AccountView } from '../views/admin/settings/AccountView';
import { PaymentView } from '../views/admin/settings/PaymentView';
import { DeliveryView } from '../views/admin/settings/DeliveryView';
import { LightOrdersView } from '../views/admin/sales/LightOrdersView';

const log = LOG.extend('ADMIN');

interface AdminProps {}

const Admin = (props: AdminProps) => {
  const { loading, error, data } = useQuery(USER_LOGGED);
  const { data: shopTypeData } = useQuery<{ shopType: 'full' | 'light' }>(SHOP_TYPE);

  if (!data.userLogged) {
    return <Navigate replace to="/" />;
  }

  /**
   * SHOP LIGHT
   */
  if (shopTypeData?.shopType === 'light') {
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-60 min-h-screen flex flex-1">
          <Navbar />
          <div className="w-full px-4 md:px-10 mx-auto pt-4 md:pt-20">
            <Routes>
              {/** /admin/ */}
              <Route index element={<LightOrdersView />} />

              <Route path="*" element={<Navigate replace to="/admin" />} />
            </Routes>
          </div>
        </div>
      </>
    );
  }

  /**
   * SHOP FULL
   */

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-60 min-h-screen flex flex-1">
        <Navbar />
        <div className="w-full px-4 md:px-10 mx-auto pt-4 md:pt-20">
          <Routes>
            {/** /admin/ */}
            <Route index element={<DashView />} />

            <Route path="shop/folders" element={<TreeView />} />

            <Route path="shop/products" element={<ProductsView />} />

            <Route path="shop/products/:id" element={<ProductView />} />

            <Route path="shop/products/:id/:view" element={<ProductView />} />

            <Route path="sales/lightorders" element={<LightOrdersView />} />

            <Route path="sales/orders" element={<OrdersView />} />

            <Route path="sales/orders/:id" element={<OrderView />} />

            <Route path="sales/orders/:id/:view" element={<OrderView />} />

            <Route path="settings/account" element={<AccountView />} />

            <Route path="settings/payment" element={<PaymentView />} />

            <Route path="settings/payment/:view" element={<PaymentView />} />

            <Route path="settings/delivery" element={<DeliveryView />} />

            {/* Se non trovi indirizzo mostra admin/Home pagina */}
            <Route path="*" element={<Navigate replace to="/admin" />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    backgroundColor: theme.colors.grey[0],
  },
};

export { Admin };
