import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

import { LOG } from '../../config';

import { useMutation } from '@apollo/client';
import {
  Product_Typology,
  TYPOLOGIES,
  Product,
  duplicateProductVariables,
  DUPLICATE_PRODUCT,
  duplicateProductData,
  Wizard,
} from '../../stores/queries/product';

import { Button, TextBox } from '../../ui';
import { useFormFields } from '../../hooks/forms';
import { ACTUAL_AUTH } from '../../stores/db/auth';

const logger = LOG.extend('PRODUCT_DUPLICATE_MODAL');

type SizeInputs = 'width' | 'height';

interface ImageSizeInputProps {
  onInputChange: (pageIndex: number, type: SizeInputs, input: string) => void;
  pageIndex: number;
  wizard: Wizard;
}

const ImageSizeInput = ({ pageIndex, onInputChange, wizard }: ImageSizeInputProps) => {
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in SizeInputs]: string }>({
    width: String(wizard.sizes.width) || '',
    height: String(wizard.sizes.height) || '',
  });

  return (
    <div>
      <div className="mt-4">
        <p className="text-sm text-gray-500">inserisci le dimensioni</p>
        <div className="flex flex-row items-center">
          <TextBox
            id="height"
            type="text"
            isNumber
            value={inputs.height}
            placeholder={'Altezza'}
            required={true}
            onChange={event => {
              handleInputChange(event);
              onInputChange(pageIndex, 'height', event.target.value);
            }}
          />

          <p className="text-sm px-2 text-gray-500 text">mm</p>
          <p className="text-sm pr-2 text-gray-500 text">-</p>
          <TextBox
            id="width"
            type="text"
            isNumber
            value={inputs.width}
            placeholder={'Lunghezza'}
            required={true}
            onChange={event => {
              handleInputChange(event);
              onInputChange(pageIndex, 'width', event.target.value);
            }}
          />
          <p className="text-sm px-2 text-gray-500">mm</p>
        </div>
      </div>
    </div>
  );
};

const getBaseNewProduct = (productToCopy: Product): duplicateProductVariables['product'] => {
  const newProduct: duplicateProductVariables['product'] = {
    name: '',
    descriptions: {
      short: productToCopy?.descriptions?.short || '',
      long: productToCopy?.descriptions?.long || '',
    },
    wizards: productToCopy.wizards.map(wizard => {
      return {
        _id: wizard._id,
        sizes: {
          height: wizard.sizes?.height,
          width: wizard.sizes?.width,
        },
      };
    }),
  };

  return newProduct;
};

const getTypologyName = (typology: Product_Typology): string | undefined => {
  return TYPOLOGIES.find(typo => typo.value === typology)?.label;
};

type Inputs = 'name' | 'sizes';

interface ProductDuplicateModalProps {
  product: Product;
}

const ProductDuplicateModal = (props: ProductDuplicateModalProps) => {
  // -- -- --
  // -- STATES --
  // -- -- --
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const newProduct = useRef<duplicateProductVariables['product']>(getBaseNewProduct(props.product));
  const [inputs, handleInputChange, setInputs] = useFormFields<{ [key in Inputs]: string }>({
    name: '',
    sizes: '',
  });
  const [inputsError, setInputErrors] = useState<{ [key in Inputs]: string }>({
    name: '',
    sizes: '',
  });

  // -- -- --
  // -- MUTATIONS --
  // -- -- --

  const [duplicateProduct, { error, loading, data }] = useMutation<
    duplicateProductData,
    duplicateProductVariables
  >(DUPLICATE_PRODUCT);

  // -- -- --
  // -- EFFECTS --
  // -- -- --

  useEffect(() => {
    if (data?.AdminProductDuplicate?._id) {
      logger.info(`! New product register [${data.AdminProductDuplicate._id}]! -> go to Detail`);
      navigate('/admin/shop/products/' + data.AdminProductDuplicate._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // ricalcolo newProductRef se cambiano le props (es: filtro in lista)
  useEffect(() => {
    newProduct.current = getBaseNewProduct(props.product);
  }, [props.product]);

  // -- -- --
  // -- FUNCTIONS --
  // -- -- --

  const duplicate = () => {
    logger.info(`CALL duplicateProduct : ${JSON.stringify(newProduct.current)}`);

    duplicateProduct({
      variables: {
        _id: props.product._id,
        shopId: ACTUAL_AUTH.shop || '',
        product: newProduct.current,
      },
    });
  };

  const resetError = (key: Inputs) => {
    setInputErrors(oldErrors => {
      return {
        ...oldErrors,
        [key]: '',
      };
    });
  };

  const resetForm = () => {
    setInputs({
      name: '',
      sizes: '',
    });
    setInputErrors({
      name: '',
      sizes: '',
    });
    newProduct.current = getBaseNewProduct(props.product);
  };

  const isFormValid = () => {
    LOG.debug('CALL formValidation()');
    let isFormValid = true;

    //name - not null
    if (!inputs.name) {
      LOG.info('  - inputs.name NOT VALID');
      setInputErrors(oldErrors => {
        return {
          ...oldErrors,
          name: '* campo obbligatorio.',
        };
      });
      isFormValid = false;
    }

    //all sizes - not null & > 0
    for (let i = 0; i < newProduct.current.wizards.length; i++) {
      let sizes = newProduct.current.wizards[i].sizes;
      if (!sizes || sizes.height <= 0) {
        LOG.info('  - inputs.sizes NOT VALID');
        setInputErrors(oldErrors => {
          return {
            ...oldErrors,
            sizes: '* campi obbligatori. Solo numeri positivi',
          };
        });
        isFormValid = false;
      }
      if (!sizes || sizes.width <= 0) {
        LOG.info('  - inputs.sizes NOT VALID');
        setInputErrors(oldErrors => {
          return {
            ...oldErrors,
            sizes: '* dimensioni obbligatorie per ogni immagine',
          };
        });
        isFormValid = false;
      }
    }

    return isFormValid;
  };

  function closeModal() {
    if (loading) return;
    resetForm();
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  const onSizeInputChange = (index: number, type: SizeInputs, input: string) => {
    let sizes = newProduct.current.wizards[index]?.sizes;
    if (!sizes) {
      logger.error(`wizards[${index}].sizes do NOT exist`);
      return;
    }

    if (type === 'height') {
      newProduct.current.wizards[index] = {
        ...newProduct.current.wizards[index],
        sizes: {
          height: Number(input),
          width: sizes.width,
        },
      };
    }
    if (type === 'width') {
      newProduct.current.wizards[index] = {
        ...newProduct.current.wizards[index],
        sizes: {
          height: sizes.height,
          width: Number(input),
        },
      };
    }
    resetError('sizes');
  };

  // -- -- --
  // -- RENDER --
  // -- -- --

  if (error) logger.error(error);

  // se selectedTypology = goods -> null
  // se selectedTypology = pictureStandard -> input dimensioni di 1 immagine
  // se selectedTypology = pictureCreative -> input dimensioni di 1 immagine
  // negli altri casi -> input dimensioni a scelta del fotografo (almeno 1 immagine)
  const renderSizesInput = () => {
    if (props.product.type === 'goods') return null;

    let sizesInput = [];

    for (let i = 0; i < props.product.wizards.length; i++) {
      sizesInput.push(
        <div key={i} className="border-b-2 border-gray-500 last:border-b-0">
          <div className="mb-2">
            <ImageSizeInput
              key={i}
              pageIndex={i}
              onInputChange={onSizeInputChange}
              wizard={props.product.wizards[i]}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {props.product.type === 'pictureStandard' ||
        props.product.type === 'pictureCreative' ? null : (
          <div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {`Num di immagini necessarie per questo prodotto:`}
              </p>
            </div>
            <div className="flex flex-row mt-2 items-center">
              <h4 className="text-base font-bold uppercase mr-4">{props.product.wizards.length}</h4>
            </div>
          </div>
        )}

        {sizesInput}
        {inputsError.sizes ? <p className="text-xs text-red-400">{inputsError.sizes}</p> : null}
      </div>
    );
  };

  return (
    <>
      <Button
        className="mr-2"
        size="xs"
        color="purple"
        onClick={openModal}
        style={{ alignSelf: 'center' }}
      >
        <i className="fa fa-clone" />
      </Button>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={closeModal}
          style={{ zIndex: 999 }}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-auto text-left align-middle transition-all transform bg-gray-100 shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-gray-600 text-l my-2 font-bold uppercase">
                  {`Duplica prodotto - ${props.product.name}`}
                </Dialog.Title>

                <hr className="border-b-1 border-gray-200" />

                <div className="mt-4">
                  <p className="text-sm text-gray-500">Inserisci il nuovo nome</p>
                </div>
                <TextBox
                  id="name"
                  type="text"
                  value={inputs.name}
                  error={inputsError.name}
                  placeholder={'Nome'}
                  required={true}
                  onChange={event => {
                    newProduct.current.name = event.target.value;
                    handleInputChange(event);
                    resetError('name');
                  }}
                />

                <div className="mt-2">
                  <p className="text-sm text-gray-500">Tipologia</p>
                </div>
                <TextBox
                  id="type"
                  type="text"
                  disabled
                  value={getTypologyName(props.product.type)}
                />

                {renderSizesInput()}

                <div className="mt-4 flex flex-row gap-4">
                  <Button
                    onClick={() => {
                      if (isFormValid()) {
                        LOG.info('valid form -> upload');
                        duplicate();
                      } else {
                        LOG.info('NOT valid form -> NOT upload');
                      }
                    }}
                    disabled={loading}
                    color="purple"
                  >
                    Crea
                  </Button>
                  <Button onClick={closeModal} color="red" disabled={loading}>
                    Annulla
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export { ProductDuplicateModal };
