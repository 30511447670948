import React, { useState } from 'react';

import { Colors, theme } from '../../config';
import { FOLDER_COLORS } from '../../stores/queries/shop';

interface SelectfolderColorProps {
  initialValue?: Colors;
  onChange?: (color: Colors | undefined) => void;
}
const SelectFolderColor = (props: SelectfolderColorProps) => {
  const [value, setValue] = useState<Colors | undefined>(props.initialValue);
  let colorSquares = [];

  for (let i = 0; i < FOLDER_COLORS.length; i++) {
    let selected = false;
    if (FOLDER_COLORS[i].value === value) selected = true;
    let hexColor = theme.colors[FOLDER_COLORS[i].value][1];
    colorSquares.push(
      <div
        key={i}
        className={`h-6 mx-auto cursor-pointer rounded-lg transition-all duration-300 ${
          selected ? `shadow-lg w-20 shadow-violet-500/50` : 'w-8'
        }`}
        style={{ backgroundColor: hexColor }}
        onClick={() => {
          props.onChange && props.onChange(FOLDER_COLORS[i].value);
          setValue(FOLDER_COLORS[i].value);
        }}
      ></div>
    );
  }

  return (
    <div className="shadow my-2 p-4 relative w-full bg-white rounded-lg grid grid-cols-4 gap-4">
      {colorSquares}
    </div>
  );
};

export { SelectFolderColor };
